import React from 'react';
import { Row, Col } from 'reactstrap';

const DivorceCertificate = ({ service = {} }) => {
  return (
    <>
      <Row>
        <Col sm={12} className='mb-3'>
          <h6 className='sub-title'>Información del esposo</h6>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Nombres</p>
          <p className='text-muted'>{`${service.data.nombresEsposo}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Apellido</p>
          <p className='text-muted'>{`${service.data.apellidosEsposo}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Fecha de nacimiento</p>
          <p className='text-muted'>{`${service.data.fechaNacimientoEsposo}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Documento de Identidad</p>
          <p className='text-muted'>{`${service.data.documentoIdentidadEsposo}`}</p>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className='mb-3'>
          <h6 className='sub-title'>Información de la esposa</h6>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Fecha de nacimiento</p>
          <p className='text-muted'>{`${service.data.nombresEsposa}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Documento de identidad</p>
          <p className='text-muted'>{`${service.data.apellidosEsposa}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Fecha de nacimiento</p>
          <p className='text-muted'>{`${service.data.fechaNacimientoEsposa}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Documento de Identidad</p>
          <p className='text-muted'>{`${service.data.documentoIdentidadEsposa}`}</p>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className='mb-3'>
          <h6 className='sub-title'>Información del divorcio</h6>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>No. registro</p>
          <p className='text-muted'>{`${service.data.registroDivorcio}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Fecha del divorcio</p>
          <p className='text-muted'>{`${service.data.fechaDivorcio}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Pais</p>
          <p className='text-muted'>{`${service.data.objPais.label}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Ciudad/Municipio</p>
          <p className='text-muted'>{`${service.data.objDepartamento.label}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Ciudad/Municipio</p>
          <p className='text-muted'>{`${service.data.objCiudad.label}`}</p>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className='mb-3'>
          <h6 className='sub-title'>Información de servicio y trámite</h6>
        </Col>
        <Col sm={12}>
          <table className='table table-striped table-bordered'>
            <tbody>
              <tr>
                <th width='250'>Tipo de Servicio</th>
                <td>{service.data.servicio.nombreServicio}</td>
              </tr>
              <tr>
                <th>Precio</th>
                <td>{`$ ${Number(service.data.servicio.precioServicio).toFixed(
                  2
                )}`}</td>
              </tr>
              <tr>
                <th>Tipo de Trámite</th>
                <td>{service.data.tipoTramite.nombre_esp}</td>
              </tr>
              <tr>
                <th>Precio</th>
                <td>{`$ ${Number(service.data.tipoTramite.precio).toFixed(
                  2
                )}`}</td>
              </tr>
              <tr>
                <th>Cantidad</th>
                <td>{service.cantidad}</td>
              </tr>
              <tr>
                <th>Total</th>
                <td>{`$ ${service.precio_total.toFixed(2)}`}</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );
};

export default DivorceCertificate;
