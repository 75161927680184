import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

const StripePaymentDetails = ({ autorizacion_pago }) => {
  const { t } = useTranslation();

  return (
    <>
      <h6 className='my-4'>{t('modules.legal.requestDetails.paymentDetails.title')}</h6>
      <table className='table table-striped table-bordered'>
        <tbody>
          <tr>
            <td>
              <Row>
                <Col sm={5} md={4} className='mb-3'>
                  <p className='h6 m-0'>{t('modules.legal.requestDetails.paymentDetails.id')}</p>
                  <small>{autorizacion_pago?.paymentIntent.id}</small>
                </Col>
                <Col sm={5} md={4} className='mb-3'>
                  <p className='h6 m-0'>
                    {t('modules.legal.requestDetails.paymentDetails.status')}
                  </p>
                  <small>{autorizacion_pago?.paymentIntent.status}</small>
                </Col>
                <Col sm={5} md={4} className='mb-3'>
                  <p className='h6 m-0'>
                    {t('modules.legal.requestDetails.paymentDetails.paymentDate')}
                  </p>
                  <small>
                    {new Date(autorizacion_pago?.paymentIntent.created * 1000).toLocaleString()}
                  </small>
                </Col>
                <Col sm={5} md={4} className='mb-3'>
                  <p className='h6 m-0'>
                    {t('modules.legal.requestDetails.paymentDetails.paymentMethod')}
                  </p>
                  <small>
                    {t('modules.legal.requestDetails.paymentDetails.paymentMethodDetails', {
                      cardBrand: autorizacion_pago?.paymentMethod.card.brand,
                      cardLastFourDigits: autorizacion_pago?.paymentMethod.card.last4
                    })}
                  </small>
                </Col>
                <Col sm={5} md={4} className='mb-3'>
                  <p className='h6 m-0'>
                    {t('modules.legal.requestDetails.paymentDetails.cardHolder')}
                  </p>
                  <small>{autorizacion_pago?.paymentMethod.billing_details.name}</small>
                </Col>
                <Col sm={5} md={4} className='mb-3'>
                  <p className='h6 m-0'>
                    {t('modules.legal.requestDetails.paymentDetails.totalAmount')}
                  </p>
                  <small>$ {autorizacion_pago?.paymentIntent.amount / 100}</small>
                </Col>
              </Row>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default StripePaymentDetails;
