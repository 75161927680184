import React from 'react';
import { Label, Input } from 'reactstrap';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';

/**
 * @param id string
 * @param name string
 * @param dataSource array of elements [{ "label": "", "value": ""}, ...]
 * @param className string
 * @param rules react-hook-form (rules object)
 * @param defaultValue string
 * @param label string
 * @param control react-hook-form (useForm() property)
 * @param errors react-hook-form (useForm() property)
 *
 *  */
const Dropdown = ({
  id,
  name,
  dataSource = [],
  className = '',
  rules = {},
  defaultValue = '',
  label,
  control,
  errors = null,
  handleChange = () => {},
  disabled = false
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Label for={id}>{label}</Label>
      <Controller
        name={name}
        control={control}
        className={className}
        rules={{ ...rules }}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Input
            id={id}
            type='select'
            disabled={disabled}
            {...field}
            onChange={(value) => {
              field.onChange(value);
              if (handleChange) handleChange(value);
            }}
          >
            <option key={0} value=''>
              {t('common.formControls.selectOption')}
            </option>
            {dataSource.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </Input>
        )}
      />

      {errors && (
        <ErrorMessage errors={errors} name={name} as={<small className='font-danger' />} />
      )}
    </>
  );
};

export default Dropdown;
