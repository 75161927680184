import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Col, Form, Row, Button } from 'reactstrap';
import { getStates, getCities } from 'services';
import { TextField, DropDown, CheckBox } from 'modules/common/components';
import { useLegalStore } from 'store/legalStore';

const GeneralInformation = (props) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    unregister,
    register,
    setValue
  } = useForm();

  const setBirthCertificate = useLegalStore((state) => state.setBirthCertificate);
  const birthCertificate = useLegalStore((state) => state.birthCertificate);
  const service = useLegalStore((state) => state.service);

  const defaultCountry = {
    value: service.idPais,
    label: service.nombrePais
  };

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [noAplica, setNoAplica] = useState(!!birthCertificate?.noAplica);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    const savedState = useLegalStore.getState().birthCertificate?.departamento;

    if (defaultCountry.value) {
      getStatesByCountry(defaultCountry.value);
    }

    if (savedState) {
      getCitiesByState(savedState);
    }

    return () => {
      isMounted = false;
    };
  }, [defaultCountry.value]);

  useEffect(() => {
    if (noAplica) {
      setValue('nombreDePadre', '');
    }
  }, [noAplica, setValue]);

  const getStatesByCountry = (idCountry) => {
    getStates(idCountry)
      .then(({ data: { info } }) => {
        const data = info?.data?.map((state) => ({
          label: state.nombre,
          value: state.id
        }));
        setStates(data);
      })
      .catch((error) => console.error('Error getting states. ', error));
  };

  const getCitiesByState = (idState) => {
    getCities(idState)
      .then(({ data: { info } }) => {
        const data = info?.data?.map((city) => ({
          label: city.nombre,
          value: city.id
        }));
        setCities(data);
      })
      .catch((error) => console.error('Error getting cities. ', error));
  };

  const handleCheckbox = ({ target: { checked } }) => {
    if (checked) {
      unregister('nombreDePadre');
    } else {
      register('nombreDePadre');
    }
    setNoAplica(checked);
  };

  const onSubmit = (data) => {
    const objDepartamento = states.find((i) => Number(i.value) === Number(data.departamento));
    const objCiudad = cities.find((i) => Number(i.value) === Number(data.ciudad));

    setBirthCertificate({
      ...data,
      pais: defaultCountry.value,
      objPais: defaultCountry,
      objDepartamento,
      objCiudad
    });
    props.nextStep();
  };

  return (
    <>
      <h5>{t('common.legal.generalInfo.title')}</h5>
      <Form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm='6' md='4' className='mb-3'>
            <TextField
              id='primerNombre'
              name='primerNombre'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.firstname')}
              control={control}
              errors={errors}
              defaultValue={birthCertificate?.primerNombre}
            />
          </Col>
          <Col sm='6' md='4' className='mb-3'>
            <TextField
              id='segundoNombre'
              name='segundoNombre'
              className='form-control'
              label={t('common.legal.middlename')}
              control={control}
              defaultValue={birthCertificate?.segundoNombre}
            />
          </Col>
          <Col sm='6' md='4' className='mb-3'>
            <TextField
              id='tercerNombre'
              name='tercerNombre'
              className='form-control'
              label={t('common.legal.thirdname')}
              control={control}
              defaultValue={birthCertificate?.tercerNombre}
            />
          </Col>
          <Col sm='6' md='4' className='mb-3'>
            <TextField
              id='primerApellido'
              name='primerApellido'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.surname')}
              control={control}
              errors={errors}
              defaultValue={birthCertificate?.primerApellido}
            />
          </Col>
          <Col sm='6' md='4' className='mb-3'>
            <TextField
              id='segundoApellido'
              name='segundoApellido'
              className='form-control'
              label={t('common.legal.secondSurname')}
              control={control}
              defaultValue={birthCertificate?.segundoApellido}
            />
          </Col>
          <Col sm='6' md='4' className='mb-3'>
            <TextField
              id='tercerApellido'
              name='tercerApellido'
              className='form-control'
              label={t('common.legal.thirdSurname')}
              control={control}
              defaultValue={birthCertificate?.tercerApellido}
            />
          </Col>
          <Col sm='6' md='4' className='mb-3'>
            <TextField
              id='conocidoComo'
              name='conocidoComo'
              className='form-control'
              label={t('common.legal.knownAs')}
              control={control}
              defaultValue={birthCertificate?.conocidoComo}
            />
          </Col>
          <Col sm='6' md='4' className='mb-3'>
            <TextField
              id='documentoIdentidad'
              name='documentoIdentidad'
              className='form-control'
              label={t('common.legal.id')}
              control={control}
              defaultValue={birthCertificate?.documentoIdentidad}
            />
          </Col>
          <Col sm='6' md='4' className='mb-3'>
            <TextField
              id='fechaNacimiento'
              name='fechaNacimiento'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.birthdate')}
              control={control}
              errors={errors}
              type='date'
              max={new Date().toISOString().slice(0, 10)}
              defaultValue={birthCertificate?.fechaNacimiento}
            />
          </Col>
          <Col sm='6' md='4' className='mb-3'>
            <TextField
              id='pais'
              name='pais'
              className='form-control'
              label={t('common.legal.country')}
              control={control}
              disabled
              defaultValue={defaultCountry?.label}
            />
          </Col>
          <Col sm='6' md='4' className='mb-3'>
            <DropDown
              id='departamento'
              name='departamento'
              dataSource={states}
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.state')}
              control={control}
              errors={errors}
              handleChange={(event) => getCitiesByState(event.target.value)}
              defaultValue={birthCertificate?.departamento}
            />
          </Col>
          <Col sm='6' md='4' className='mb-3'>
            <DropDown
              id='ciudad'
              name='ciudad'
              dataSource={cities}
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.city')}
              control={control}
              errors={errors}
              defaultValue={birthCertificate?.ciudad}
            />
          </Col>

          <Col md='8' className='mb-3'>
            <TextField
              id='nombreDeMadre'
              name='nombreDeMadre'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.mothersName')}
              control={control}
              errors={errors}
              defaultValue={birthCertificate?.nombreDeMadre}
            />
          </Col>

          <Col md='8' className='mb-3'>
            <TextField
              id='nombreDePadre'
              name='nombreDePadre'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.fathersName')}
              control={control}
              errors={errors}
              disabled={noAplica}
              defaultValue={birthCertificate?.nombreDePadre}
            />
          </Col>

          <Col md='4' className='mb-3'>
            <div className='d-none d-md-block'>&nbsp;</div>
            <CheckBox
              id='noAplica'
              name='noAplica'
              className='form-control'
              label={t('common.legal.doesntApply')}
              control={control}
              handleChange={handleCheckbox}
              defaultValue={noAplica}
            />
          </Col>

          <Col md='12' className='mb-3'>
            <p className='text-muted info-alert'>
              <span className='ri-information-fill mr-2'></span>
              {t('common.legal.generalInfo.help')}
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm='12' className='mb-3 d-flex justify-content-end'>
            <Button className='btn-pill d-flex align-items-center' color='primary' type='submit'>
              {t('common.formControls.next')}
              <span className='ri-arrow-right-line ml-2'></span>
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default GeneralInformation;
