import { axiosInstance, BASE_URL } from 'configurations/axios';

const path = BASE_URL.concat('/solicitudes');

const createRequest = async (
  data = {
    modulo: 0,
    direccion: 0,
    tipo_envio: 0,
    forma_pago: 0,
    numero_autorizacion: {},
  }
) => {
  return await axiosInstance.post(path.concat('/procesar'), data);
};

const getRequestById = async (solicitud = 0) => {
  return await axiosInstance.get(`${path}/consultar/${solicitud}`);
};

const getRequestList = async (
  data = { modulo: 0, fecha_desde: '', fecha_hasta: '' }
) => {
  return await axiosInstance.post(`${path}/cliente`, data);
};

export { createRequest, getRequestById, getRequestList };
