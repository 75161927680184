import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LogIn, User, ShoppingCart } from 'react-feather';
import { useLegalStore } from 'store/legalStore';
import { useAuthStore } from 'store/authStore';
import { getCart } from 'services';
import {
  signOut as signOutFirebase,
  authentication,
  isLoggedInByFirebase
} from 'configurations/firebase';
import { signOut } from 'services';
import { useOutsideClick } from 'modules/common/hooks/useOutsideClick';
import man from 'assets/images/tkiero_placeholder.jpeg';

const Rightbar = (props) => {
  const userInformation = useAuthStore((state) => state.userInformation);
  const setUserInformation = useAuthStore((state) => state.setUserInformation);
  const cartCount = useLegalStore((state) => state.cartCount);
  const setCartCount = useLegalStore((state) => state.setCartCount);
  const languageDivRef = useRef(null);

  const { i18n, t } = useTranslation();
  const [langdropdown, setLangdropdown] = useState(false);

  useOutsideClick({ elementRef: languageDivRef, handleClose: () => setLangdropdown(false) });

  let defaultLanguage = localStorage.getItem('language') ?? null;
  defaultLanguage =
    defaultLanguage && ['en', 'es'].includes(defaultLanguage) ? defaultLanguage : 'es';
  const [selected, setSelected] = useState(defaultLanguage);

  useEffect(() => {
    const modulo = 1;
    getCart(modulo)
      .then(({ count }) => {
        setCartCount(count);
      })
      .catch((error) => console.error('error getting cart count ', error));
    // eslint-disable-next-line
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelected(lng);
    localStorage.setItem('language', lng);
  };

  return (
    <Fragment>
      <div className='nav-right col-8 pull-right right-header p-0'>
        <ul className='nav-menus m-0'>
          <li className='language-nav'>
            <div className={`translate_wrapper ${langdropdown ? 'active' : ''}`}>
              <div className='current_lang'>
                <div
                  id='lang'
                  className='lang'
                  tabIndex={0}
                  role='button'
                  ref={languageDivRef}
                  onClick={() => setLangdropdown(!langdropdown)}
                >
                  <i
                    className={`flag-icon flag-icon-${
                      selected === 'en' ? 'us' : selected === 'du' ? 'de' : selected
                    }`}
                  ></i>
                  <span className='lang-txt'>{selected}</span>
                </div>
              </div>
              <div className={`more_lang ${langdropdown ? 'active' : ''}`}>
                <div className='lang' onClick={() => changeLanguage('en')}>
                  <i className='flag-icon flag-icon-us'></i>
                  <span className='lang-txt'>
                    English
                    <span> {'(US)'}</span>
                  </span>
                </div>
                <div className='lang' onClick={() => changeLanguage('es')}>
                  <i className='flag-icon flag-icon-es'></i>
                  <span className='lang-txt'>Español</span>
                </div>
              </div>
            </div>
          </li>
          <li className='cart-nav onhover-dropdown'>
            <Link className='cart-box' to='/app/ecommerce/shopping-cart'>
              <ShoppingCart />
              <span className='badge badge-pill badge-primary'>{cartCount}</span>
            </Link>
          </li>
          <li className='profile-nav onhover-dropdown pl-2'>
            <div className='media profile-media'>
              <img className='b-r-10' src={man} alt='' />
              <div className='media-body'>
                <span>{`${userInformation?.nombres} ${userInformation?.apellidos ?? ''}`}</span>
                <p className='mb-0 font-roboto'>
                  {`${userInformation?.correo_electronico} `}{' '}
                  <i className='middle fa fa-angle-down'></i>
                </p>
              </div>
            </div>
            <ul className='profile-dropdown onhover-show-div'>
              <li>
                <Link to='/app/settings/account'>
                  <User />
                  <span>{t('toolbar.account')}</span>
                </Link>
              </li>
              <li
                onClick={async () => {
                  const isFirebaseUser = await isLoggedInByFirebase();

                  if (isFirebaseUser) {
                    await signOutFirebase(authentication);
                  } else {
                    await signOut();
                  }

                  setUserInformation(null);
                  window.location.reload();
                }}
              >
                <LogIn />
                <span>{t('toolbar.signOut')}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default Rightbar;
