import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Loader } from 'react-feather';
import Breadcrumb from 'layout/breadcrumb';
import { TextField } from 'modules/common/components';
import { PaypalCheckoutButton, StripeCheckoutForm } from 'modules/ecommerce/checkout/components';
import { Form, Row, Col, Button, Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { generateAutomaticCapture, getPaymentMethods, processExpressPointPayment } from 'services';

const PayWithNPE = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [amountToPay, setAmountToPay] = useState(0);
  const [capture, setCapture] = useState(null);
  const [stripeInfo, setStripeInfo] = useState(null);
  const [paypalInfo, setPaypalInfo] = useState(null);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  useEffect(() => {
    getPaymentMethods()
      .then(({ data: { info } }) => {
        const paypal = info.data.find((i) => i.metodo === 'paypal');
        const stripe = info.data.find((i) => i.metodo === 'stripe');
        setPaypalInfo(paypal);
        setStripeInfo(stripe);
        setPaymentMethods(info.data);
      })
      .catch((error) => console.error('error getting payment methods ', error));
  }, []);

  const onSubmit = (data) => {
    setIsLoading(true);
    setCapture(data.captura);

    if (paymentResponse !== null) {
      setPaymentResponse(null);
    }

    generateAutomaticCapture(data)
      .then(({ data: { info } }) => {
        setPaymentResponse(info.data);
        setAmountToPay(
          info.data?.service?.dataSets?.data?.resultQueryForm?.fields?.amount?.value?.val
        );
      })
      .catch((error) => {
        console.error('There was an error on processing the payment with NPE. ', error);
      })
      .finally(() => {
        setIsLoading(false);
        reset({});
      });
  };

  const handlePaymentMethod = ({ target }) => {
    const { value } = target;
    setSelectedPaymentMethod(value);
  };

  const onApprovePayment = async (order) => {
    setPaymentCompleted(true);

    // TODO
    // check why the selectedPaymentMethod is reset to null
    // when paying with Paypal
    const _selectedPaymentMethod =
      selectedPaymentMethod === 'stripe' ? selectedPaymentMethod : 'paypal';
    const paymentMethod = paymentMethods.find((i) => i.metodo === _selectedPaymentMethod);

    const service = { ...paymentResponse?.service };

    service.dataSets.status = 'REQUEST';
    service.dataSets.data.resultQueryForm.fields.reference.reference = true;
    service.dataSets.data.resultQueryForm.fields.total_payment.value.val = amountToPay.toString();

    const request = {
      formaPago: paymentMethod?.id,
      autorizacionPago: order,
      formulario: {
        ...service
      }
    };

    processExpressPointPayment(request)
      .then(({ data: { info } }) => {
        const authorization = info.data?.authorization;
        /**
         * TODO
         * redirect to a page displaying a list of payments or the payment detail
         */
        toast.success(t('modules.payments.payWithNPE.paymentSuccess'));
        history.push('/app/payments/dashboard');
      })
      .catch((error) => {
        console.error('error processing express point payment ', { error });
        toast.error(error.response.data.error);
      })
      .finally(() => setPaymentCompleted(false));
  };

  const onErrorPayment = async (error, paymentMethod) => {
    if (paymentMethod === 'stripe') {
      toast.error(error.message);
    } else {
      console.error(`[${paymentMethod}] - error on processing the payment: `, error);
    }
  };

  return (
    <>
      <Breadcrumb
        module={t('modules.payments.title')}
        page={t('modules.payments.payWithNPE.page')}
        title={t('modules.payments.payWithNPE.page')}
      />

      <h5>{t('modules.payments.payWithNPE.title')}</h5>
      <Form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className='mb-3'>
            <TextField
              id='captura'
              name='captura'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('modules.payments.payWithNPE.enterNPE')}
              control={control}
              errors={errors}
            />
          </Col>
          <Col className='col-auto mb-3'>
            <label className='d-block'>&nbsp;</label>
            <Button color='primary' className='btn-pill' disabled={isLoading}>
              {isLoading ? <Loader size={15} className='fa-spin' /> : null}
              <span className='ml-1'>{t('common.formControls.verify')}</span>
            </Button>
          </Col>
        </Row>
      </Form>
      <Row>
        {paymentResponse !== null ? (
          <>
            <Col xs={12} md={6}>
              <h5 className='mb-4'>{t('modules.payments.payWithNPE.invoiceDetails')}</h5>
              <Card className='bg-neutral'>
                <CardBody>
                  {paymentResponse?.serviceCode && (
                    <p>
                      {t('modules.payments.payWithNPE.serviceCode', {
                        serviceCode: paymentResponse?.service.serviceCode
                      })}
                    </p>
                  )}

                  {paymentResponse?.service.name && (
                    <p>
                      {t('modules.payments.payWithNPE.serviceName', {
                        serviceName: paymentResponse?.service.name
                      })}
                    </p>
                  )}

                  {paymentResponse?.dateTime && (
                    <p>
                      {t('modules.payments.payWithNPE.dateTime', {
                        dateTime: paymentResponse?.dateTime
                      })}
                    </p>
                  )}

                  {paymentResponse?.description && (
                    <p>
                      {t('modules.payments.payWithNPE.description', {
                        description: paymentResponse?.description
                      })}
                    </p>
                  )}

                  <p>
                    {t('modules.payments.payWithNPE.capture', {
                      capture
                    })}
                  </p>

                  {amountToPay > 0 && (
                    <p>
                      {t('modules.payments.payWithNPE.amount', {
                        amount: amountToPay
                      })}
                    </p>
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col xs={12} md={6}>
              {Number(amountToPay) > 0 && (
                <section id='payment_methods'>
                  <h5 className='mb-4'>{t('common.checkout.paymentMethod')}</h5>
                  {paypalInfo ? (
                    <Card className='shadow'>
                      <CardHeader className='p-3'>
                        <label htmlFor='payWithPaypal' className='m-0'>
                          <input
                            type='radio'
                            name='paymentMethod'
                            id='payWithPaypal'
                            value={paypalInfo.metodo}
                            className='radio_animated'
                            onChange={handlePaymentMethod}
                          />
                          {t('common.checkout.paypalPayment')}
                        </label>
                      </CardHeader>
                      <Collapse isOpen={selectedPaymentMethod === 'paypal'}>
                        <CardBody>
                          <PaypalCheckoutButton
                            disabled={paymentCompleted}
                            amount={Number(amountToPay)}
                            description={t('modules.payments.payWithNPE.serviceName', {
                              serviceName: paymentResponse?.service.name
                            })}
                            onApprovePayment={onApprovePayment}
                            onErrorPayment={onErrorPayment}
                          />
                        </CardBody>
                      </Collapse>
                    </Card>
                  ) : null}

                  {stripeInfo ? (
                    <Card className='shadow'>
                      <CardHeader className='p-3'>
                        <label htmlFor='payWithCard' className='m-0'>
                          <input
                            type='radio'
                            name='paymentMethod'
                            id='payWithCard'
                            value={stripeInfo.metodo}
                            className='radio_animated'
                            onChange={handlePaymentMethod}
                          />
                          {t('common.checkout.stripePayment')}
                        </label>
                      </CardHeader>
                      <Collapse isOpen={selectedPaymentMethod === 'stripe'}>
                        <CardBody>
                          <StripeCheckoutForm
                            disabled={paymentCompleted}
                            amount={Number(amountToPay)}
                            onApprovePayment={onApprovePayment}
                            onErrorPayment={onErrorPayment}
                          />
                        </CardBody>
                      </Collapse>
                    </Card>
                  ) : null}
                </section>
              )}
            </Col>
          </>
        ) : null}
      </Row>
    </>
  );
};

export default PayWithNPE;
