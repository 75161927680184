import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';
import { Button } from 'reactstrap';
import { generatePayment } from 'services/stripe';
import styles from './stripe-checkout-form.module.scss';

const StripeCheckoutForm = ({
  disabled = false,
  amount,
  onApprovePayment = (order = {}) => {},
  onErrorPayment = (error = {}, paymentMethod) => {}
}) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [cardHolder, setCardHolder] = useState('');
  const [isCardHolderValid, setIsCardHolderValid] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleInputChange = (event) => {
    const { value } = event.target;
    const pattern = /[a-zA-Z][a-zA-Z ]{2,}/;
    setIsCardHolderValid(pattern.test(value));
    setCardHolder(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);

    // generating payment
    let paymentInfo = null;
    let errorPayment = null;

    try {
      const { data } = await generatePayment({ total: Number(amount), modulo: 1 });
      paymentInfo = data.info.data;
    } catch (error) {
      errorPayment = error;
    }

    if (errorPayment) {
      onErrorPayment(errorPayment, 'stripe');
      setProcessing(false);
      return;
    }

    // Creating payment
    const { error: errorPaymentMethod, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: {
        ...elements.getElement(CardNumberElement),
        ...elements.getElement(CardExpiryElement),
        ...elements.getElement(CardCvcElement)
      },
      billing_details: {
        name: cardHolder
      }
    });

    if (errorPaymentMethod) {
      onErrorPayment(errorPaymentMethod, 'stripe');
      setProcessing(false);
      return;
    }

    // Confirm payment
    const { error: errorPaymentIntent, paymentIntent } = await stripe.confirmCardPayment(
      paymentInfo.client_secret,
      {
        payment_method: paymentMethod.id
      }
    );

    if (errorPaymentIntent) {
      onErrorPayment(errorPaymentIntent, 'stripe');
      setProcessing(false);
      return;
    }

    onApprovePayment({ paymentIntent, paymentMethod });
    setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.formGroup}>
        <label htmlFor='card-number-element'>{t('common.stripePayment.cardNumber')}</label>
        <CardNumberElement
          id='card-number-element'
          className={styles.inputElement}
          options={{ showIcon: true }}
        />
      </div>
      <div className={styles.twoColumns}>
        <div className={styles.formGroup}>
          <label htmlFor='card-expiry-number-element'>{t('common.stripePayment.expiration')}</label>
          <CardExpiryElement id='card-expiry-number-element' className={styles.inputElement} />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor='card-cvc-number-element'>{t('common.stripePayment.cvc')}</label>
          <CardCvcElement id='card-cvc-number-element' className={styles.inputElement} />
        </div>
      </div>
      <div className={styles.formGroup}>
        <label htmlFor='card-holder-element'>{t('common.stripePayment.cardHolder')}</label>
        <input
          id='card-holder-element'
          className={`form-control ${
            isCardHolderValid !== null ? (isCardHolderValid ? 'is-valid' : 'is-invalid') : ''
          }`}
          name='card-holder-element'
          onChange={handleInputChange}
          value={cardHolder}
          required
        />
      </div>
      <div className='d-flex justify-content-end'>
        <Button
          type='submit'
          color='primary'
          className='btn-pill'
          disabled={!stripe || !elements || processing || disabled || !isCardHolderValid}
        >
          {t('common.formControls.payNow')}
        </Button>
      </div>
    </form>
  );
};

export default StripeCheckoutForm;
