import { Dashboard, PaymentServices, PayWithNPE, Transfers } from 'modules/payments';

export const paymentRoutes = [
  {
    path: '/app/payments/dashboard/',
    Component: Dashboard
  },
  {
    path: '/app/payments/services/',
    Component: PaymentServices
  },
  {
    path: '/app/payments/transfers',
    Component: Transfers
  },
  {
    path: '/app/payments/pay-with-npe',
    Component: PayWithNPE
  }
];
