import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Input, Row, Col, Button } from 'reactstrap';
import { getProcedure, addToCart, getCart } from 'services';
import { useLegalStore } from 'store/legalStore';
import { calculatePricePerCopies } from 'utils/legalServices';
import { LEGAL_MAX_NUMBER_OF_CERTIFICATES } from 'configurations/constants';

const ServiceInformation = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const deathCertificate = useLegalStore((state) => state.deathCertificate);
  const resetCertificates = useLegalStore((state) => state.resetCertificates);
  const service = useLegalStore((state) => state.service);
  const cartCount = useLegalStore((state) => state.cartCount);
  const setCartCount = useLegalStore((state) => state.setCartCount);

  const quantityRef = React.useRef();
  const [quantity, setQuantity] = useState(0);
  const [typeOfProcedure, setTypeOfProcedure] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [isAddingToCart, setIsAddingToCart] = useState(false);

  const totalCopies = cartCount;
  const maxAllowedCopies = LEGAL_MAX_NUMBER_OF_CERTIFICATES - totalCopies;
  const precioServicio = Number(service?.precioServicio);
  const precioTramite = Number(typeOfProcedure?.precio);
  const tipoTramite = deathCertificate?.tipoTramite;

  useEffect(() => {
    const fetchProcedures = () => {
      getProcedure(tipoTramite)
        .then(({ data: { info } }) => {
          setTypeOfProcedure(info.data);
        })
        .catch((error) => console.error('error getting type of procedure ', error))
        .finally(() => {
          if (quantityRef.current) {
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
              window.HTMLInputElement.prototype,
              'value'
            ).set;
            nativeInputValueSetter.call(quantityRef.current, 1);
            quantityRef.current.dispatchEvent(new Event('input', { bubbles: true }));
          }
        });
    };

    fetchProcedures();
  }, [tipoTramite]);

  const onAddToCart = () => {
    setIsAddingToCart(true);

    const data = {
      modulo: 1,
      servicio: Number(service.idServicio),
      data: {
        ...deathCertificate,
        tipoTramite: typeOfProcedure,
        servicio: service,
        numeroCopias: quantity
      },
      cantidad: Number(quantity)
    };

    addToCart(data)
      .then((data) => {
        Swal.fire({
          title: `<i class="ri-checkbox-circle-fill text-success"></i> ${t(
            'common.legal.serviceInformation.alertAddedtoCart.title'
          )}`,
          text: t('common.legal.serviceInformation.alertAddedtoCart.description'),
          buttonsStyling: false,
          cancelButtonText: t('common.legal.serviceInformation.alertAddedtoCart.addService'),
          showCancelButton: true,
          confirmButtonText: t(
            'common.legal.serviceInformation.alertAddedtoCart.proceedToCheckout'
          ),
          showConfirmButton: true,
          customClass: {
            cancelButton: 'btn btn-light btn-pill order-1 mr-2',
            confirmButton: 'btn btn-primary btn-pill order-2'
          }
        }).then((result) => {
          const modulo = 1;
          getCart(modulo)
            .then(({ count }) => {
              setCartCount(count);
            })
            .catch((error) => console.error('error getting cart count ', error));

          if (result.isConfirmed) {
            history.replace('/app/ecommerce/checkout');
          } else if (result.dismiss) {
            history.replace('/app/legal/create-application');
          }
          resetCertificates();
        });
      })
      .catch((error) => {
        setIsAddingToCart(false);
        console.error('error adding a product to the shopping cart ', error);
      });
  };

  const onChangeQuantity = ({ target: { value } }) => {
    if (Number(value) > 0 && Number(value) <= maxAllowedCopies) {
      setQuantity(Number(value));
      setTotalPrice(calculatePricePerCopies(precioServicio, precioTramite, Number(value)));
    }
  };

  return (
    <>
      <Row className='mt-3 mb-5'>
        <Col sm={12} className='mb-3'>
          <h4 className='sub-title'>{t('common.legal.serviceInformation.title')}</h4>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={8} xl={6} className='mb-3'>
          <table className='table mb-5 shadow-sm'>
            <tbody>
              <tr>
                <th>{t('common.legal.serviceInformation.serviceType')}</th>
                <td>{service?.nombreServicio}</td>
              </tr>
              <tr>
                <th>{t('common.legal.serviceInformation.servicePrice')}</th>
                <td>{`$ ${Number(service?.precioServicio).toFixed(2)}`}</td>
              </tr>
              <tr>
                <th>{t('common.legal.serviceInformation.typeOfProcedure')}</th>
                <td>{typeOfProcedure?.nombre_esp}</td>
              </tr>
              <tr>
                <th>{t('common.legal.serviceInformation.procedurePrice')}</th>
                <td>{`$ ${Number(typeOfProcedure?.precio).toFixed(2)}`}</td>
              </tr>
              <tr>
                <th>{t('common.legal.serviceInformation.quantity')}</th>
                <td>
                  <Input
                    style={{ maxWidth: '320px' }}
                    type='number'
                    value={quantity}
                    min='1'
                    max={maxAllowedCopies}
                    onChange={onChangeQuantity}
                    innerRef={quantityRef}
                  />
                </td>
              </tr>
              <tr>
                <th>{t('common.legal.serviceInformation.total')}</th>
                <td>{`$ ${totalPrice.toFixed(2)}`}</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <p className='text-muted info-alert'>
            <span className='ri-information-fill mr-2'></span>
            {t('common.legal.serviceInformation.help')}
          </p>
          <p className='text-muted'>{t('common.legal.serviceInformation.note')}</p>
        </Col>
      </Row>
      <Row>
        <Col sm='12' className='mt-3 d-flex justify-content-end'>
          <Button
            color='light'
            onClick={() => props.previousStep()}
            className='btn-pill d-flex align-items-center mr-2'
          >
            <span className='ri-arrow-left-line mr-2'></span> {t('common.formControls.previous')}
          </Button>
          <Button
            color='primary'
            onClick={onAddToCart}
            className='btn-pill d-flex align-items-center'
            disabled={quantity === 0 || isAddingToCart}
          >
            {t('common.formControls.addToCart')}
            <span className='ri-shopping-cart-2-line ml-2'></span>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ServiceInformation;
