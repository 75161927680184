import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'layout/breadcrumb';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { getTransfersRecord, getFavoritesTransfers } from 'services';
import { Carousel } from 'reactstrap';
import style from 'modules/payments/Dashboard/PaymentsDashboard.module.scss';
import { CustomButton } from 'modules/common/components/CustomButton';
import { Loader } from 'react-feather';
import { useCallback } from 'react';
import ClientCard from '../components/ClientCard';
import recordsCollection from 'utils/mocks/records';
import favorites from 'utils/mocks/favorites';
import FavoriteCard from '../components/FavoriteCard';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const [isFetching, setIsFetching] = useState(false);
  const { t } = useTranslation();

  const getTransferRecords = useCallback(async () => {}, []);

  useEffect(() => {
    getTransfersRecord()
      .then((data) => {
        console.log(data);
      })
      .catch(() => console.log('Error while getting the transfers records'))
      .finally();

    getFavoritesTransfers().then((data) => {});
  }, []);

  return (
    <>
      <Breadcrumbs
        parent={t('menu.payments.title')}
        module={t('modules.payments.title')}
        page={t('modules.payments.dashboard.title')}
        title={t('menu.payments.services')}
        icon='ri-bank-card-line'
        titleClassName={style.paymentTitle}
      />
      <div className={style.paymentHeader}>
        <CustomButton color='' outline className={style.favoriteButton} icon='ri-star-fill'>
          {t('modules.payments.dashboard.favoriteList')}
        </CustomButton>
        <Link to="/app/payments/transfers">
          <CustomButton color='primary' className='primary' icon='ri-send-plane-fill'>
            {t('modules.payments.dashboard.sendMoney')}
          </CustomButton>
        </Link>
      </div>
      <div className={style.dashboardBottom}>
        <div className='record-container'>
          <div className='record-container__title'>
            {t('modules.payments.dashboard.card1Title')}
          </div>
          {isFetching ? <Loader /> : null}
          {!isFetching && (
            <div className='record-container__records'>
              {recordsCollection.map((record) => (
                <ClientCard key={record.id} record={record} />
              ))}
            </div>
          )}
        </div>
        <div className='favorite-container'>
          <div className='record-container__title'>
            {t('modules.payments.dashboard.card2Title')}
          </div>
          {isFetching ? <Loader /> : null}
          {!isFetching && (
            <div className='record-container__records'>
              {favorites.map((favorite) => (
                <FavoriteCard key={favorite.id} favorite={favorite} />
              ))}
            </div>
          )}
        </div>
      </div>
      <ToastContainer position='top-right' />
    </>
  );
};

export default Dashboard;
