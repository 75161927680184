import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

const AddressDetails = ({ direccion, empresa_envio }) => {
  const { t } = useTranslation();
  return (
    <>
      <h6 className='my-4'>
        {t('modules.legal.requestDetails.addressDetails.title')}
      </h6>
      <table className='table table-striped table-bordered'>
        <tbody>
          <tr>
            <td>
              <Row>
                <Col sm={6} md={4} className='mb-3'>
                  <p className='h6 m-0'>
                    {t(
                      'modules.legal.requestDetails.addressDetails.primaryAddress'
                    )}
                  </p>
                  <small>
                    {`${direccion?.zipcode || ''}`} {`${direccion?.direccion}`}
                  </small>
                </Col>
                {direccion?.direccion2 && (
                  <Col sm={6} md={4} className='mb-3'>
                    <p className='h6 m-0'>
                      {t(
                        'modules.legal.requestDetails.addressDetails.secondaryAddress'
                      )}
                    </p>
                    <small>{`${direccion?.direccion2}`}</small>
                  </Col>
                )}
                <Col sm={6} md={4} className='mb-3'>
                  <p className='h6 m-0'>
                    {t(
                      'modules.legal.requestDetails.addressDetails.cityStateCountry'
                    )}
                  </p>
                  <small>{`${direccion?.ciudad}, ${direccion?.departamento?.nombre}, ${direccion?.departamento?.pais?.nombre_esp}`}</small>
                </Col>

                <Col sm={6} md={4} className='mb-3'>
                  <p className='h6 m-0'>
                    {t('modules.legal.requestDetails.addressDetails.phone')}
                  </p>
                  <small>{direccion?.telefono}</small>
                </Col>

                <Col sm={6} md={4} className='mb-3'>
                  <p className='h6 m-0'>
                    {t(
                      'modules.legal.requestDetails.addressDetails.shippingType'
                    )}
                  </p>
                  <small>{empresa_envio.empresa_envio.nombre}</small>
                </Col>
              </Row>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default AddressDetails;
