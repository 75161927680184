import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { CardColor } from 'modules/common/components';

const RequestStatus = (props) => {
  const { t } = useTranslation();

  const {
    requests: {
      contador_pendientes,
      contador_con_problemas,
      contador_en_proceso,
      contador_preparando_envio,
      contador_en_transito_envio,
      contador_finalizadas
    }
  } = props;

  return (
    <Row>
      <Col xs='3' md='3' xl='col-auto'>
        <CardColor
          cardStyle={'bg-warning'}
          textBody={t('modules.legal.dashboard.cards.pendings')}
          counter={contador_pendientes ?? 0}
          icon={'Clock'}
        ></CardColor>
      </Col>
      <Col xs='3' md='3' xl='col-auto'>
        <CardColor
          cardStyle={'bg-danger'}
          textBody={t('modules.legal.dashboard.cards.withProblems')}
          counter={contador_con_problemas ?? 0}
          icon={'AlertCircle'}
        ></CardColor>
      </Col>
      <Col xs='3' md='3' xl='col-auto'>
        <CardColor
          cardStyle={'bg-primary'}
          textBody={t('modules.legal.dashboard.cards.inProcess')}
          counter={(contador_en_proceso + contador_en_transito_envio + contador_preparando_envio) ?? 0}
          icon={'Repeat'}
        ></CardColor>
      </Col>
      <Col xs='3' md='3' xl='col-auto'>
        <CardColor
          cardStyle={'bg-success'}
          textBody={t('modules.legal.dashboard.cards.completed')}
          counter={contador_finalizadas ?? 0}
          icon={'CheckCircle'}
        ></CardColor>
      </Col>
    </Row>
  );
};

export default RequestStatus;
