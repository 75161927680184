import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// redux | store
import { Provider } from 'react-redux';
import store from 'store';
import { useAuthStore } from 'store/authStore';

// routes
import { privateRoutes, publicRoutes } from 'routes';

// components
import { App } from 'modules/app';

// i18n
import 'configurations/i18n';

// styles
import 'assets/scss/app.scss';
import 'remixicon/fonts/remixicon.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const Root = (props) => {
  const userInformation = useAuthStore((state) => state.userInformation);
  const isLoggedIn = !!userInformation && !!userInformation.token && !!userInformation.token2;
  const abortController = new AbortController();

  useEffect(() => {
    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PayPalScriptProvider
        options={{
          'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID
        }}
      >
        <Elements stripe={stripePromise}>
          <Provider store={store}>
            <BrowserRouter>
              <Switch>
                {publicRoutes.map(({ path, Component }) => (
                  <Route key={path} path={path} component={Component} />
                ))}

                {isLoggedIn ? (
                  <App>
                    <Route
                      exact
                      path='/'
                      render={() => {
                        return <Redirect to='/app/legal/dashboard' />;
                      }}
                    />
                    {privateRoutes.map(({ path, Component }) => (
                      <Route key={path} exact path={path}>
                        <div>
                          <Component />
                        </div>
                      </Route>
                    ))}
                  </App>
                ) : (
                  <Redirect to='/auth/login' />
                )}
              </Switch>
            </BrowserRouter>
          </Provider>
        </Elements>
      </PayPalScriptProvider>
    </>
  );
};
ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();
