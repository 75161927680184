import { Book, DollarSign, List } from 'react-feather';

/*For change the default home is necesary edit these files
    src/layout/sidebar/menu.jsx line 18 (no add the layout Dubai)
    src/layout/header/leafbar.jsx line 63
    src/layout/sidebar/index.jsx line 199
    src/layout/sidebar/index.jsx line 210
*/
export const MENUITEMS = [
  {
    menutitle: 'menu.title',
    menucontent: '',
    Items: [
      {
        title: 'menu.legal.dashboard',
        icon: Book,
        type: 'link',
        active: false,
        path: '/app/legal/dashboard'
      },
      {
        title: 'menu.legal.createApplication',
        icon: List,
        type: 'link',
        active: false,
        path: '/app/legal/create-application'
      }
      // {
      //   title: 'menu.payments.payWithNPE',
      //   icon: DollarSign,
      //   type: 'link',
      //   active: false,
      //   path: '/app/payments/pay-with-npe'
      // }
      // {
      //   title: 'menu.legal.title',
      //   icon: Book,
      //   type: 'sub',
      //   active: false,
      //   children: [
      //     {
      //       path: '/app/legal/dashboard',
      //       title: 'menu.legal.dashboard',
      //       type: 'link',
      //     },
      //     {
      //       path: '/app/legal/services',
      //       type: 'link',
      //       title: 'menu.legal.services',
      //     },
      //   ],
      // },
      // {
      //   title: 'menu.payments.title',
      //   icon: DollarSign,
      //   type: 'sub',
      //   active: false,
      //   children: [
      //     {
      //       path: '/app/payments/dashboard',
      //       title: 'menu.legal.dashboard',
      //       type: 'link',
      //     },
      //     {
      //       path: '/app/payments/services',
      //       type: 'link',
      //       title: 'menu.legal.services',
      //     },
      //   ],
      // },
    ]
  }
];
