import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { usePaymentStore } from "store/paymentStore";
import { getExpressPointProvidersByCategory } from "services";
import { EmptyPage, Loader, SelectableCard } from "modules/common/components";

const ExpressPointProviders = (props) => {
  const [providers, setProviders] = useState([]);
  const category = usePaymentStore((state) => state.category);
  const provider = usePaymentStore((state) => state.provider);
  const setProvider = usePaymentStore((state) => state.setProvider);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const fetchProviders = () => {
      getExpressPointProvidersByCategory(category?.id)
        .then(({ data: { info } }) => {
          setProviders(info.data);
        })
        .catch((error) => console.error("Error getting providers"))
        .finally(() => setIsFetching(false));
    };
    fetchProviders();
  }, [category.id]);

  const onSelectProvider = ({ target }) => {
    const { value: providerID } = target;
    const providerItem = providers.find(
      ({ id }) => Number(id) === Number(providerID)
    );
    setProvider(providerItem);
  };

  return (
    <Container fluid>
      <h3 className="mb-4 text-center">Proveedores</h3>
      {isFetching ? (
        <Loader />
      ) : providers.length ? (
        <>
          <Row>
            {providers.map(({ id, nombre, icono }) => (
              <Col sm="6" lg="4" key={`provider_${id}`}>
                <SelectableCard
                  id={`provider_${id}`}
                  name="provider"
                  value={id}
                  icon={icono || ""}
                  label={nombre}
                  onChange={onSelectProvider}
                  isChecked={provider?.id === id}
                />
              </Col>
            ))}
          </Row>
          <Row>
            <Col sm="12" className="mt-3 d-flex justify-content-between">
              <Button color="primary" onClick={() => props.previousStep()}>
                &laquo; Regresar
              </Button>
              <Button
                color="primary"
                disabled={false}
                onClick={() => props.nextStep()}
              >
                Siguiente &raquo;
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <EmptyPage
            icon="Search"
            title="No se encontraron datos"
            description={`No se encontraron proveedores disponibles para ${category.nombre}`}
          />
          <Row>
            <Col sm="12" className="mt-3 d-flex justify-content-between">
              <Button color="primary" onClick={() => props.previousStep()}>
                &laquo; Regresar
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ExpressPointProviders;
