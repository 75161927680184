import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Col, Form, Row, Button } from 'reactstrap';
import { getStates, getCities } from 'services';
import { TextField, DropDown } from 'modules/common/components';
import { useLegalStore } from 'store/legalStore';

const GeneralInformation = (props) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm();

  const setDivorceCertificate = useLegalStore((state) => state.setDivorceCertificate);
  const divorceCertificate = useLegalStore((state) => state.divorceCertificate);
  const service = useLegalStore((state) => state.service);

  const defaultCountry = {
    value: service.idPais,
    label: service.nombrePais
  };

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    const savedState = useLegalStore.getState().divorceCertificate?.departamento;

    if (defaultCountry.value) {
      getStatesByCountry(defaultCountry.value);
    }

    if (savedState) {
      getCitiesByState(savedState);
    }

    return () => {
      isMounted = false;
    };
  }, [defaultCountry.value]);

  const getStatesByCountry = (idCountry) => {
    getStates(idCountry)
      .then(({ data: { info } }) => {
        const data = info?.data?.map((state) => ({
          label: state.nombre,
          value: state.id
        }));
        setStates(data);
      })
      .catch((error) => console.error('Error getting states. ', error));
  };

  const getCitiesByState = (idState) => {
    getCities(idState)
      .then(({ data: { info } }) => {
        const data = info?.data?.map((city) => ({
          label: city.nombre,
          value: city.id
        }));
        setCities(data);
      })
      .catch((error) => console.error('Error getting cities. ', error));
  };

  const onSubmit = (data) => {
    const objDepartamento = states.find((item) => Number(item.value) === Number(data.departamento));
    const objCiudad = cities.find((item) => Number(item.value) === Number(data.ciudad));

    setDivorceCertificate({
      ...data,
      pais: defaultCountry.value,
      objPais: defaultCountry,
      objDepartamento,
      objCiudad
    });
    props.nextStep();
  };

  return (
    <>
      <h5>{t('common.legal.generalInfo.title')}</h5>
      <Form className='mt-5' onSubmit={handleSubmit(onSubmit)}>
        <Row className='mb-3'>
          <Col sm={12}>
            <h4 className='sub-title text-muted m-0'>{t('common.legal.husbandInformation')}</h4>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm='6' xl={3} className='mb-3'>
            <TextField
              id='nombresEsposo'
              name='nombresEsposo'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.names')}
              control={control}
              errors={errors}
              defaultValue={divorceCertificate?.nombresEsposo}
            />
          </Col>
          <Col sm='6' xl={3} className='mb-3'>
            <TextField
              id='apellidosEsposo'
              name='apellidosEsposo'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.surnames')}
              control={control}
              errors={errors}
              defaultValue={divorceCertificate?.apellidosEsposo}
            />
          </Col>
          <Col sm='6' xl={3} className='mb-3'>
            <TextField
              id='fechaNacimientoEsposo'
              name='fechaNacimientoEsposo'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.birthdate')}
              control={control}
              errors={errors}
              type='date'
              max={new Date().toISOString().slice(0, 10)}
              defaultValue={divorceCertificate?.fechaNacimientoEsposo}
            />
          </Col>
          <Col sm='6' xl={3} className='mb-3'>
            <TextField
              id='documentoIdentidadEsposo'
              name='documentoIdentidadEsposo'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.id')}
              control={control}
              errors={errors}
              defaultValue={divorceCertificate?.documentoIdentidadEsposo}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={12}>
            <h4 className='sub-title text-muted m-0'>{t('common.legal.wifeInformation')}</h4>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm='6' xl='3' className='mb-3'>
            <TextField
              id='nombresEsposa'
              name='nombresEsposa'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.names')}
              control={control}
              errors={errors}
              defaultValue={divorceCertificate?.nombresEsposa}
            />
          </Col>
          <Col sm='6' xl='3' className='mb-3'>
            <TextField
              id='apellidosEsposa'
              name='apellidosEsposa'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.surnames')}
              control={control}
              errors={errors}
              defaultValue={divorceCertificate?.apellidosEsposa}
            />
          </Col>
          <Col sm='6' xl='3' className='mb-3'>
            <TextField
              id='fechaNacimientoEsposa'
              name='fechaNacimientoEsposa'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.birthdate')}
              control={control}
              errors={errors}
              type='date'
              max={new Date().toISOString().slice(0, 10)}
              defaultValue={divorceCertificate?.fechaNacimientoEsposa}
            />
          </Col>
          <Col sm='6' xl='3' className='mb-3'>
            <TextField
              id='documentoIdentidadEsposa'
              name='documentoIdentidadEsposa'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.id')}
              control={control}
              errors={errors}
              defaultValue={divorceCertificate?.documentoIdentidadEsposa}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col sm={12}>
            <h4 className='sub-title text-muted m-0'>{t('common.legal.divorceInformation')}</h4>
          </Col>
        </Row>
        <Row>
          <Col sm='6' xl='3' className='mb-3'>
            <TextField
              id='registroDivorcio'
              name='registroDivorcio'
              className='form-control'
              label={t('common.legal.registryNumber')}
              control={control}
              defaultValue={divorceCertificate?.registroDivorcio}
            />
          </Col>
          <Col sm='6' xl='3' className='mb-3'>
            <TextField
              id='fechaDivorcio'
              name='fechaDivorcio'
              className='form-control'
              label={t('common.legal.divorceDate')}
              type='date'
              control={control}
              max={new Date().toISOString().slice(0, 10)}
              defaultValue={divorceCertificate?.fechaDivorcio}
            />
          </Col>
          <Col sm='6' xl='3' className='mb-3'>
            <TextField
              id='pais'
              name='pais'
              className='form-control'
              label={t('common.legal.country')}
              control={control}
              disabled
              defaultValue={defaultCountry?.label}
            />
          </Col>
          <Col sm='6' xl='3' className='mb-3'>
            <DropDown
              id='departamento'
              name='departamento'
              dataSource={states}
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.state')}
              control={control}
              errors={errors}
              handleChange={(event) => getCitiesByState(event.target.value)}
              defaultValue={divorceCertificate?.departamento}
            />
          </Col>
          <Col sm='6' xl='3' className='mb-3'>
            <DropDown
              id='ciudad'
              name='ciudad'
              dataSource={cities}
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.city')}
              control={control}
              errors={errors}
              defaultValue={divorceCertificate?.ciudad}
            />
          </Col>
          <Col md='12' className='mb-3'>
            <p className='text-muted info-alert'>
              <span className='ri-information-fill mr-2'></span>
              {t('common.legal.generalInfo.help')}
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm='12' className='mb-3 d-flex justify-content-end'>
            <Button className='btn-pill d-flex align-items-center' color='primary' type='submit'>
              {t('common.formControls.next')}
              <span className='ri-arrow-right-line ml-2'></span>
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default GeneralInformation;
