import React from 'react';
import { useTranslation } from 'react-i18next';
import StepWizard from 'react-step-wizard';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import Breadcrumb from 'layout/breadcrumb';
import { StepWizardNavigation } from 'modules/common/components';
import {
  ExpressPointCategories,
  ExpressPointForm,
  ExpressPointModules,
  ExpressPointProviders,
  ExpressPointServices
} from './components';

const PaymentServices = () => {
  const { t } = useTranslation();
  const titles = ['Modulos'];
  return (
    <>
      <Breadcrumb
        parent={t('menu.payments.title')}
        module={t('modules.payments.title')}
        page={t('modules.legal.createApplication.page')}
        title={t('menu.payments.services')}
        icon='ri-file-add-line'
      />
      <Container fluid={true}>
        <Row>
          <Col md='12'>
            <Card>
              <CardBody>
                <StepWizard
                  initialStep={1}
                  nav={<StepWizardNavigation titles={titles} />}
                  isLazyMount={true}
                >
                  <ExpressPointModules stepName={'ExpressPointModules'} />
                  <ExpressPointCategories stepName={'ExpressPointCategories'} />
                  <ExpressPointProviders stepName={'ExpressPointProviders'} />
                  <ExpressPointServices stepName={'ExpressPointServices'} />
                  <ExpressPointForm stepName={'ExpressPointForm'} />
                </StepWizard>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaymentServices;
