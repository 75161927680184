import React, { useEffect, useState, useCallback } from 'react';
import * as dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Row, Col, Card, CardBody, Form, Button } from 'reactstrap';
import {
  getCountries,
  getGenres,
  getUserInformation,
  updateUserInformation,
  updateUserPassword
} from 'services';
import { TextField, DropDown, Loader, PhoneField } from 'modules/common/components';
import { useAuthStore } from 'store/authStore';
import { handleHttpErrors } from 'utils/handleHttpErrors';

const PersonalInformation = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm();
  const updateUserInfo = useAuthStore((s) => s.updateUserInformation);
  const [togglePassword, setTogglePassword] = useState(false);
  const [countries, setCountries] = useState([]);
  const [genres, setGenres] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [userInfo, setUserInfo] = useState(null);

  const fetchData = useCallback(async () => {
    await getCountries()
      .then(({ data: { info } }) => {
        const data = info.data.map((c) => ({
          label: c.nombre_esp,
          value: c.id
        }));
        setCountries(data);
      })
      .catch((error) => handleHttpErrors(error, 'Error getting countries'));

    await getGenres()
      .then(({ data: { info } }) => {
        const data = info.data.map((g) => ({
          label: g.nombre_esp,
          value: g.id
        }));
        setGenres(data);
      })
      .catch((error) => handleHttpErrors(error, 'Error getting genres'));

    await getUserInformation()
      .then(({ data: { info } }) => {
        updateUserInfo(info.data);
        setUserInfo(info.data);
      })
      .catch((error) => handleHttpErrors(error, 'Error getting user information'));

    setIsFetching(false);
  }, [updateUserInfo]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSubmit = async (data) => {
    delete data.correo_electronico;
    const { contrasenia } = data;
    const onUpdateUserInfo = await updateUserInformation(data);

    if (onUpdateUserInfo.status !== 200) {
      toast.error(t('modules.settings.personalInformation.personalInfoMessages.error'));
      return;
    }

    if (contrasenia === '') {
      toast.success(t('modules.settings.personalInformation.personalInfoMessages.success'));
      fetchData();
      return;
    }

    const onUpdatePassword = await updateUserPassword(contrasenia);

    if (onUpdatePassword.status !== 200) {
      toast.error(t('modules.settings.personalInformation.accountInfoMessages.error'));
      return;
    }

    toast.success(t('modules.settings.personalInformation.accountInfoMessages.success'));
    setValue('contrasenia', '');
    fetchData();
  };

  if (isFetching) return <Loader />;

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card className='bg-neutral'>
          <CardBody>
            <Row>
              <Col className='mb-3'>
                <h6 className='mb-0 f-w-600'>
                  {t('modules.settings.personalInformation.personalInfoTitle')}
                </h6>
                <small>{t('modules.settings.personalInformation.personalInfoDescription')}</small>
              </Col>
            </Row>
            <Row>
              <Col sm='6' className='mb-3'>
                <TextField
                  id='nombres'
                  name='nombres'
                  className='form-control'
                  rules={{ required: t('common.formValidations.required') }}
                  label={t('modules.settings.personalInformation.personalInfoForm.names')}
                  control={control}
                  errors={errors}
                  defaultValue={userInfo?.nombres}
                />
              </Col>
              <Col sm='6' className='mb-3'>
                <TextField
                  id='apellidos'
                  name='apellidos'
                  className='form-control'
                  rules={{ required: t('common.formValidations.required') }}
                  label={t('modules.settings.personalInformation.personalInfoForm.lastnames')}
                  control={control}
                  errors={errors}
                  defaultValue={userInfo?.apellidos}
                />
              </Col>
              <Col sm='6' className='mb-3'>
                <TextField
                  id='fecha_nacimiento'
                  name='fecha_nacimiento'
                  className='form-control'
                  type='date'
                  rules={{ required: t('common.formValidations.required') }}
                  label={t('modules.settings.personalInformation.personalInfoForm.birthdate')}
                  control={control}
                  errors={errors}
                  defaultValue={dayjs(userInfo?.fecha_nacimiento).format('YYYY-MM-DD')}
                />
              </Col>
              <Col sm='6' className='mb-3'>
                <PhoneField
                  id='telefono'
                  name='telefono'
                  className='form-control'
                  rules={{ required: t('common.formValidations.required') }}
                  label={t('modules.settings.personalInformation.personalInfoForm.phone')}
                  control={control}
                  errors={errors}
                  defaultValue={userInfo?.telefono}
                />
              </Col>
              <Col sm='6' className='mb-3'>
                <DropDown
                  id='pais'
                  name='pais'
                  dataSource={countries}
                  className='form-control'
                  rules={{ required: t('common.formValidations.required') }}
                  label={t('modules.settings.personalInformation.personalInfoForm.country')}
                  control={control}
                  errors={errors}
                  defaultValue={userInfo?.pais?.id}
                />
              </Col>
              <Col sm='6' className='mb-3'>
                <DropDown
                  id='sexo'
                  name='sexo'
                  dataSource={genres}
                  className='form-control'
                  rules={{
                    required: t('common.formValidations.required')
                  }}
                  label={t('modules.settings.personalInformation.personalInfoForm.gender')}
                  control={control}
                  errors={errors}
                  defaultValue={userInfo?.sexo?.id}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className='bg-neutral'>
          <CardBody>
            <Row>
              <Col className='mb-3'>
                <h6 className='mb-0 f-w-600'>
                  {t('modules.settings.personalInformation.accountInfoTitle')}
                </h6>
                <small>{t('modules.settings.personalInformation.accountInfoDescription')}</small>
              </Col>
            </Row>
            <Row>
              <Col sm={6} className='mb-3'>
                <TextField
                  id='correo_electronico'
                  name='correo_electronico'
                  className='form-control'
                  label={t('modules.settings.personalInformation.accountInfoForm.email')}
                  defaultValue={userInfo?.correo_electronico}
                  disabled
                  control={control}
                  errors={errors}
                />
              </Col>
              <Col sm={6} className='mb-3'>
                <TextField
                  type={togglePassword ? 'text' : 'password'}
                  id='contrasenia'
                  name='contrasenia'
                  className='form-control'
                  rules={{
                    pattern: {
                      value: /^(?!.*\s)/g,
                      message: t('common.formValidations.noWhiteSpaces')
                    },
                    minLength: {
                      value: 5,
                      message: t('common.formValidations.minLength', { length: 5 })
                    }
                  }}
                  label={t('modules.settings.personalInformation.accountInfoForm.password')}
                  placeholder={t(
                    'modules.settings.personalInformation.accountInfoForm.passwordPlaceholder'
                  )}
                  control={control}
                  errors={errors}
                />
                <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                  <span className={togglePassword ? '' : 'show'}></span>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <div className='text-right'>
          <Button color='primary' className='btn-pill'>
            {t('common.formControls.saveChanges')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default PersonalInformation;
