import React, { useRef } from 'react';
import { Col, Container, Modal, Row } from 'reactstrap';
import { ReactComponent as Logo } from 'assets/images/login/logo_main.svg';
import style from './ConfirmationModal.module.scss';
import { CheckBox } from 'modules/common/components';
import { useForm } from 'react-hook-form';
import { CustomButton } from 'modules/common/components/CustomButton';
import { useTranslation } from 'react-i18next';
import useTransfer from 'store/transfer';

const ConfirmationModal = ({ openModal, setOpenModal, nextStep }) => {
  const { t } = useTranslation();
  const toggle = () => setOpenModal(!openModal);
  const ref = useRef(null);

  const remitente = useTransfer((state) => state.remitente);
  const destinatario = useTransfer((state) => state.destinatario);
  const setAddresseeInformation = useTransfer((state) => state.setAddresseeInformation);

  const {
    control,
    formState: { errors }
  } = useForm();

  const handleSubmit = () => {
    const data = {
      ...destinatario,
      favorito: ref.current.checked
    };
    setAddresseeInformation(data);
    nextStep();
  };

  return (
    <Modal className={style.modal} isOpen={openModal} toggle={toggle} centered unmountOnClose>
      <Container className={style.modalContainer}>
        <Row className='justify-content-center'>
          <Col xs='12'>
            <div className={style.modalHeader}>
              <Logo />
              <div className='cash'>
                <div className='icon-container'>
                  <i className='fa fa-usd' />
                </div>
                <span>Cash</span>
              </div>
            </div>
          </Col>
          <Row className='general-information'>
            <Col xs='12' className='general-information__name'>
              Para:{' '}
              <span>
                {destinatario.nombres} {destinatario.primer_apellido}{' '}
                {destinatario.segundo_apellido}
              </span>
            </Col>
            <Col xs='12'>
              {destinatario.tipo_documento_identidad}:{' '}
              <span>{destinatario.documento_identidad}</span>
            </Col>
            <Col xs='12'>
              Telefono: <span>{destinatario.telefono}</span>
            </Col>
          </Row>
          <Col xs='12' className='amount-information'>
            <div className='amount-information__amount'>{`$ ${Number(destinatario.monto).toFixed(
              2
            )}`}</div>
            <div>{remitente.isoCode}</div>
          </Col>
          <Col xs='12' className='email-container'>
            <div className='email-container__email'>{destinatario.correo_electronico}</div>
            <CheckBox
              id='favorito'
              name='favorito'
              label={
                <span className='email-container__favorite'>
                  Agregar a <span>favoritos.</span>
                </span>
              }
              control={control}
              errors={errors}
              ref={ref}
            />
          </Col>
          <Col xs='8' className='advice'>
            Confirma que la informacion guardada es veridica y correcta.
          </Col>
          <Col sm='12' className='d-flex justify-content-center align-items-center actions'>
            <CustomButton color='primary' outline className={style.favoriteButton} onClick={toggle}>
              {t('common.formControls.cancel')}
            </CustomButton>
            <CustomButton
              color='primary'
              className='primary'
              type='submit'
              icon='ri-send-plane-fill'
              onClick={handleSubmit}
            >
              {t('common.formControls.accept')}
            </CustomButton>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default ConfirmationModal;
