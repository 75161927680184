import { useEffect } from 'react';

export const useOutsideClick = ({ elementRef, handleClose = () => {} }) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!elementRef.current.contains(event.target)) {
        handleClose();
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [elementRef, handleClose]);
};
