import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import style from './CustomButton.module.scss';

/**
 * @param icon string | remix icon classname
 * @param children string
 * @param className string
 * @param ...rest other button props
 */

const CustomButton = ({ icon, children, className, ...rest }) => {
  return (
    <Button className={`${style.button} ${className ?? ''}`} {...rest}>
      {icon && <span className={`mr-2 ri-md ${icon}`} />}
      {children}
    </Button>
  );
};

CustomButton.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
};

export default CustomButton;
