import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

const useLegalStore = create(
  devtools(
    persist(
      (set, get) => ({
        service: null,
        birthCertificate: null,
        deathCertificate: null,
        registrationCertificate: null,
        registryInvestigation: null,
        divorceCertificate: null,
        marriageCertificate: null,
        cartCount: 0,
        setService: (service) =>
          set((state) => ({
            service,
          })),
        setBirthCertificate: (birthCertificate) =>
          set((state) => ({
            birthCertificate: {
              ...state.birthCertificate,
              ...birthCertificate,
            },
          })),
        setDeathCertificate: (deathCertificate) =>
          set((state) => ({
            deathCertificate: {
              ...state.deathCertificate,
              ...deathCertificate,
            },
          })),
        setRegistrationCertificate: (registrationCertificate) =>
          set((state) => ({
            registrationCertificate: {
              ...state.registrationCertificate,
              ...registrationCertificate,
            },
          })),
        setRegistryInvestigation: (registryInvestigation) =>
          set((state) => ({
            registryInvestigation: {
              ...state.registryInvestigation,
              ...registryInvestigation,
            },
          })),
        setDivorceCertificate: (divorceCertificate) =>
          set((state) => ({
            divorceCertificate: {
              ...state.divorceCertificate,
              ...divorceCertificate,
            },
          })),
        setMarriageCertificate: (marriageCertificate) =>
          set((state) => ({
            marriageCertificate: {
              ...state.marriageCertificate,
              ...marriageCertificate,
            },
          })),
        resetCertificates: () =>
          set((state) => ({
            service: null,
            birthCertificate: null,
            deathCertificate: null,
            registrationCertificate: null,
            registryInvestigation: null,
            divorceCertificate: null,
            marriageCertificate: null,
          })),
        setCartCount: (cartCount) =>
          set((state) => ({
            cartCount,
          })),
      }),
      {
        name: 'legal',
        getStorage: () => localStorage,
      }
    )
  )
);

export { useLegalStore };
