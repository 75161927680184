import { legalRoutes, paymentRoutes } from './private';
import { Checkout, shoppingCart } from 'modules/ecommerce';
import { Account } from 'modules/settings';

export const privateRoutes = [
  ...legalRoutes,
  ...paymentRoutes,
  {
    path: '/app/ecommerce/shopping-cart',
    Component: shoppingCart,
  },
  {
    path: '/app/ecommerce/checkout',
    Component: Checkout,
  },
  {
    path: '/app/settings/account/',
    Component: Account,
  },
];
