import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Form, Row, Col, Button } from 'reactstrap';
import { getProcedures } from 'services';
import { useLegalStore } from 'store/legalStore';

const TypeOfProcedure = (props) => {
  const { t } = useTranslation();
  const setRegistryInvestigation = useLegalStore((state) => state.setRegistryInvestigation);
  const registryInvestigation = useLegalStore((state) => state.registryInvestigation);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      tipoTramite: ''
    }
  });

  const [typeOfProcedures, setTypeOfProcedures] = useState([]);

  useEffect(() => {
    getProcedures()
      .then(({ data: { info } }) => {
        const data = info?.data?.map((procedure) => ({
          label: `${procedure.nombre_esp} - $${procedure.precio.toFixed(2)}`,
          value: procedure.id
        }));

        setTypeOfProcedures(data);
      })
      .catch((error) => console.error('Error getting type of procedures. ', error));
  }, []);

  const onSubmit = (data) => {
    const objTipoTramite = typeOfProcedures.find(
      (i) => Number(i.value) === Number(data.tipoTramite)
    );
    setRegistryInvestigation({
      tipoTramite: data.tipoTramite,
      objTipoTramite
    });
    props.nextStep();
  };

  return (
    <>
      <h5>{t('common.legal.typeOfProcedure.title')}</h5>

      <Form className='mt-3 mb-5' onSubmit={handleSubmit(onSubmit)}>
        <p className='f-w-400'>{t('common.legal.typeOfProcedure.label')}</p>
        <Row className='mb-3'>
          {typeOfProcedures.map((item) => (
            <Col xs={12} key={item.value} className='mb-1'>
              <div className='radio custom-radio-ml radio-dark'>
                <input
                  {...register('tipoTramite', {
                    required: t('common.formValidations.required')
                  })}
                  id={`tipoTramite_${item.value}`}
                  value={item.value}
                  type='radio'
                  defaultChecked={Number(item.value) === Number(registryInvestigation?.tipoTramite)}
                />
                <label className='m-0 f-w-400' htmlFor={`tipoTramite_${item.value}`}>
                  {item.label}
                </label>
              </div>
            </Col>
          ))}
          <Col xs={12}>
            <ErrorMessage
              errors={errors}
              name='tipoTramite'
              as={<small className='font-danger d-block mt-2' />}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <p className='text-muted info-alert'>
              <span className='ri-information-fill mr-2'></span>
              {t('common.legal.typeOfProcedure.help')}
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm='12' className='mt-3 d-flex justify-content-end'>
            <Button
              color='light'
              onClick={() => props.previousStep()}
              className='btn-pill d-flex align-items-center mr-2'
            >
              <span className='ri-arrow-left-line mr-2'></span> {t('common.formControls.previous')}
            </Button>
            <Button className='btn-pill d-flex align-items-center' color='primary' type='submit'>
              {t('common.formControls.next')} <span className='ri-arrow-right-line ml-2'></span>
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default TypeOfProcedure;
