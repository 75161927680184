import React, { forwardRef } from 'react';
import { Label, Input } from 'reactstrap';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

/**
 * @param id string
 * @param name string
 * @param rules react-hook-form (rules object)
 * @param label string
 * @param control react-hook-form (useForm() property)
 * @param errors react-hook-form (useForm() property)
 * @param onChange handle change function
 * @param defaultValue
 *  */

const CheckBox = forwardRef(
  (
    { id, name, rules = {}, label, control, errors = null, handleChange = (e) => {}, defaultValue },
    ref
  ) => {
    return (
      <>
        <Controller
          name={name}
          control={control}
          rules={{ ...rules }}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div className='checkbox custom-radio-ml checkbox-dark'>
              <Input
                {...field}
                type='checkbox'
                onChange={(e) => {
                  handleChange(e);
                  field.onChange(e.target.checked);
                }}
                checked={field.value}
                id={id}
                innerRef={ref}
              />
              <Label for={id}>{label}</Label>
            </div>
          )}
        />

        {errors && (
          <ErrorMessage errors={errors} name={name} as={<small className='font-danger' />} />
        )}
      </>
    );
  }
);

export default CheckBox;
