import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en_US from 'languages/en_US.json';
import es_ES from 'languages/es_ES.json';

let defaultLanguage = localStorage.getItem('language') ?? null;
  defaultLanguage =
    defaultLanguage && ['en', 'es'].includes(defaultLanguage)
      ? defaultLanguage
      : 'es';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en_US,
    },
    es: {
      translation: es_ES,
    },
  },
  lng: defaultLanguage,
  defaultNS: 'translation',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
