import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, FormGroup, Button, Form } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import { Loader } from 'react-feather';
import { TextField, DropDown, PhoneField } from 'modules/common/components';
import { createUser, getCountries, getGenres } from 'services';
import { handleHttpErrors } from 'utils/handleHttpErrors';
import { useAuthStore } from 'store/authStore';
import 'react-toastify/dist/ReactToastify.css';

const RegisterUser = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm();
  const setUserInformation = useAuthStore((state) => state.setUserInformation);

  const [togglePassword, setTogglePassword] = useState(false);
  const [countries, setCountries] = useState([]);
  const [genres, setGenres] = useState([]);
  const [isRegistering, setIsRegistering] = useState(false);

  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword);
  };

  useEffect(() => {
    fetchCountries();
    fetchGenres();
  }, []);

  const fetchCountries = async () => {
    getCountries()
      .then(({ data: { info } }) => {
        const data = info?.data?.map((country) => ({
          label: country.nombre_esp,
          value: country.id
        }));

        setCountries(data);
      })
      .catch((error) => handleHttpErrors(error, 'Error getting countries'));
  };

  const fetchGenres = async () => {
    getGenres()
      .then(({ data: { info } }) => {
        const data = info?.data?.map((genre) => ({
          label: genre.nombre_esp,
          value: genre.id
        }));

        setGenres(data);
      })
      .catch((error) => handleHttpErrors(error, 'Error getting genres'));
  };

  const onSubmit = async (form) => {
    setIsRegistering(true);
    const request = {
      correo_electronico: form.email,
      contrasenia: form.password,
      nombres: form.firstName,
      apellidos: form.lastName,
      pais: Number(form.country),
      sexo: Number(form.genre),
      fecha_nacimiento: form.birthdate,
      telefono: form.phone
    };

    createUser(request)
      .then(({ data: { info } }) => {
        setUserInformation(info.data);
        toast.success(t('modules.auth.common.messages.registerWithEmail'));
        setTimeout(() => {
          history.push('/app/legal/dashboard');
        }, [2000]);
      })
      .catch((error) => {
        handleHttpErrors(error, 'Error registering new user');
        setIsRegistering(false);
      });
  };

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col
            xl='7'
            className='b-center bg-size'
            style={{
              backgroundImage: `url(${require('assets/images/login/auth-bg.jpg')})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'block'
            }}
          ></Col>
          <Col xl='5' className='p-0'>
            <div className='login-card'>
              <div>
                <div>
                  <Link className='logo' to='/'>
                    <img
                      className='img-fluid for-light'
                      src={require('assets/images/logo/logo.png')}
                      alt='tkiero'
                    />
                  </Link>
                </div>
                <div className='login-main'>
                  <Form
                    className='theme-form needs-validation mt-5'
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <h4>{t('modules.auth.register.title')}</h4>
                    <p>{t('modules.auth.register.subtitle')}</p>
                    <FormGroup>
                      <div className='form-row'>
                        <Col xs='6'>
                          <TextField
                            id='firstName'
                            name='firstName'
                            className='form-control'
                            rules={{
                              required: t('common.formValidations.required')
                            }}
                            label={t('modules.auth.register.name')}
                            control={control}
                            errors={errors}
                          />
                        </Col>
                        <Col xs='6'>
                          <TextField
                            id='lastName'
                            name='lastName'
                            className='form-control'
                            rules={{
                              required: t('common.formValidations.required')
                            }}
                            label={t('modules.auth.register.lastname')}
                            control={control}
                            errors={errors}
                          />
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <TextField
                        id='email'
                        name='email'
                        className='form-control'
                        rules={{
                          required: t('common.formValidations.required')
                        }}
                        label={t('modules.auth.register.email')}
                        control={control}
                        errors={errors}
                      />
                    </FormGroup>
                    <FormGroup>
                      <TextField
                        id='password'
                        name='password'
                        type={togglePassword ? 'text' : 'password'}
                        className='form-control'
                        rules={{
                          required: t('common.formValidations.required'),
                          minLength: {
                            value: 5,
                            message: t('common.formValidations.minLength', {
                              length: 5
                            })
                          }
                        }}
                        label={t('modules.auth.register.password')}
                        control={control}
                        errors={errors}
                      />
                      <div className='show-hide' onClick={() => HideShowPassword(togglePassword)}>
                        <span className={togglePassword ? '' : 'show'}></span>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <TextField
                        id='birthdate'
                        name='birthdate'
                        className='form-control'
                        type='date'
                        rules={{
                          required: t('common.formValidations.required')
                        }}
                        label={t('modules.auth.register.birthdate')}
                        control={control}
                        errors={errors}
                      />
                    </FormGroup>
                    <FormGroup>
                      <PhoneField
                        id='phone'
                        name='phone'
                        className='form-control'
                        rules={{
                          required: t('common.formValidations.required')
                        }}
                        label={t('modules.auth.register.phone')}
                        control={control}
                        errors={errors}
                      />
                    </FormGroup>
                    <FormGroup className='mb-3'>
                      <DropDown
                        id='country'
                        name='country'
                        dataSource={countries}
                        className='form-control'
                        rules={{
                          required: t('common.formValidations.required')
                        }}
                        label={t('modules.auth.register.country')}
                        control={control}
                        errors={errors}
                      />
                    </FormGroup>
                    <FormGroup className='mb-3'>
                      <DropDown
                        id='genre'
                        name='genre'
                        dataSource={genres}
                        className='form-control'
                        rules={{
                          required: t('common.formValidations.required')
                        }}
                        label={t('modules.auth.register.genre')}
                        control={control}
                        errors={errors}
                      />
                    </FormGroup>
                    <FormGroup className='mb-0'>
                      <Button
                        color='primary'
                        className='btn-block'
                        type='submit'
                        disabled={isRegistering}
                      >
                        {isRegistering && <Loader size={15} className='fa-spin mr-1' />}
                        {t('modules.auth.register.createAccount')}
                      </Button>
                    </FormGroup>
                    <p className='mt-4 mb-0'>
                      {t('modules.auth.register.haveAnAccount')}
                      <Link className='ml-2' to={'/auth/login'}>
                        {t('modules.auth.register.login')}
                      </Link>
                    </p>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer position='top-right' />
    </>
  );
};

export default RegisterUser;
