import React from "react";
import styles from "./StepWizardNavigation.module.scss";

const StepWizardNavigation = (props) => {
  const dots = [];
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i;
    dots.push(
      <span
        key={`step-navigation-${i}`}
        className={`${styles.dot} ${isActive ? styles.active : ""}`}
      >
        {i}
      </span>
    );
  }
  return <div className={styles.nav}>{dots}</div>;
};

export default StepWizardNavigation;
