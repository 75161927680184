import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Breadcrumb from 'layout/breadcrumb';
import { Container, Row, Col, Card, CardBody, Button, UncontrolledTooltip } from 'reactstrap';
import Swal from 'sweetalert2';
import { getCart, deleteProduct } from 'services';
import { useLegalStore } from 'store/legalStore';
import { ShoppingCart as ShoppingCartIcon } from 'react-feather';
import { Loader } from 'modules/common/components';
import { LEGAL_MAX_NUMBER_OF_CERTIFICATES } from 'configurations/constants';

const ShoppingCart = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const setCartCount = useLegalStore((store) => store.setCartCount);
  const [services, setServices] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  let totalQuantity = 0;
  let sumTotalPrice = 0.0;

  useEffect(() => {
    fetchShoppingCart();
  }, []);

  const fetchShoppingCart = async () => {
    setIsFetching(true);
    const modulo = 1;
    getCart(modulo)
      .then(({ count, data }) => {
        setServices(data);
      })
      .catch((error) => {
        console.error('error getting cart ', error);
      })
      .finally(() => {
        setTimeout(() => setIsFetching(false), 250);
      });
  };

  const onDeleteService = (serviceId = 0) => {
    Swal.fire({
      text: t('common.legal.ecommerce.shoppingCart.alertDeleteItem.title'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('common.formControls.delete'),
      cancelButtonText: t('common.formControls.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-pill btn-danger mr-2',
        cancelButton: 'btn btn-pill btn-light'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProduct(serviceId)
          .then(() => {
            Swal.fire({
              text: t('common.legal.ecommerce.shoppingCart.alertDeleteItem.message'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: t('common.formControls.accept'),
              customClass: {
                confirmButton: 'btn btn-pill btn-success'
              }
            }).then(() => {
              const modulo = 1;
              getCart(modulo)
                .then(({ count, data }) => {
                  setCartCount(count);
                  setServices(data);
                })
                .catch((error) => console.error('error getting cart ', error))
                .finally(() => {
                  setTimeout(() => setIsFetching(false), 250);
                });
            });
          })
          .catch((error) => console.error('error deleting product from shopping cart ', error));
      }
    });
  };

  return (
    <>
      <Breadcrumb
        module={t('common.legal.ecommerce.title')}
        page={t('common.legal.ecommerce.shoppingCart.page')}
        title={t('common.legal.ecommerce.shoppingCart.page')}
      />

      {isFetching ? (
        <Loader />
      ) : services.length ? (
        <Container fluid={true}>
          <Row>
            <Col sm='12'>
              <Card>
                <CardBody className='cart cart-wrapper'>
                  <table className='table mb-5 '>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>{t('common.legal.ecommerce.shoppingCart.serviceType')}</th>
                        <th>{t('common.legal.ecommerce.shoppingCart.typeOfProcedure')}</th>
                        <th>{t('common.legal.ecommerce.shoppingCart.quantity')}</th>
                        <th>{t('common.legal.ecommerce.shoppingCart.totals')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {services?.map((service) => {
                        totalQuantity += Number(service.cantidad);
                        sumTotalPrice += Number(service.precio_total);
                        return (
                          <tr key={service.id}>
                            <td>
                              <UncontrolledTooltip target={`btn-delete-${service.id}`}>
                                {t('common.formControls.delete')}
                              </UncontrolledTooltip>
                              <Button
                                id={`btn-delete-${service.id}`}
                                size='xs'
                                color='danger'
                                className='btn-pill'
                                onClick={() => onDeleteService(service.id)}
                              >
                                x
                              </Button>
                            </td>
                            <td>{service.servicio.nombre_esp}</td>
                            <td>{service.data?.tipoTramite.nombre_esp}</td>
                            <td>{service.cantidad}</td>
                            <td>{`$ ${Number(service.precio_total).toFixed(2)}`}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className='text-right' colSpan={3}>
                          {t('common.legal.ecommerce.shoppingCart.totals')}
                        </td>
                        <td>{totalQuantity}</td>
                        <td>{`$ ${Number(sumTotalPrice).toFixed(2)}`}</td>
                      </tr>
                    </tfoot>
                  </table>
                  <div className='info-user'>
                    <p className='text-muted info-alert'>
                      <span className='ri-information-fill mr-2'></span>
                      {t('common.legal.ecommerce.help', {
                        maxNumberOfCertificates: LEGAL_MAX_NUMBER_OF_CERTIFICATES
                      })}
                    </p>
                  </div>
                  <div className='text-right'>
                    <Button
                      color='primary'
                      className='btn-pill'
                      onClick={() => history.push('/app/ecommerce/checkout')}
                    >
                      {t('common.formControls.proceedToCheckout')}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <section className='cart-section my-5'>
          <Container fluid={true} className='text-center'>
            <div className='cart-icon'>
              <ShoppingCartIcon size={32} />
            </div>
            <h5>
              <strong>{t('common.legal.ecommerce.shoppingCart.emptyCart.title')}</strong>
            </h5>
            <p className='font-roboto text-muted '>
              {t('common.legal.ecommerce.shoppingCart.emptyCart.description')}
            </p>
          </Container>
        </section>
      )}
    </>
  );
};

export default ShoppingCart;
