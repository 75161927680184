const idCardTypes = Object.freeze([
  {
    id: 1,
    value: 'Pasaporte',
    label: 'Pasaporte'
  },
  {
    id: 2,
    value: 'DUI',
    label: 'DUI'
  }
]);

export default idCardTypes;
