import { CustomButton } from 'modules/common/components/CustomButton';
import { PaypalCheckoutButton, StripeCheckoutForm } from 'modules/ecommerce/checkout/components';
import React, { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Collapse, Container, Row } from 'reactstrap';
import { getPaymentMethods, postTransfers } from 'services';
import useTransfer from 'store/transfer';
import style from './Payment.module.scss';

const Payment = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [stripeInfo, setStripeInfo] = useState(null);
  const [paypalInfo, setPaypalInfo] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const remitente = useTransfer((state) => state.remitente);
  const destinatario = useTransfer((state) => state.destinatario);

  const fetchPaymentMethods = () => {
    getPaymentMethods()
      .then(({ data: { info } }) => {
        const paypal = info.data.find((i) => i.metodo === 'paypal');
        const stripe = info.data.find((i) => i.metodo === 'stripe');
        console.log(info);
        setPaypalInfo(paypal);
        setStripeInfo(stripe);
      })
      .catch((error) => console.error('error getting payment methods ', error));
  };

  const handlePaymentMethod = ({ target }) => {
    const { value } = target;
    setSelectedPaymentMethod(value);
  };

  const onErrorPayment = async (error, paymentMethod) => {
    if (paymentMethod === 'stripe') {
      toast.error(error.message);
    } else {
      console.error(`[${paymentMethod}] - error on processing the payment: `, error);
    }
  };

  const onApprovePayment = useCallback(
    async (order) => {
      setPaymentCompleted(true);
      const data = {
        remitente,
        destinatario,
        autorizacion_pago: order
      };
      postTransfers(data)
        .then(({ data: { info } }) => {
          toast.done('Se ha realizado la transferencia con exito!');
          history.push(`/app/payments/dashboard`);
        })
        .catch((error) => console.log('Error while creating transaction', error));
    },
    [remitente, destinatario, history]
  );

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col xs='0' md='4'></Col>
        <Col xs='12' md='8'>
          <Card>
            <CardBody>
              <div className='order-box'>
                <h5 className='mb-4 d-flex justify-content-between'>
                  {t('common.checkout.orderDetails')}
                  <CustomButton
                    color='primary'
                    outline
                    className={style.favoriteButton}
                    type='submit'
                    onClick={props.prevStep()}
                  >
                    <i className='fa fa-arrow-left' />
                    {t('common.formControls.back')}
                  </CustomButton>
                </h5>
                <section id='product_list' className='mb-4'>
                  <ul className='order-detail'>
                    <li className='mb-3'>
                      <div className='d-flex justify-content-between'>
                        <div>
                          <p className='m-0'>{`${t('common.addresses.form.recipient')}: ${
                            destinatario.nombres
                          } x 1`}</p>
                          <small>Transaccion</small>
                        </div>
                        <span>{`$ ${Number(destinatario.monto).toFixed(2)}`}</span>
                      </div>
                    </li>
                  </ul>
                  <div className='d-flex justify-content-end summary'>
                    <p>
                      {t('common.checkout.totalPayment')}
                      <span className='pl-3'> {`$ ${Number(destinatario.monto).toFixed(2)}`} </span>
                    </p>
                  </div>
                </section>
                <section id='payment_methods'>
                  {paypalInfo ? (
                    <Card className='shadow'>
                      <CardHeader className='p-3'>
                        <label htmlFor='payWithPaypal' className='m-0'>
                          <input
                            type='radio'
                            name='paymentMethod'
                            id='payWithPaypal'
                            value='paypal'
                            className='radio_animated'
                            onChange={handlePaymentMethod}
                          />
                          {t('common.checkout.paypalPayment')}
                        </label>
                      </CardHeader>
                      <Collapse isOpen={selectedPaymentMethod === 'paypal'}>
                        <CardBody>
                          <PaypalCheckoutButton
                            disabled={
                              !(destinatario && destinatario.monto && Number(destinatario.monto)) ||
                              paymentCompleted
                            }
                            onErrorPayment={onErrorPayment}
                            amount={destinatario.monto}
                            onApprovePayment={onApprovePayment}
                            description={t('common.legal.ecommerce.checkout.orderDescription')}
                          />
                        </CardBody>
                      </Collapse>
                    </Card>
                  ) : null}

                  {stripeInfo ? (
                    <Card className='shadow'>
                      <CardHeader className='p-3'>
                        <label htmlFor='payWithCard' className='m-0'>
                          <input
                            type='radio'
                            name='paymentMethod'
                            id='payWithCard'
                            value='stripe'
                            className='radio_animated'
                            onChange={handlePaymentMethod}
                          />
                          {t('common.checkout.stripePayment')}
                        </label>
                      </CardHeader>
                      <Collapse isOpen={selectedPaymentMethod === 'stripe'}>
                        <CardBody>
                          <StripeCheckoutForm
                            disabled={
                              !(destinatario && destinatario.monto && Number(destinatario.monto)) ||
                              paymentCompleted
                            }
                            onErrorPayment={onErrorPayment}
                            onApprovePayment={onApprovePayment}
                            amount={destinatario.monto}
                            description={t('common.legal.ecommerce.checkout.orderDescription')}
                          />
                        </CardBody>
                      </Collapse>
                    </Card>
                  ) : null}
                </section>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Payment;
