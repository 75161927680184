import React, { useRef } from 'react';
import { Label, Input, Card, CardBody } from 'reactstrap';
import styles from './SelectableCard.module.scss';

const SelectableCard = ({
  id,
  name,
  label,
  value,
  icon,
  className,
  onChange,
  isChecked,
}) => {
  const inputRef = useRef();

  const handleClickCard = (e) => {
    inputRef.current.click();
  };

  return (
    <Card
      className={`${styles.selectable__card} ${className} ${
        isChecked || inputRef.current?.checked ? styles.selected : ''
      }`}
      onClick={handleClickCard}
      role='button'
      tabIndex={0}
    >
      <CardBody className={`${styles.selectable__cardbody} animated-chk`}>
        {icon && <img src={icon} alt={value} />}
        <Input
          type='radio'
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          innerRef={inputRef}
          className='radio_animated'
          defaultChecked={isChecked}
        />
        <Label for={id} className='f-16 f-w-300 text-center mb-0 mt-2'>
          {label}
        </Label>
      </CardBody>
    </Card>
  );
};

export default SelectableCard;
