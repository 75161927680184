import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button, Alert, Form, FormGroup } from 'reactstrap';
import { useAuthStore } from 'store/authStore';
import { validateVerificationCode, resendVerificationCode } from 'services';
import { Loader, TextField } from 'modules/common/components';

const VerificationCode = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const setUserInformation = useAuthStore((state) => state.setUserInformation);
  const userInformation = useAuthStore((state) => state.userInformation);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = ({ pin }) => {
    setIsSubmitting(true);
    validateVerificationCode(pin, userInformation?.token)
      .then(({ data: { info } }) => {
        setUserInformation(info.data);
        history.push('/app/legal/dashboard');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error(t('modules.auth.verificationCode.invalidCode'));
        } else {
          toast.error(error.response.data.error);
        }
        console.error('Error verifying the code ', error.response);
      })
      .finally(() => setIsSubmitting(false));
  };

  const resendCode = () => {
    resendVerificationCode(userInformation?.token)
      .then(({ data: { info } }) => {
        toast.success(t('modules.auth.verificationCode.codeResentSuccessfully'));
      })
      .catch((error) => {
        toast.error(t('modules.auth.verificationCode.codeResentError'));
        console.error('Error trying to resent the code ', error.response);
        setTimeout(() => history.push('/auth/legal'), 3000);
      });
  };

  return (
    <div>
      <Container fluid={true}>
        <Row>
          <Col xs='12'>
            <div className='login-card'>
              <div>
                <div>
                  <a className='logo' href='#javascript'>
                    <img
                      className='img-fluid for-light'
                      src={require('assets/images/logo/logo.png')}
                      alt='tkiero.app'
                    />
                  </a>
                </div>
                <Alert
                  style={{ maxWidth: '450px' }}
                  className='outline bg-white'
                  color='dark inverse'
                  isOpen={true}
                  target='Alert-1'
                >
                  <i className='icon-info-alt'></i>
                  <small className='d-block pr-2'>{t('modules.auth.verificationCode.alert')}</small>
                </Alert>
                <div className='login-main'>
                  <Form className='theme-form' onSubmit={handleSubmit(onSubmit)}>
                    <h4 className='mb-3'>{t('modules.auth.verificationCode.title')}</h4>
                    <FormGroup className='mb-3'>
                      <TextField
                        id='pin'
                        name='pin'
                        className='form-control'
                        type='text'
                        rules={{
                          required: t('common.formValidations.required'),
                          minLength: {
                            value: 6,
                            message: t('common.formValidations.minLength', {
                              length: 6,
                            }),
                          },
                          pattern: {
                            value: /^[A-Za-z0-9]*$/,
                            message: t('common.formValidations.lettersAndNumbers'),
                          },
                        }}
                        control={control}
                        errors={errors}
                        placeholder={t('modules.auth.verificationCode.inputPlaceholder')}
                      />
                    </FormGroup>
                    <FormGroup className='mb-3'>
                      <Button
                        color='primary'
                        className='btn-block'
                        type='submit'
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <Loader size={15} className='fa-spin' />
                        ) : (
                          <>
                            {t('modules.auth.verificationCode.continue')}{' '}
                            <i className='fa fa-arrow-right ml-1'></i>
                          </>
                        )}
                      </Button>
                    </FormGroup>
                    <FormGroup>
                      <Trans
                        i18nKey='modules.auth.verificationCode.didNotReceiveCode'
                        components={{
                          Button: <Button color='link' className='px-2' onClick={resendCode} />,
                        }}
                      />
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer position='top-right' />
    </div>
  );
};

export default VerificationCode;
