import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Loader } from 'react-feather';
import DataTable from 'react-data-table-component';
import { dateFormat } from 'utils/date';

const Requests = ({ requests, isSearching }) => {
  const { t } = useTranslation();
  const columns = [
    {
      name: t('modules.legal.dashboard.table.headers.status'),
      cell: (row) => (
        <div className={`status status-${row.estado.id}`}>{row.estado.nombre_esp}</div>
      ),

      sortable: true
    },
    {
      name: t('modules.legal.dashboard.table.headers.requestNumber'),
      cell: (row) => (
        <div data-tag='allowRowEvents' className='order-link'>
          <Link to={`/app/legal/request-details/${row.numero_solicitud}`}>
            {row.numero_solicitud}
          </Link>
        </div>
      ),
      sortable: true
    },
    {
      name: t('modules.legal.dashboard.table.headers.provider'),
      selector: 'proveedor.nombre_comercial',
      sortable: true
    },
    {
      name: t('modules.legal.dashboard.table.headers.shippingType'),
      selector: 'empresa_envio.empresa_envio.nombre',
      sortable: true
    },
    {
      name: t('modules.legal.dashboard.table.headers.date'),
      cell: (row) => <div data-tag='allowRowEvents'>{dateFormat(row.fecha_creacion)}</div>,
      sortable: true
    }
  ];

  return (
    <Row>
      <Col>
        <DataTable
          noHeader
          columns={columns}
          data={requests}
          pagination={true}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[5, 10, 20]}
          paginationComponentOptions={{
            rowsPerPageText: t('common.datatable.rowsPerPage')
          }}
          defaultSortField='fecha_creacion'
          defaultSortAsc={false}
          progressPending={isSearching}
          progressComponent={
            <div className='p-3'>
              <Loader className='fa-spin' />
            </div>
          }
          noDataComponent={<div className='p-3'>{t('common.datatable.emptyRecords')}</div>}
        />
      </Col>
    </Row>
  );
};

export default Requests;
