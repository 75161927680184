import React, { useEffect, useRef } from 'react';
import { Label, Input } from 'reactstrap';
import styles from './ButtonTags.module.scss';

/**
 *
 * @param {*} dataSource Array of objects [{ label: '', value: ''}]
 * @param {*} onChange input change event
 */
const ButtonTags = ({ id, name, dataSource, className, onChange }) => {
  const inputRefs = useRef([]);
  inputRefs.current = dataSource.map(
    (_, i) => inputRefs.current[i] ?? React.createRef()
  );

  useEffect(() => {
    if (inputRefs.current.length) {
      inputRefs.current[0].current.click();
    }
  }, [dataSource]);

  return (
    <div className={`${styles.button__tags} ${className ? className : ''}`}>
      {dataSource?.length &&
        dataSource.map((item, index) => (
          <div className={`${styles.button__tag}`} key={item.value}>
            <Input
              type='radio'
              id={`${id}_${item.value}`}
              name={name}
              value={item.value}
              onChange={onChange}
              innerRef={inputRefs.current[index]}
            />
            <Label for={`${id}_${item.value}`}>{item.label}</Label>
          </div>
        ))}
    </div>
  );
};

export default ButtonTags;
