import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';
import { useLegalStore } from 'store/legalStore';

const Summary = (props) => {
  const { t } = useTranslation();
  const marriageCertificate = useLegalStore((state) => state.marriageCertificate);

  return (
    <>
      <h5 className='mb-5'>{t('common.legal.summary.title')}</h5>
      <Row>
        <Col md={12} xl={6}>
          <Row className='mb-3'>
            <Col sm={12} className='mb-3'>
              <h4 className='sub-title text-muted'>{t('common.legal.husbandInformation')}</h4>
            </Col>
            <Col sm={12} md={6} className='mb-3'>
              <p className='f-w-500 mb-0 text-primary'>{t('common.legal.names')}</p>
              <p className='text-muted'>{`${marriageCertificate?.nombresEsposo}`}</p>
            </Col>
            <Col sm={12} md={6} className='mb-3'>
              <p className='f-w-500 mb-0 text-primary'>{t('common.legal.surnames')}</p>
              <p className='text-muted'>{`${marriageCertificate?.apellidosEsposo}`}</p>
            </Col>
            <Col sm={12} md={6} className='mb-3'>
              <p className='f-w-500 mb-0 text-primary'>{t('common.legal.birthdate')}</p>
              <p className='text-muted'>{`${marriageCertificate?.fechaNacimientoEsposo}`}</p>
            </Col>
            <Col sm={12} md={6} className='mb-3'>
              <p className='f-w-500 mb-0 text-primary'>{t('common.legal.id')}</p>
              <p className='text-muted'>{`${marriageCertificate?.documentoIdentidadEsposo}`}</p>
            </Col>
          </Row>
        </Col>
        <Col md={12} xl={6}>
          <Row className='mb-3'>
            <Col sm={12} className='mb-3'>
              <h4 className='sub-title text-muted'>{t('common.legal.wifeInformation')}</h4>
            </Col>
            <Col sm={12} md={6} className='mb-3'>
              <p className='f-w-500 mb-0 text-primary'>{t('common.legal.names')}</p>
              <p className='text-muted'>{`${marriageCertificate?.nombresEsposa}`}</p>
            </Col>
            <Col sm={12} md={6} className='mb-3'>
              <p className='f-w-500 mb-0 text-primary'>{t('common.legal.surnames')}</p>
              <p className='text-muted'>{`${marriageCertificate?.apellidosEsposa}`}</p>
            </Col>
            <Col sm={12} md={6} className='mb-3'>
              <p className='f-w-500 mb-0 text-primary'>{t('common.legal.birthdate')}</p>
              <p className='text-muted'>{`${marriageCertificate?.fechaNacimientoEsposa}`}</p>
            </Col>
            <Col sm={12} md={6} className='mb-3'>
              <p className='f-w-500 mb-0 text-primary'>{t('common.legal.id')}</p>
              <p className='text-muted'>{`${marriageCertificate?.documentoIdentidadEsposa}`}</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12} xl={6}>
          <Row className='mb-3'>
            <Col sm={12} className='mb-3'>
              <h4 className='sub-title text-muted'>{t('common.legal.marriageInformation')}</h4>
            </Col>
            <Col sm={12} md={6} className='mb-3'>
              <p className='f-w-500 mb-0 text-primary'>{t('common.legal.marriageDate')}</p>
              <p className='text-muted'>{`${marriageCertificate?.fechaMatrimonio}`}</p>
            </Col>
            <Col sm={12} md={6} className='mb-3'>
              <p className='f-w-500 mb-0 text-primary'>{t('common.legal.country')}</p>
              <p className='text-muted'>{`${marriageCertificate?.objPais.label}`}</p>
            </Col>
            <Col sm={12} md={6} className='mb-3'>
              <p className='f-w-500 mb-0 text-primary'>{t('common.legal.state')}</p>
              <p className='text-muted'>{`${marriageCertificate?.objDepartamento.label}`}</p>
            </Col>
            <Col sm={12} md={6} className='mb-3'>
              <p className='f-w-500 mb-0 text-primary'>{t('common.legal.city')}</p>
              <p className='text-muted'>{`${marriageCertificate?.objCiudad.label}`}</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <p className='text-muted'>
            <span className='ri-information-fill mr-2'></span>
            {t('common.legal.summary.help')}
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm='12' className='mt-3 d-flex justify-content-end'>
          <Button
            color='light'
            onClick={() => props.previousStep()}
            className='btn-pill d-flex align-items-center mr-2'
          >
            <span className='ri-arrow-left-line mr-2'></span> {t('common.formControls.previous')}
          </Button>
          <Button
            color='primary'
            onClick={() => props.nextStep()}
            className='btn-pill d-flex align-items-center'
          >
            {t('common.formControls.next')} <span className='ri-arrow-right-line ml-2'></span>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Summary;
