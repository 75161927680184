import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Nav, NavItem, TabContent, TabPane, NavLink } from 'reactstrap';
import { PersonalInformation, Addresses } from './components';
import styles from './account.module.scss';

const Account = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('1');

  return (
    <Card className='p-4'>
      <Nav tabs className={`border-tab justify-content-center ${styles.navTabs}`}>
        <div className={styles.moduleNameContainer}>
          <div className={styles.moduleName}>
            <span className='ri-user-line ri-xl'></span>
            <h5>{t('modules.settings.account.title')}</h5>
          </div>
        </div>
        <NavItem>
          <NavLink
            href='#javascript'
            className={activeTab === '1' ? 'active' : ''}
            onClick={() => setActiveTab('1')}
          >
            {t('modules.settings.account.personalInformation')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            href='#javascript'
            className={activeTab === '2' ? 'active' : ''}
            onClick={() => setActiveTab('2')}
          >
            {t('modules.settings.account.addresses')}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane className='fade show' tabId='1'>
          <PersonalInformation />
        </TabPane>
        <TabPane tabId='2'>
          <Addresses />
        </TabPane>
      </TabContent>
    </Card>
  );
};

export default Account;
