const kinshipTypes = Object.freeze([
  {
    id: 1,
    value: 'Esposo/a',
    label: 'Esposo/a'
  },
  {
    id: 2,
    value: 'Hijo/a',
    label: 'Hijo/a'
  },
  {
    id: 3,
    value: 'Hermano/a',
    label: 'Hermano/a'
  },
  {
    id: 4,
    value: 'Padre/a',
    label: 'Padre/a'
  },
  {
    id: 5,
    value: 'Madre/a',
    label: 'Madre/a'
  }
]);

export default kinshipTypes;
