import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Alert,
} from 'reactstrap';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Loader } from 'react-feather';
import { useForm } from 'react-hook-form';
import { validatePasswordResetToken, updatePassword } from 'services';
import { TextField } from 'modules/common/components';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const ResetPassword = (props) => {
  const { t } = useTranslation();
  const [togglePassword, setTogglePassword] = useState(false);
  const [isValidToken, setIsValidToken] = useState(false);
  const [isValidatingToken, setIsValidatingToken] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  let query = useQuery();
  const history = useHistory();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();

  const password = watch('password', '');

  useEffect(() => {
    validateToken();
    // eslint-disable-next-line
  }, []);

  const validateToken = async () => {
    setIsValidatingToken(true);
    validatePasswordResetToken(query.get('token'))
      .then(({ data: { info } }) => {
        setIsValidToken(true);
      })
      .catch((error) => {
        setIsValidToken(false);
        console.error('error trying to validate password reset token ', error);
      })
      .finally(() => {
        setIsValidatingToken(false);
      });
  };

  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword);
  };

  const onSubmit = async (form) => {
    setIsSubmitting(true);
    updatePassword(form.password, query.get('token'))
      .then(({ data: { info } }) => {
        reset({});
        toast.success(
          t('modules.auth.resetPassword.passwordUpdatedSuccessfully')
        );
        setTimeout(() => {
          history.push('/auth/login');
        }, 2000);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.error('error trying to recover password ', error);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col xs='12'>
            <div className='login-card'>
              {isValidatingToken ? (
                <div className='w-100 d-flex align-items-center justify-content-center m-3'>
                  <Loader size={15} className='fa-spin' />
                </div>
              ) : null}

              {!isValidatingToken && !isValidToken ? (
                <Alert
                  style={{ maxWidth: '768' }}
                  className='alert-dismissible bg-white'
                  color='danger inverse'
                  target='Alert-1'
                >
                  <i className='fa fa-close'></i>
                  <small className='d-block pr-2'>
                    <Trans
                      i18nKey='modules.auth.resetPassword.alert'
                      components={{ Link: <Link to='/auth/forgot-password' /> }}
                    />
                  </small>
                </Alert>
              ) : null}

              {isValidToken ? (
                <div>
                  <div>
                    <a className='logo' href='#javascript'>
                      <img
                        className='img-fluid for-light'
                        src={require('assets/images/logo/logo.png')}
                        alt='tkiero'
                      />
                    </a>
                  </div>
                  <div className='login-main'>
                    <Form
                      className='theme-form'
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <h4 className='mb-3'>
                        {t('modules.auth.resetPassword.title')}
                      </h4>
                      <FormGroup>
                        <TextField
                          id='password'
                          name='password'
                          type={togglePassword ? 'text' : 'password'}
                          className='form-control'
                          rules={{
                            required: t('common.formValidations.required'),
                            minLength: {
                              value: 5,
                              message: t('common.formValidations.minLength', {
                                length: 5,
                              }),
                            },
                          }}
                          label={t('modules.auth.resetPassword.newPassword')}
                          control={control}
                          errors={errors}
                          placeholder='*****'
                        />
                        <div
                          className='show-hide'
                          onClick={() => HideShowPassword(togglePassword)}
                        >
                          <span className={togglePassword ? '' : 'show'}></span>
                        </div>
                      </FormGroup>
                      <FormGroup className='mb-3'>
                        <TextField
                          id='repeat_password'
                          name='repeat_password'
                          type={togglePassword ? 'text' : 'password'}
                          className='form-control'
                          rules={{
                            required: t('common.formValidations.required'),
                            validate: (value) =>
                              value === password ||
                              t('common.formValidations.passwordDoNotMatch'),
                          }}
                          label={t('modules.auth.resetPassword.repeatPassword')}
                          control={control}
                          errors={errors}
                          placeholder='*****'
                        />
                      </FormGroup>
                      <FormGroup className='mb-0'>
                        <Button
                          color='primary'
                          className='btn-block'
                          type='submit'
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <Loader size={15} className='fa-spin' />
                          ) : (
                            t('modules.auth.resetPassword.title')
                          )}
                        </Button>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer position='top-right' />
    </>
  );
};

export default ResetPassword;
