import { axiosInstance, BASE_URL } from 'configurations/axios';
const path = BASE_URL.concat('/clientes/carrito-compras');

const addToCart = async (data = {}) => {
  return await axiosInstance.post(path, data);
};

const getCart = async (modulo = 0) => {
  const response = await axiosInstance.get(`${path}/modulo/${modulo}`);
  const {
    data: { info },
  } = response;

  let count = 0;
  count = info?.data?.reduce((sum, { cantidad }) => sum + Number(cantidad), 0);

  const data = info?.data;

  return { count, data };
};

const deleteProduct = async (productId) => {
  return await axiosInstance.delete(`${path}/${productId}`);
};

export { addToCart, getCart, deleteProduct };
