import create from "zustand";
import { devtools, persist } from "zustand/middleware";

const usePaymentStore = create(
  devtools(
    persist(
      (set, get) => ({
        module: null,
        category: null,
        provider: null,
        service: null,
        setModule: (module) =>
          set((state) => ({
            module,
          })),
        setCategory: (category) =>
          set((state) => ({
            category,
          })),
        setProvider: (provider) =>
          set((state) => ({
            provider,
          })),
        setService: (service) =>
          set((state) => ({
            service,
          })),
      }),
      {
        name: "tkiero:payment_storage",
        getStorage: () => localStorage,
      }
    )
  )
);

export { usePaymentStore };
