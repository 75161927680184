import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import {
  authentication,
  FacebookAuthProvider,
  signInWithPopup,
  fetchSignInMethodsForEmail,
  supportedPopupSignInMethods,
  getProvider,
  linkWithCredential,
  signOut
} from 'configurations/firebase';
import { loginWithFirebase } from 'services';
import { useAuthStore } from 'store/authStore';

const SignInFacebook = () => {
  const { t } = useTranslation();
  const provider = new FacebookAuthProvider();
  const history = useHistory();
  const setUserInformation = useAuthStore((s) => s.setUserInformation);

  const signIn = async () => {
    signInWithPopup(authentication, provider)
      .then(({ user }) => {
        const request = {
          correo_electronico: user.email,
          nombre: user.displayName,
          uid: user.uid
        };

        loginWithFirebase(request)
          .then(({ data: { info } }) => {
            if (info.data === 'Ok') {
              toast.success(t('modules.auth.common.messages.registerWithSocialMedia'));
            } else {
              if (info.data.token && info.data.token2) {
                setUserInformation(info.data);
                history.push('/app/legal/dashboard');
              } else {
                setUserInformation(info.data);
                history.push('/auth/verification-code');
              }
            }
          })
          .catch((error) => {
            signOut(authentication);
            if (error.response.status === 401) {
              toast.error(t('modules.auth.login.invalidCredentials'));
            } else {
              toast.error(error.response.data.error);
            }
            console.error('Error signin with facebook', error.response);
          });
      })
      .catch((error) => {
        const customData = error.customData;
        const credential = FacebookAuthProvider.credentialFromError(error);
        if (customData.email && error.code === 'auth/account-exists-with-different-credential') {
          fetchSignInMethodsForEmail(authentication, customData.email)
            .then((providers) => {
              const firstPopupProvider = providers.find((p) =>
                supportedPopupSignInMethods.includes(p)
              );

              if (!firstPopupProvider) {
                throw new Error(`Your account is linked to a provider that isn't supported.`);
              }

              const linkedProvider = getProvider(firstPopupProvider);
              linkedProvider.setCustomParameters({
                login_hint: customData.email
              });

              signInWithPopup(authentication, linkedProvider).then((result) => {
                linkWithCredential(result.user, credential);
              });
            })
            .catch((err) => {
              console.error('error getting providers: ', err);
            });
        }
      });
  };

  return (
    <>
      <Button
        color=''
        style={{
          borderRadius: '30px',
          padding: '0',
          background: 'dimgray',
          color: 'white',
          height: '30px',
          width: '30px'
        }}
        onClick={signIn}
      >
        <i className='icofont icofont-social-facebook'></i>
      </Button>
    </>
  );
};

export default SignInFacebook;
