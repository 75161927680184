import React, { useEffect, useState } from 'react';
import { DropDown, PhoneField, Placeholder, TextField } from 'modules/common/components';
import { getCities, getCountries, getStates } from 'services';
import { CustomButton } from 'modules/common/components/CustomButton';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import style from './SenderForm.module.scss';
import { Link } from 'react-router-dom';
import useTransfer from 'store/transfer';
import idCardTypes from 'utils/mocks/idCardTypes';
import kinshipTypes from 'utils/mocks/kinshipTypes';
import { dateFormat } from 'utils/date';
import { useAuthStore } from 'store/authStore';
import { useCallback } from 'react';

const SenderForm = (props) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const setSenderInformation = useTransfer((state) => state.setSenderInformation);
  const userInformation = useAuthStore((state) => state.userInformation);
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm();

  const getStatesByCountry = useCallback((idCountry) => {
    getStates(idCountry)
      .then(({ data: { info } }) => {
        const data = info?.data?.map((state) => ({
          label: state.nombre,
          value: state.id
        }));
        setStates(data);
      })
      .catch((error) => console.error('Error getting states. ', error));
  }, []);

  const getCitiesByState = (idState) => {
    getCities(idState)
      .then(({ data: { info } }) => {
        const data = info?.data?.map((city) => ({
          label: city.nombre,
          value: city.id
        }));
        setCities(data);
      })
      .catch((error) => console.error('Error getting cities. ', error));
  };

  const getCountryInfo = useCallback(
    (idCountry = 0) => {
      if (!idCountry) {
        setStates([]);
        setSelectedCountry(null);
        return;
      }
      const country = countries.find((c) => Number(c.value) === Number(idCountry));
      if (country) setSelectedCountry(country);
    },
    [countries]
  );

  const onSubmit = (items) => {
    const selectedCountry = countries.find((country) => country.value === Number(items.pais));
    const data = {
      ...items,
      isoCode: selectedCountry.isoCode,
      expiracion_documento_identidad: dateFormat(items.expiracion_documento_identidad, 'YYYY-MM-DD')
    };
    setSenderInformation(data);
    props.nextStep();
  };

  useEffect(() => {
    getCountries()
      .then(({ data: { info } }) => {
        const data = info?.data?.map((country) => ({
          label: country.nombre_esp,
          value: country.id,
          isoCode: country.codigo_iso
        }));

        setCountries(data);
      })
      .catch((error) => console.error('Error getting express point countries', error));
  }, []);

  useEffect(() => {
    console.log(userInformation);
    setValue('nombres', userInformation.nombres);
    setValue('apellidos', userInformation.apellidos);
    setValue('correo_electronico', userInformation.correo_electronico);
    setValue('pais', userInformation.pais_id);
    getStatesByCountry(userInformation.pais_id);
    getCountryInfo(userInformation.pais_id);
  }, [userInformation, setValue, countries, getStatesByCountry, getCountryInfo]);

  return (
    <>
      <h5>{t('common.payments.senderTitle')}</h5>
      <Form className={`mt-4 ${style.formContainer}`} onSubmit={handleSubmit(onSubmit)}>
        <Row className='form custom-row'>
          <Col sm='12' md='12' className='mb-3'>
            <TextField
              id='nombres'
              name='nombres'
              disabled
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.payments.firstname')}
              control={control}
              errors={errors}
            />
          </Col>
          <Col sm='12' md='12' className='mb-3'>
            <TextField
              id='apellidos'
              name='apellidos'
              disabled
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.payments.firstSurname')}
              control={control}
              errors={errors}
            />
          </Col>
          <Col sm='6' md='6' className='mb-3'>
            <TextField
              id='codigo_postal'
              name='codigo_postal'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.payments.postalCode')}
              control={control}
            />
          </Col>
          <Col sm='6' md='6' className='mb-3'>
            <DropDown
              id='pais'
              name='pais'
              dataSource={countries}
              className='form-control'
              rules={{
                required: t('common.formValidations.required')
              }}
              disabled
              label={t('modules.auth.register.country')}
              control={control}
              errors={errors}
            />
          </Col>
          <Col sm='6' md='6' className='mb-3'>
            <DropDown
              id='estado'
              name='estado'
              dataSource={states}
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.state')}
              control={control}
              errors={errors}
              handleChange={(event) => getCitiesByState(event.target.value)}
            />
          </Col>
          <Col sm='6' md='6' className='mb-3'>
            <DropDown
              id='ciudad'
              name='ciudad'
              dataSource={cities}
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.city')}
              control={control}
              errors={errors}
            />
          </Col>
          <Col sm='6' md='6' className='mb-3'>
            <PhoneField
              id='telefono'
              name='telefono'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.addresses.form.phoneNumber')}
              defaultCountry={selectedCountry?.isoCode}
              disabled={!selectedCountry?.isoCode}
              control={control}
              errors={errors}
            />
          </Col>
          <Col sm='12' md='12' className='mb-3'>
            <TextField
              id='direccion'
              name='direccion'
              className='form-control'
              label={t('common.payments.address')}
              control={control}
            />
          </Col>
          <Row className='personal-identification mb-3'>
            <Col sm='4' md='4' className='identification-selector'>
              <DropDown
                id='tipo_documento_identidad'
                name='tipo_documento_identidad'
                rules={{ required: t('common.formValidations.required') }}
                className='form-control'
                label={t('common.payments.personalIdentification')}
                control={control}
                errors={errors}
                dataSource={idCardTypes}
              />
            </Col>
            <Col sm='4' md='5' className='identification'>
              <TextField
                id='documento_identidad'
                name='documento_identidad'
                rules={{ required: t('common.formValidations.required') }}
                className='form-control'
                placeholder='Document'
                control={control}
              />
            </Col>
            <Col sm='4' md='3' className='identification-expire'>
              <TextField
                id='expiracion_documento_identidad'
                name='expiracion_documento_identidad'
                rules={{ required: t('common.formValidations.required') }}
                placeholder='Expire date'
                className='form-control'
                type='date'
                control={control}
              />
            </Col>
          </Row>
          <Col sm='6' md='6'>
            <DropDown
              id='parentesco'
              name='parentesco'
              className='form-control'
              label={t('common.payments.kinship')}
              rules={{ required: t('common.formValidations.required') }}
              control={control}
              dataSource={kinshipTypes}
            />
          </Col>
          <Col sm='6' md='6'>
            <TextField
              id='concepto'
              name='concepto'
              className='form-control'
              label={t('common.payments.concept')}
              control={control}
            />
          </Col>
        </Row>
        <Row className='description custom-row'>
          <Col sm='12 mb-3'>
            <div className='example-card'>
              <div className='user'>
                <i className='ri-user-fill'></i>
              </div>
              <div>
                <Placeholder />
                <Placeholder />
                <Placeholder />
                <Placeholder className='last-placeholder' />
              </div>
            </div>
            <div className='description__text'>
              <span>
                El nombre del destinatario tiene que ser igual a su identificacion para recibir el
                dienro.{' '}
              </span>
              Por ejemplo, si tiene nombre de solteria segun DUI, tiene que registrarlo de igual
              forma.
            </div>
          </Col>
          <Col className='description__advice' sm='12 mb-3'>
            <i className='ri-question-fill' />
            <span>
              Completando el formulario notificaremos al destinatario quien envia el dinero
            </span>
          </Col>
          <Col sm='12' className='description__action'>
            <Link to='/app/payments/dashboard'>
              <CustomButton color='primary' outline className={style.favoriteButton}>
                <i className='fa fa-arrow-left' />
                {t('common.formControls.back')}
              </CustomButton>
            </Link>
            <CustomButton
              color='primary'
              className='primary'
              type='submit'
              icon='ri-send-plane-fill'
            >
              {t('common.formControls.next')}
            </CustomButton>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default SenderForm;
