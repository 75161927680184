import { axiosInstance, BASE_URL } from 'configurations/axios';

const path = BASE_URL.concat('/tipos-tramites');

const getProcedures = () => {
  return axiosInstance.get(path);
};

const getProcedure = (id) => {
  return axiosInstance.get(path.concat('/', id));
};

export { getProcedures, getProcedure };
