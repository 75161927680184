import React, { Fragment } from 'react';
import Loader from 'layout/loader';
import Header from 'layout/header';
import Sidebar from 'layout/sidebar';
import { ToastContainer } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';
import { PersonalInformationModal } from './components/PersonalInformationModal';

const App = ({ children }) => {
  console.warn = () => {};

  const userInformation = useAuthStore((s) => s.userInformation);
  const isUserInfoIncompleted =
    !userInformation?.nombres ||
    !userInformation?.apellidos ||
    !userInformation?.fecha_nacimiento ||
    !userInformation?.telefono ||
    !userInformation?.pais ||
    !userInformation?.sexo;

  return (
    <Fragment>
      <Loader />
      <div className='page-wrapper compact-wrapper ' id='pageWrapper'>
        <Sidebar />
        <div className='page-body-wrapper sidebar-icon'>
          <Header />
          <div className='page-body'>{children}</div>
        </div>
      </div>
      <ToastContainer />
      {isUserInfoIncompleted && <PersonalInformationModal />}
    </Fragment>
  );
};

export default withRouter(App);
