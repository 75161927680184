import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';
import { useLegalStore } from 'store/legalStore';

const Summary = (props) => {
  const { t } = useTranslation();
  const birthCertificate = useLegalStore((state) => state.birthCertificate);

  return (
    <>
      <h5 className='mb-5'>{t('common.legal.summary.title')}</h5>
      <Row>
        <Col sm={12} className='mb-3'>
          <h4 className='sub-title text-muted'>{t('common.legal.generalInformation')}</h4>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={4} xl={3} className='mb-3'>
          <p className='f-w-500 mb-0 text-primary'>{t('common.legal.names')}</p>
          <p className='text-muted'>{`${birthCertificate?.primerNombre} ${birthCertificate?.segundoNombre} ${birthCertificate?.tercerNombre}`}</p>
        </Col>
        <Col md={6} lg={4} xl={3} className='mb-3'>
          <p className='f-w-500 mb-0 text-primary'>{t('common.legal.surnames')}</p>
          <p className='text-muted'>{`${birthCertificate?.primerApellido} ${birthCertificate?.segundoApellido} ${birthCertificate?.tercerApellido}`}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={4} xl={3} className='mb-3'>
          <p className='f-w-500 mb-0 text-primary'>{t('common.legal.knownAs')}</p>
          <p className='text-muted'>{birthCertificate?.conocidoComo}</p>
        </Col>
        <Col md={6} lg={4} xl={3} className='mb-3'>
          <p className='f-w-500 mb-0 text-primary'>{t('common.legal.id')}</p>
          <p className='text-muted'>{birthCertificate?.documentoIdentidad}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={4} xl={3} className='mb-3'>
          <p className='f-w-500 mb-0 text-primary'>{t('common.legal.birthdate')}</p>
          <p className='text-muted'>{birthCertificate?.fechaNacimiento}</p>
        </Col>
        <Col md={6} lg={4} xl={3} className='mb-3'>
          <p className='f-w-500 mb-0 text-primary'>{t('common.legal.country')}</p>
          <p className='text-muted'>{birthCertificate?.objPais.label}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={4} xl={3} className='mb-3'>
          <p className='f-w-500 mb-0 text-primary'>{t('common.legal.state')}</p>
          <p className='text-muted'>{birthCertificate?.objDepartamento.label}</p>
        </Col>
        <Col md={6} lg={4} xl={3} className='mb-3'>
          <p className='f-w-500 mb-0 text-primary'>{t('common.legal.city')}</p>
          <p className='text-muted'>{birthCertificate?.objCiudad.label}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={4} xl={3} className='mb-3'>
          <p className='f-w-500 mb-0 text-primary'>{t('common.legal.mothersName')}</p>
          <p className='text-muted'>{birthCertificate?.nombreDeMadre}</p>
        </Col>
        {!birthCertificate?.noAplica && (
          <Col md={6} lg={4} xl={3} className='mb-3'>
            <p className='f-w-500 mb-0 text-primary'>{t('common.legal.fathersName')}</p>
            <p className='text-muted'>{birthCertificate?.nombreDePadre}</p>
          </Col>
        )}
      </Row>
      <Row className='mb-3'>
        <Col>
          <p className='text-muted info-alert'>
            <span className='ri-information-fill mr-2'></span>
            {t('common.legal.summary.help')}
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm='12' className='mt-3 d-flex justify-content-end'>
          <Button
            color='light'
            onClick={() => props.previousStep()}
            className='btn-pill d-flex align-items-center mr-2'
          >
            <span className='ri-arrow-left-line mr-2'></span> {t('common.formControls.previous')}
          </Button>
          <Button
            color='primary'
            onClick={() => props.nextStep()}
            className='btn-pill d-flex align-items-center'
          >
            {t('common.formControls.next')} <span className='ri-arrow-right-line ml-2'></span>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Summary;
