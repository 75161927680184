import React, { useCallback } from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';

const PaypalCheckoutButton = ({
  disabled = false,
  amount,
  description = '',
  onApprovePayment = (order = {}) => {},
  onErrorPayment = (error = {}, paymentMethod) => {}
}) => {
  const createOrder = useCallback(
    (data, actions) => {
      return actions.order
        .create({
          purchase_units: [
            {
              description,
              amount: {
                currency_code: 'USD',
                value: amount
              }
            }
          ]
        })
        .then((orderID) => {
          return orderID;
        });
    },
    [amount]
  );

  return (
    <PayPalButtons
      style={{
        color: 'blue',
        layout: 'horizontal',
        height: 35,
        tagline: true,
        shape: 'pill'
      }}
      disabled={disabled}
      createOrder={createOrder}
      forceReRender={[createOrder]}
      onApprove={async (data, actions) => {
        const order = await actions.order.capture();
        onApprovePayment(order);
      }}
      onError={(error) => {
        onErrorPayment(error, 'paypal');
      }}
    />
  );
};

export default PaypalCheckoutButton;
