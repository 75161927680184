import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import BirthCertificate from './BirthCertificate';
import DeathCertificate from './DeathCertificate';
import DivorceCertificate from './DivorceCertificate';
import RegistrationCertificate from './RegistrationCertificate';
import RegistryInvestigation from './RegistryInvestigation';
import MarriageCertificate from './MarriageCertificate';

const ServiceDetailsModal = ({
  isOpen = false,
  service = {},
  handleClose = () => {},
}) => {
  const services = [
    { key: 'birth-certificate', component: BirthCertificate },
    { key: 'death-certificate', component: DeathCertificate },
    { key: 'divorce-certificate', component: DivorceCertificate },
    { key: 'registration-certificate', component: RegistrationCertificate },
    { key: 'registry-investigation', component: RegistryInvestigation },
    { key: 'marriage-certificate', component: MarriageCertificate },
  ];

  const RenderServiceDetails = () => {
    const ServiceDetails = services.find((s) =>
      service.servicio.url.includes(s.key)
    ).component;
    return <ServiceDetails service={service} />;
  };

  return (
    <>
      <Modal isOpen={isOpen} size='xl'>
        <ModalHeader toggle={handleClose}>
          Detalle de {service.servicio.nombre_esp}
        </ModalHeader>
        <ModalBody>
          <RenderServiceDetails />
        </ModalBody>
        <ModalFooter>
          <Button color='light' onClick={() => handleClose()}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ServiceDetailsModal;
