// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  FacebookAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
  linkWithCredential,
  fetchSignInMethodsForEmail,
} from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_GFB_KEY,
  authDomain: process.env.REACT_APP_GFB_AUTH,
  projectId: process.env.REACT_APP_GFB_PROJECT,
  storageBucket: process.env.REACT_APP_GFB_STORAGE,
  messagingSenderId: process.env.REACT_APP_GFB_MESSAGING,
  appId: process.env.REACT_APP_GFB_APP,
  measurementId: process.env.REACT_APP_GFB_MEASUREMENT,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const authentication = getAuth(app);

// Supported popup sign in methods
const supportedPopupSignInMethods = [
  'facebook.com',
  'google.com',
  'twitter.com',
];

const getProvider = (providerId) => {
  switch (providerId) {
    case 'facebook.com':
      return FacebookAuthProvider;
    case 'google.com':
      return GoogleAuthProvider;
    case 'twitter.com':
      return TwitterAuthProvider;
    default:
      throw new Error(`No provider implemented for ${providerId}`);
  }
};

const isLoggedInByFirebase = async () => {
  try {
    await new Promise((resolve, reject) =>
      onAuthStateChanged(
        authentication,
        (user) => {
          if (user) {
            // User is signed in.
            resolve(user);
          } else {
            // No user is signed in.
            reject('no user logged in');
          }
        },
        // Prevent console error
        (error) => reject(error)
      )
    );
    return true;
  } catch (error) {
    return false;
  }
};

export {
  authentication,
  signOut,
  onAuthStateChanged,
  FacebookAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  supportedPopupSignInMethods,
  signInWithPopup,
  linkWithCredential,
  fetchSignInMethodsForEmail,
  getProvider,
  isLoggedInByFirebase,
};
