import { axiosInstance, BASE_URL } from 'configurations/axios';
import * as api from './abstract/api';

export const getExpressPointModulesByCountry = (countryID = 0) => {
  return axiosInstance.get(`${BASE_URL}/punto-express/modulos/pais/${countryID}`);
};

export const getExpressPointCategoriesByModule = (moduleID = 0) => {
  return axiosInstance.get(`${BASE_URL}/punto-express/categorias/${moduleID}`);
};

export const getExpressPointProvidersByCategory = (categoryID = 0) => {
  return axiosInstance.get(`${BASE_URL}/punto-express/proveedores/${categoryID}`);
};

export const getExpressPointServicesByProvider = (providerID = 0) => {
  return axiosInstance.get(`${BASE_URL}/punto-express/servicios/${providerID}`);
};

export const getExpressPointFormFieldsByService = (serviceID = 0) => {
  return axiosInstance.get(`${BASE_URL}/punto-express/formulario/servicio/${serviceID}`);
};

export const generateAutomaticCapture = (data = { captura: 0 }) => {
  return axiosInstance.post(`${BASE_URL}/punto-express/captura-automatica`, data);
};

/** Transfers Endpoint */
export const getTransfersRecord = () => {
  return api.get('punto-express/transferencias/historial');
};

export const getFavoritesTransfers = () => {
  return api.get('punto-express/transferencias/favoritos');
};

export const postTransfers = (data) => {
  return api.post('punto-express/transferencias', data);
};

export const processExpressPointPayment = (
  data = { servicio: 0, formaPago: 0, autorizacionPago: {}, formulario: {} }
) => {
  return axiosInstance.post(`${BASE_URL}/punto-express/procesar-pago`, data);
};
