import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { usePaymentStore } from "store/paymentStore";
import { getExpressPointFormFieldsByService } from "services";
import { EmptyPage, Loader } from "modules/common/components";

const ExpressPointForm = (props) => {
  const service = usePaymentStore((state) => state.service);
  const [formFields, setFormFields] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const fetchFormFields = () => {
      getExpressPointFormFieldsByService(service?.codigo)
        .then(({ data: { info } }) => {
          setFormFields(info.data ?? []);
        })
        .catch((error) => console.error("Error getting form fields by service"))
        .finally(() => setIsFetching(false));
    };
    fetchFormFields();
  }, [service.codigo]);

  return (
    <Container fluid>
      <h3 className="mb-4 text-center">Formulario</h3>
      {isFetching ? (
        <Loader />
      ) : formFields.length ? (
        <>
          <Row>
            <Col>form fields here!</Col>
          </Row>
          <Row>
            <Col sm="12" className="mt-3 d-flex justify-content-between">
              <Button color="primary" onClick={() => props.previousStep()}>
                &laquo; Regresar
              </Button>
              <Button color="primary" disabled={false}>
                Consultar
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <EmptyPage
            icon="Search"
            title="No se encontraron datos"
            description={`No se encontraron opciones disponibles para ${service.nombre}`}
          />
          <Row>
            <Col sm="12" className="mt-3 d-flex justify-content-between">
              <Button color="primary" onClick={() => props.previousStep()}>
                &laquo; Regresar
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ExpressPointForm;
