import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { evaluate } from 'mathjs';
import { toast } from 'react-toastify';
import { Container, Row, Col, Card, CardBody, Button, Collapse, CardHeader } from 'reactstrap';
import {
  getAddresses,
  getShippingMethodByCountryID,
  getCart,
  createRequest,
  getPaymentMethods
} from 'services';
import {
  Address,
  CreateAddressModal,
  PaypalCheckoutButton,
  StripeCheckoutForm
} from './components';
import Breadcrumb from 'layout/breadcrumb';
import { Loader } from 'modules/common/components';
import { ShoppingCart as ShoppingCartIcon } from 'react-feather';

const Checkout = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [cart, setCart] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [isFetchingAddresses, setIsFetchingAddresses] = useState(false);
  const [isFetchingShippingMethods, setIsFetchingShippingMethods] = useState(false);
  const [isFetchingCart, setIsFetchingCart] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedShippingMethod, setSelectedShippingMethod] = useState(null);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [taxes, setTaxes] = useState(0);

  const [transactionFee, setTransactionFee] = useState(0);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [stripeInfo, setStripeInfo] = useState(null);
  const [paypalInfo, setPaypalInfo] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  let sumTotalPrice = 0.0;

  useEffect(() => {
    fetchAddresses();
    getShoppingCart();
    fetchPaymentMethods();
  }, []);

  const fetchAddresses = () => {
    setIsFetchingAddresses(true);
    getAddresses()
      .then(({ data: { info } }) => {
        const preferred = info.data.find((address) => address.predeterminada === 1);
        if (preferred) {
          setSelectedAddress(preferred);
          getShippingMethodByCountry(preferred.departamento.pais.id);
        }
        setAddresses(info.data);
      })
      .catch((error) => console.error('Error getting addresses list. ', error))
      .finally(() => setIsFetchingAddresses(false));
  };

  const getShoppingCart = () => {
    setIsFetchingCart(true);
    const modulo = 1;
    getCart(modulo)
      .then(({ count, data }) => {
        setCart(data);
      })
      .catch((error) => console.error('error getting cart count ', error))
      .finally(() => setIsFetchingCart(false));
  };

  const fetchPaymentMethods = () => {
    getPaymentMethods()
      .then(({ data: { info } }) => {
        const paypal = info.data.find((i) => i.metodo === 'paypal');
        const stripe = info.data.find((i) => i.metodo === 'stripe');
        setPaypalInfo(paypal);
        setStripeInfo(stripe);
        setPaymentMethods(info.data);
      })
      .catch((error) => console.error('error getting payment methods ', error));
  };

  const getShippingMethodByCountry = (id) => {
    setIsFetchingShippingMethods(true);
    getShippingMethodByCountryID(id)
      .then(({ data: { info } }) => {
        setShippingMethods(info.data);
      })
      .catch((error) => console.error('Error getting shipping method. ', error))
      .finally(() => setIsFetchingShippingMethods(false));
  };

  const onSelectAddress = (id) => {
    const address = addresses.find((address) => Number(address.id) === Number(id));
    setSelectedAddress(address);
    getShippingMethodByCountry(address.departamento.pais.id);
    setSelectedShippingMethod(null);
    setTotalPrice(0);
  };

  const onSelectShippingMethod = (id) => {
    const shippingMethod = shippingMethods.find((i) => Number(i.id) === Number(id));
    setSelectedShippingMethod(shippingMethod);

    let shipping = shippingMethod.precio;
    let subTotal = 0;
    let iva = 0;

    cart.forEach((item, index) => {
      if (index === 0) {
        iva = Number(item.data.servicio.iva);
      }
      subTotal += Number(item.precio_total);
    });

    iva = iva / 100;

    const subTotalWithShipping = Number((shipping + subTotal).toFixed(2));
    const calculateTaxes = Number((subTotalWithShipping * iva).toFixed(2));
    const subtotalWithTaxes = Number((subTotalWithShipping + calculateTaxes).toFixed(2));
    let equation = null;
    if (selectedPaymentMethod === 'paypal') {
      equation = paypalInfo.tarifa.replace(/total/g, subtotalWithTaxes);
    } else {
      equation = stripeInfo.tarifa.replace(/total/g, subtotalWithTaxes);
    }

    const calculateTransactionFee = Number(evaluate(equation)).toFixed(2);

    const totalTotal = (
      subTotalWithShipping +
      calculateTaxes +
      Number(calculateTransactionFee)
    ).toFixed(2);

    setSubTotalPrice(subTotalWithShipping);
    setTaxes(calculateTaxes);
    setTransactionFee(calculateTransactionFee);
    setTotalPrice(totalTotal);
  };

  const onApprovePayment = async (order) => {
    setPaymentCompleted(true);
    const paymentMethod = paymentMethods.find((i) => i.metodo === selectedPaymentMethod);
    const request = {
      modulo: 1,
      direccion: selectedAddress?.id,
      tipo_envio: selectedShippingMethod?.id,
      forma_pago: paymentMethod?.id,
      numero_autorizacion: order
    };

    createRequest(request)
      .then(({ data: { info } }) => {
        const numero_solicitud = info.data?.numero_solicitud;
        history.push(`/app/legal/request-details/${numero_solicitud}`);
      })
      .catch((error) => console.error('error creating request ', error));
  };

  const onErrorPayment = async (error, paymentMethod) => {
    if (paymentMethod === 'stripe') {
      toast.error(error.message);
    } else {
      console.error(`[${paymentMethod}] - error on processing the payment: `, error);
    }
  };

  const handlePaymentMethod = ({ target }) => {
    const { value } = target;
    setSelectedPaymentMethod(value);
  };

  return (
    <>
      <Breadcrumb
        module={t('common.legal.ecommerce.title')}
        page={t('common.legal.ecommerce.checkout.page')}
        title={t('common.legal.ecommerce.checkout.page')}
      />

      {!isFetchingCart && cart.length ? (
        <>
          <Container fluid={true}>
            <Row>
              <Col md='6'>
                <Card className='checkout'>
                  <CardBody>
                    <div className='address-wrapper'>
                      <h5 className='mb-4'>{t('common.addresses.selectAddress')}</h5>
                      {isFetchingAddresses ? <Loader /> : null}
                      {!isFetchingAddresses ? (
                        <>
                          <ul
                            id='address-list'
                            className='mega-vertical border-style animate-chk'
                            data-text='Todavía no tienes direcciónes registradas Agrega una dirección de envío para continuar'
                          >
                            {addresses.map((address, index) => (
                              <li key={index} className='w-100'>
                                <Address
                                  address={address}
                                  handleSelect={onSelectAddress}
                                  selectedAddress={selectedAddress?.id}
                                />
                              </li>
                            ))}
                          </ul>
                          <div className='text-right mt-5'>
                            <Button
                              className='btn-subtle'
                              onClick={() => setIsModalOpen(!isModalOpen)}
                            >
                              + {t('common.addresses.add')}
                            </Button>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md='6'>
                <Card className='checkout'>
                  <CardBody>
                    <div>
                      <div className='order-box'>
                        <section id='product_list' className='mb-4'>
                          <h5 className='mb-4'>{t('common.checkout.orderDetails')}</h5>
                          <ul className='order-detail'>
                            {cart.map((item) => {
                              sumTotalPrice += Number(item.precio_total);
                              return (
                                <li key={item.id} className='mb-3'>
                                  <div className='d-flex justify-content-between'>
                                    <div>
                                      <p className='m-0'>{`${item.servicio.nombre_esp} x
                                                ${item.cantidad}`}</p>
                                      <small>{`${item.data.tipoTramite.nombre_esp}`}</small>
                                    </div>
                                    <span>{`$ ${Number(item.precio_total).toFixed(2)}`}</span>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                          <div className='d-flex justify-content-end summary'>
                            <p>
                              {' '}
                              {t('common.checkout.serviceProcedures')}
                              <span className='pl-3'>
                                {' '}
                                {`$ ${Number(sumTotalPrice).toFixed(2)}`}{' '}
                              </span>
                            </p>
                          </div>
                        </section>

                        <section id='shipping_methods' className='mb-4'>
                          <h5 className='mb-4'>{t('common.checkout.shipment')}</h5>
                          <div className='shopping-checkout-option'>
                            {isFetchingShippingMethods ? <Loader /> : null}

                            {!isFetchingShippingMethods && shippingMethods.length === 0 ? (
                              <p className='text-muted info-alert'>
                                {t('common.addresses.selectAddress')}
                              </p>
                            ) : null}

                            {!isFetchingShippingMethods &&
                              shippingMethods.map((item) => (
                                <div key={item.id} className='shipping-card p-20 media'>
                                  <label className='d-block m-0 shipper'>
                                    <input
                                      className='radio_animated'
                                      type='radio'
                                      name='shippingMethod'
                                      value={item.id}
                                      onChange={({ target: { value } }) => {
                                        onSelectShippingMethod(value);
                                      }}
                                    />
                                    {item.empresa_envio.nombre}
                                  </label>
                                  <small className='price'>{`$ ${Number(item.precio).toFixed(
                                    2
                                  )}`}</small>
                                </div>
                              ))}
                          </div>
                        </section>

                        <section id='subtotal_items' className='mb-2'>
                          <div className='d-flex justify-content-end pricing'>
                            <span className='m-0'>Subtotal</span>
                            <span className='pl-3'>{`$ ${subTotalPrice}`}</span>
                          </div>
                        </section>

                        <section id='taxes_items' className='mb-2'>
                          <div className='d-flex justify-content-end pricing'>
                            <span className='m-0'>{t('common.checkout.taxes')}</span>
                            <span className='pl-3'>{`$ ${taxes}`}</span>
                          </div>
                        </section>

                        <section id='transaction_fee_items' className='mb-2'>
                          <div className='d-flex justify-content-end pricing'>
                            <span className='m-0'>{t('common.checkout.transactionFee')}</span>
                            <span className='pl-3'>{`$ ${transactionFee}`}</span>
                          </div>
                        </section>

                        <hr />
                        <section id='total_with_shipping'>
                          <div className='d-flex justify-content-end total-ammount'>
                            <span className='mb-4'>{t('common.checkout.totalPayment')}</span>
                            <span className='pl-3'>{`$ ${totalPrice}`}</span>
                          </div>
                        </section>

                        <section id='payment_methods'>
                          <h5 className='mb-4'>{t('common.checkout.paymentMethod')}</h5>
                          {paypalInfo ? (
                            <Card className='shadow'>
                              <CardHeader className='p-3'>
                                <label htmlFor='payWithPaypal' className='m-0'>
                                  <input
                                    type='radio'
                                    name='paymentMethod'
                                    id='payWithPaypal'
                                    value='paypal'
                                    className='radio_animated'
                                    onChange={handlePaymentMethod}
                                  />
                                  {t('common.checkout.paypalPayment')}
                                </label>
                              </CardHeader>
                              <Collapse isOpen={selectedPaymentMethod === 'paypal'}>
                                <CardBody>
                                  <PaypalCheckoutButton
                                    disabled={
                                      !selectedAddress ||
                                      !selectedShippingMethod ||
                                      totalPrice === 0 ||
                                      paymentCompleted
                                    }
                                    amount={totalPrice}
                                    description={t(
                                      'common.legal.ecommerce.checkout.orderDescription'
                                    )}
                                    onApprovePayment={onApprovePayment}
                                    onErrorPayment={onErrorPayment}
                                  />
                                </CardBody>
                              </Collapse>
                            </Card>
                          ) : null}

                          {stripeInfo ? (
                            <Card className='shadow'>
                              <CardHeader className='p-3'>
                                <label htmlFor='payWithCard' className='m-0'>
                                  <input
                                    type='radio'
                                    name='paymentMethod'
                                    id='payWithCard'
                                    value='stripe'
                                    className='radio_animated'
                                    onChange={handlePaymentMethod}
                                  />
                                  {t('common.checkout.stripePayment')}
                                </label>
                              </CardHeader>
                              <Collapse isOpen={selectedPaymentMethod === 'stripe'}>
                                <CardBody>
                                  <StripeCheckoutForm
                                    disabled={
                                      !selectedAddress ||
                                      !selectedShippingMethod ||
                                      totalPrice === 0 ||
                                      paymentCompleted
                                    }
                                    amount={totalPrice}
                                    onApprovePayment={onApprovePayment}
                                    onErrorPayment={onErrorPayment}
                                  />
                                </CardBody>
                              </Collapse>
                            </Card>
                          ) : null}
                        </section>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {isModalOpen && (
              <CreateAddressModal
                isOpen={isModalOpen}
                handleClose={(isOpen, addressID) => {
                  setIsModalOpen(isOpen);
                  fetchAddresses();
                  setSelectedAddress(addressID);
                }}
              />
            )}
          </Container>
        </>
      ) : null}

      {!isFetchingCart && cart.length === 0 ? (
        <section className='cart-section'>
          <Container fluid={true} className='text-center'>
            <div className='cart-icon'>
              <ShoppingCartIcon size={48} />
            </div>
            <h3>
              <strong>Tu carrito de compras está vacío</strong>
            </h3>
            <p className='font-roboto text-muted'>Explore más, seleccione algunos elementos.</p>
          </Container>
        </section>
      ) : null}
    </>
  );
};

export default Checkout;
