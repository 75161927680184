import axios from 'axios';
import { useAuthStore } from 'store/authStore';
import { refreshToken } from 'services';
import { signOut, authentication } from './firebase';
const hostname = process.env.REACT_APP_API_URL;
const BASE_URL = hostname;


const axiosInstance = axios.create();
const axiosPublicInstance = axios.create();

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = useAuthStore.getState().userInformation?.token2;
    const id = useAuthStore.getState().userInformation?.id;
    config.headers = {
      ...(token && { 'x-access-token': token }),
      ...(id && { 'x-access-user': id }),
      Accept: 'application/json',
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      !originalRequest.url.includes('iniciar-sesion')
    ) {
      originalRequest._retry = true;

      try {
        const { data } = await refreshToken({
          id: useAuthStore.getState().userInformation?.id,
          correo_electronico:
            useAuthStore.getState().userInformation?.correo_electronico,
          token: useAuthStore.getState().userInformation?.token,
        });

        const userInfo = data?.info.data;

        if (userInfo) {
          useAuthStore.getState().setUserInformation(userInfo);
          axiosInstance.defaults.headers['x-access-token'] = userInfo.token2;
        } else {
          useAuthStore.getState().setUserInformation(null);
          delete axiosInstance.defaults.headers['x-access-token'];
        }

        return axiosInstance(originalRequest);
      } catch (error) {
        await signOut(authentication);
        useAuthStore.getState().setUserInformation(null);
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);

export { axiosInstance, axiosPublicInstance, BASE_URL };
