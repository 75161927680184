import React, { useEffect, useState } from 'react';
import { DropDown, PhoneField, TextField } from 'modules/common/components';
import { CustomButton } from 'modules/common/components/CustomButton';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import useTransfer from 'store/transfer';
import isValidEmail from 'utils/regex/isValidEmail';
import { getCities, getStates } from 'services';
import style from './AddresseeForm.module.scss';
import idCardTypes from 'utils/mocks/idCardTypes';
import ConfirmationModal from './components/ConfirmationModal';
import { dateFormat } from 'utils/date';

const AddresseeForm = (props) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm();
  const remitente = useTransfer((state) => state.remitente);
  const setAddresseeInformation = useTransfer((state) => state.setAddresseeInformation);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  const handleEmailValidation = (email) =>
    isValidEmail(email) || t('common.formValidations.invalidEmail');

  const getCitiesByState = (idState) => {
    getCities(idState)
      .then(({ data: { info } }) => {
        const data = info?.data?.map((city) => ({
          label: city.nombre,
          value: city.id
        }));
        setCities(data);
      })
      .catch((error) => console.error('Error getting cities. ', error));
  };

  const onSubmit = (items) => {
    const data = {
      ...items,
      monto: Number(items.monto),
      expiracion_documento_identidad: dateFormat(items.expiracion_documento_identidad, 'YYYY-MM-DD')
    };
    setAddresseeInformation(data);
    setOpenModal(true);
  };

  useEffect(() => {
    if (remitente && remitente.pais) {
      const { pais } = remitente;
      setValue('pais', pais);
      getStates(pais).then(({ data: { info } }) => {
        const data = info?.data?.map((state) => ({
          label: state.nombre,
          value: state.id
        }));
        setStates(data);
      });
    }
  }, [remitente, setValue]);

  return (
    <>
      <h5>{t('common.payments.addresseeTitle')}</h5>
      <Form
        className={`d-flex flex-row justify-content-between align-items-start ${style.formContainer}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row className='custom-row'>
          <Col sm='12' md='12' className='mb-3'>
            <TextField
              id='nombres'
              name='nombres'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.payments.addresseeForm.name')}
              control={control}
              errors={errors}
            />
          </Col>
          <Col sm='12' md='12' className='mb-3'>
            <TextField
              id='apellidos'
              name='apellidos'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.payments.firstSurname')}
              control={control}
              errors={errors}
            />
          </Col>
          <Row className='personal-identification mb-3'>
            <Col sm='4' md='3' className='identification-selector'>
              <DropDown
                id='tipo_documento_identidad'
                name='tipo_documento_identidad'
                rules={{ required: t('common.formValidations.required') }}
                className='form-control'
                label={t('common.payments.personalIdentification')}
                control={control}
                errors={errors}
                dataSource={idCardTypes}
              />
            </Col>
            <Col sm='4' md='5' className='identification'>
              <TextField
                id='documento_identidad'
                name='documento_identidad'
                rules={{ required: t('common.formValidations.required') }}
                className='form-control '
                placeholder='Document'
                control={control}
                errors={errors}
              />
            </Col>
            <Col sm='4' md='4' className='identification-expire'>
              <TextField
                id='expiracion_documento_identidad'
                name='expiracion_documento_identidad'
                rules={{ required: t('common.formValidations.required') }}
                placeholder='Expire date'
                className='form-control '
                type='date'
                control={control}
                errors={errors}
              />
            </Col>
          </Row>
          <Col sm='12' md='12' className='mb-3'>
            <TextField
              id='direccion'
              name='direccion'
              className='form-control'
              label={t('common.payments.address')}
              control={control}
              errors={errors}
            />
          </Col>
        </Row>
        <Row className='custom-row'>
          <Col sm='12' md='12' className='mb-3'>
            <TextField
              id='monto'
              name='monto'
              className='form-control'
              type='number'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.payments.addresseeForm.quantityToSent')}
              control={control}
              errors={errors}
            />
          </Col>
          <Col sm='6' md='6' className='mb-3'>
            <DropDown
              id='estado'
              name='estado'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.state')}
              control={control}
              errors={errors}
              dataSource={states}
              handleChange={(event) => getCitiesByState(event.target.value)}
            />
          </Col>
          <Col sm='6' md='6' className='mb-3'>
            <DropDown
              id='ciudad'
              name='ciudad'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.legal.city')}
              control={control}
              errors={errors}
              dataSource={cities}
            />
          </Col>
          <Col sm='12' md='12' className='mb-3'>
            <TextField
              id='correo_electronico'
              name='correo_electronico'
              className='form-control'
              rules={{
                required: t('common.formValidations.required'),
                validate: handleEmailValidation
              }}
              label={t('common.payments.addresseeForm.email')}
              control={control}
              errors={errors}
            />
          </Col>
          <Col sm='6' md='6' className='mb-3'>
            <PhoneField
              id='telefono'
              name='telefono'
              className='form-control'
              rules={{ required: t('common.formValidations.required') }}
              label={t('common.addresses.form.phoneNumber')}
              control={control}
              errors={errors}
              defaultCountry={remitente.isoCode}
            />
          </Col>
        </Row>
        <Row className={style.actionContainer}>
          <Col sm='6'>
            <Col className={`${style.advice} mb-3`} sm='12'>
              <i className='ri-question-fill' />
              <span>
                Completa la informacion exacata segun identidad del DUI para recibir el dinero
              </span>
            </Col>
            <Col className={`${style.advice} mb-3`} sm='12'>
              <i className='ri-question-fill' />
              <span>Puedes enviar un maximo de $2,000.00</span>
            </Col>
          </Col>
          <Col sm='6' className='d-flex justify-content-end align-items-end actions'>
            <CustomButton color='primary' outline className={style.favoriteButton} onClick={() => props.prevStep()}>
              <i className='fa fa-arrow-left' />
              {t('common.formControls.back')}
            </CustomButton>
            <CustomButton
              color='primary'
              className='primary'
              type='submit'
              icon='ri-send-plane-fill'
            >
              {t('common.formControls.next')}
            </CustomButton>
          </Col>
        </Row>
      </Form>
      <ConfirmationModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        nextStep={props.nextStep}
      />
    </>
  );
};

export default AddresseeForm;
