import React from 'react';
import { Label } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import 'react-phone-number-input/style.css';
import styles from './PhoneField.module.scss';

/**
 * @param id string
 * @param name string
 * @param rules react-hook-form (rules object)
 * @param defaultValue string
 * @param defaultCountry string
 * @param disabled boolean
 * @param label string
 * @param control react-hook-form (useForm() property)
 * @param errors react-hook-form (useForm() property)
 *  */
const PhoneField = ({
  id,
  name,
  rules = {},
  defaultValue = '',
  defaultCountry = 'US',
  disabled = false,
  label,
  control,
  errors = null,
}) => {
  return (
    <>
      <Label for={id}>{label}</Label>
      <PhoneInputWithCountry
        name={name}
        control={control}
        disabled={disabled}
        rules={{
          ...rules,
          validate: (value) =>
            isValidPhoneNumber(value) || 'Número de teléfono no válido',
        }}
        defaultValue={defaultValue}
        defaultCountry={defaultCountry}
        className={styles.phoneField}
      />

      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          as={<small className='font-danger' />}
        />
      )}
    </>
  );
};

export default PhoneField;
