import React from 'react';
import { Row, Col } from 'reactstrap';

const BirthCertificate = ({ service = {} }) => {
  return (
    <>
      <Row>
        <Col sm={12} className='mb-3'>
          <h6 className='sub-title'>Información General</h6>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>
            Primer nombre / Segundo nombre / Tercer nombre
          </p>
          <p className='text-muted'>{`${service.data.primerNombre} ${service.data.segundoNombre} ${service.data.tercerNombre}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>
            Primer apellido / Segundo apellido / Tercer apellido
          </p>
          <p className='text-muted'>{`${service.data.primerApellido} ${service.data.segundoApellido} ${service.data.tercerApellido}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Conocido como</p>
          <p className='text-muted'>{`${service.data.primerApellido} ${service.data.segundoApellido} ${service.data.tercerApellido}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Documento de identidad</p>
          <p className='text-muted'>{`${service.data.documentoIdentidad}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Fecha de nacimiento</p>
          <p className='text-muted'>{`${service.data.fechaNacimiento}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Pais</p>
          <p className='text-muted'>{`${service.data.objPais.label}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Estado/Departamento</p>
          <p className='text-muted'>{`${service.data.objDepartamento.label}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Ciudad/Municipio</p>
          <p className='text-muted'>{`${service.data.objCiudad.label}`}</p>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <p className='f-w-500 mb-0'>Nombre de la madre</p>
          <p className='text-muted'>{`${service.data.nombreDeMadre}`}</p>
        </Col>
        {!service.data.noAplica && (
          <Col sm={12} md={6} lg={4}>
            <p className='f-w-500 mb-0'>Nombre del padre</p>
            <p className='text-muted'>{`${service.data.nombreDePadre}`}</p>
          </Col>
        )}
      </Row>
      <Row>
        <Col sm={12} className='mb-3'>
          <h6 className='sub-title'>Información de servicio y trámite</h6>
        </Col>
        <Col sm={12}>
          <table className='table table-striped table-bordered'>
            <tbody>
              <tr>
                <th width='250'>Tipo de Servicio</th>
                <td>{service.data.servicio.nombreServicio}</td>
              </tr>
              <tr>
                <th>Precio</th>
                <td>{`$ ${Number(service.data.servicio.precioServicio).toFixed(
                  2
                )}`}</td>
              </tr>
              <tr>
                <th>Tipo de Trámite</th>
                <td>{service.data.tipoTramite.nombre_esp}</td>
              </tr>
              <tr>
                <th>Precio</th>
                <td>{`$ ${Number(service.data.tipoTramite.precio).toFixed(
                  2
                )}`}</td>
              </tr>
              <tr>
                <th>Cantidad</th>
                <td>{service.cantidad}</td>
              </tr>
              <tr>
                <th>Total</th>
                <td>{`$ ${service.precio_total.toFixed(2)}`}</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );
};

export default BirthCertificate;
