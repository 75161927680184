import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

const useTransfer = create(
  devtools(
    persist(
      (set, get) => ({
        remitente: null,
        destinatario: null,
        setSenderInformation: (remitente) =>
          set(() => ({
            remitente
          })),
        setAddresseeInformation: (destinatario) =>
          set(() => ({
            destinatario
          })),
        resetInformation: () =>
          set(() => ({
            remitente: null,
            destinatario: null
          }))
      }),
      {
        name: 'tkiero:transfer',
        getStorage: () => localStorage
      }
    )
  )
);

export default useTransfer;
