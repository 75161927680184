import React, { Fragment } from 'react';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import styles from './breadcrumb.module.scss';

/**
 * @param title string
 * @param parent string
 * @param icon remix icon classname
 * @param titleClassName string
 *  */
const Breadcrumbs = ({ title, icon, module, page, titleClassName }) => {
  return (
    <Fragment>
      <Container fluid className={styles.pageTitle}>
        <Row className='d-flex justify-content-between'>
          <Col>
            <h5 className={`d-flex align-items-center ${titleClassName ?? ''}`}>
              {icon && <span className={`mr-2 ri-lg ${icon}`}></span>}
              {title}
            </h5>
          </Col>
          <Col className='d-none d-md-flex justify-content-end'>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={`${process.env.PUBLIC_URL}/app/legal/dashboard`}>
                  <span className='ri-home-5-line'></span>
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem>{module}</BreadcrumbItem>
              <BreadcrumbItem active>{page}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
