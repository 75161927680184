import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Col, Row } from 'reactstrap';
import { getCountries, getStates, createAddress, updateAddress } from 'services';
import { TextField, DropDown, CheckBox, PhoneField } from 'modules/common/components';

const AddressForm = ({ isOpen = false, setIsOpen = (isOpen = false) => {}, address = null }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (address) {
      getStatesByCountry(address?.departamento?.pais?.id);
      setSelectedCountry({
        label: address?.departamento.pais.nombre_esp,
        value: address?.departamento.pais.id,
        isoCode: address?.departamento.pais.codigo_iso,
      });
    }
  }, [address]);

  useEffect(() => {
    getCountries()
      .then(({ data: { info } }) => {
        const data = info?.data?.map((country) => ({
          label: country.nombre_esp,
          value: country.id,
          isoCode: country.codigo_iso,
        }));
        setCountries(data);
      })
      .catch((error) => console.error('Error getting countries. ', error));
  }, []);

  const getStatesByCountry = (idCountry) => {
    if (!idCountry) {
      setStates([]);
      setSelectedCountry(null);
      return;
    }
    getStates(idCountry)
      .then(({ data: { info } }) => {
        const data = info?.data?.map((state) => ({
          label: state.nombre,
          value: state.id,
        }));
        setStates(data);
      })
      .catch((error) => console.error('Error getting states. ', error));
  };

  const getCountryInfo = (idCountry = 0) => {
    if (!idCountry) {
      setStates([]);
      setSelectedCountry(null);
      return;
    }
    const country = countries.find((c) => Number(c.value) === Number(idCountry));
    if (country) setSelectedCountry(country);
  };

  const onSubmit = (data) => {
    const request = {
      ...data,
      departamento: Number(data.departamento),
      predeterminada: Number(data.predeterminada),
    };

    if (address) {
      updateAddress(request, address.id)
        .then((data) => {
          Swal.fire({
            text: t('common.addresses.alertUpdateAddress.message.updated'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: t('common.formControls.accept'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
          setIsOpen(false);
        })
        .catch((error) => console.error('Error updating address. ', error));
    } else {
      createAddress(request)
        .then((data) => {
          Swal.fire({
            text: t('common.addresses.alertUpdateAddress.message.created'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: t('common.formControls.accept'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
          setIsOpen(false);
        })
        .catch((error) => console.error('Error adding new address. ', error));
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} size='lg'>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            wrapTag={(props) => (
              <div {...props}>
                <div>
                  <h5 className='modal-title'>
                    {t(`common.addresses.form.${address ? 'editAddress' : 'addAddress'}`)}
                  </h5>
                  <small>{t('common.addresses.form.description')}</small>
                </div>
              </div>
            )}
          />
          <ModalBody>
            <Row>
              <Col md='6' className='mb-3'>
                <TextField
                  id='nombre'
                  name='nombre'
                  className='form-control'
                  rules={{ required: t('common.formValidations.required') }}
                  label={t('common.addresses.form.addressName')}
                  control={control}
                  errors={errors}
                  defaultValue={address?.nombre}
                />
              </Col>
              <Col md='6' className='mb-3'>
                <TextField
                  id='destinatario'
                  name='destinatario'
                  className='form-control'
                  rules={{ required: t('common.formValidations.required') }}
                  label={t('common.addresses.form.recipient')}
                  control={control}
                  errors={errors}
                  defaultValue={address?.destinatario}
                />
              </Col>
              <Col md='6' className='mb-3'>
                <DropDown
                  id='pais'
                  name='pais'
                  dataSource={countries}
                  className='form-control'
                  rules={{ required: t('common.formValidations.required') }}
                  label={t('common.addresses.form.country')}
                  control={control}
                  errors={errors}
                  handleChange={(event) => {
                    getStatesByCountry(event.target.value);
                    getCountryInfo(event.target.value);
                  }}
                  defaultValue={address?.departamento?.pais?.id}
                />
              </Col>
              <Col md='6' className='mb-3'>
                <DropDown
                  id='departamento'
                  name='departamento'
                  dataSource={states}
                  className='form-control'
                  rules={{ required: t('common.formValidations.required') }}
                  label={t('common.addresses.form.state')}
                  control={control}
                  errors={errors}
                  defaultValue={address?.departamento?.id}
                />
              </Col>
              <Col md='6' className='mb-3'>
                <TextField
                  id='ciudad'
                  name='ciudad'
                  className='form-control'
                  rules={{ required: t('common.formValidations.required') }}
                  label={t('common.addresses.form.city')}
                  control={control}
                  errors={errors}
                  defaultValue={address?.ciudad}
                />
              </Col>
              <Col md='6' className='mb-3'>
                <TextField
                  id='zipcode'
                  name='zipcode'
                  className='form-control'
                  label={t('common.addresses.form.zipcode')}
                  control={control}
                  errors={errors}
                  defaultValue={address?.zipcode}
                />
              </Col>
              <Col md='12' className='mb-3'>
                <TextField
                  id='direccion'
                  name='direccion'
                  className='form-control'
                  rules={{ required: t('common.formValidations.required') }}
                  label={t('common.addresses.form.address')}
                  control={control}
                  errors={errors}
                  defaultValue={address?.direccion}
                />
              </Col>
              <Col md='12' className='mb-3'>
                <TextField
                  id='direccion2'
                  name='direccion2'
                  className='form-control'
                  label={t('common.addresses.form.secondaryAddress')}
                  control={control}
                  errors={errors}
                  defaultValue={address?.direccion2}
                />
              </Col>
              <Col md='12' className='mb-3'>
                <TextField
                  id='informacion_adicional'
                  name='informacion_adicional'
                  className='form-control'
                  label={t('common.addresses.form.additionalInfo')}
                  control={control}
                  errors={errors}
                  defaultValue={address?.informacion_adicional}
                />
              </Col>
              <Col lg='6' className='mb-3'>
                <PhoneField
                  id='telefono'
                  name='telefono'
                  className='form-control'
                  rules={{ required: t('common.formValidations.required') }}
                  label={t('common.addresses.form.phoneNumber')}
                  control={control}
                  errors={errors}
                  defaultValue={address?.telefono}
                  defaultCountry={selectedCountry?.isoCode}
                  disabled={!selectedCountry?.isoCode}
                />
              </Col>
              <Col lg='6'>
                <div className='d-none d-lg-block'>&nbsp;</div>
                <CheckBox
                  id='predeterminada'
                  name='predeterminada'
                  className='form-control'
                  label={t('common.addresses.form.defaultAddress')}
                  control={control}
                  defaultValue={address?.predeterminada}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button className='btn-pill' color='light' onClick={() => setIsOpen(false)}>
              {t('common.formControls.cancel')}
            </Button>
            <Button className='btn-pill' color='primary' type='submit'>
              {t(`common.addresses.form.${address ? 'saveChanges' : 'addNewAddress'}`)}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default AddressForm;
