import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'layout/breadcrumb';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { getRequestById } from 'services';
import { AlertCircle } from 'react-feather';
import {
  AddressDetails,
  ServiceList,
  PaypalPaymentDetails,
  StripePaymentDetails
} from './components';

const RequestDetails = () => {
  const { t } = useTranslation();
  const { requestId } = useParams();
  const [requestInfo, setRequestInfo] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const fetchRequestByID = async () => {
      getRequestById(requestId)
        .then(({ data: { info } }) => {
          setRequestInfo(info.data);
        })
        .catch((error) => console.error('Error getting order details. ', error))
        .finally(() => setIsFetching(false));
    };

    fetchRequestByID();
  }, [requestId]);

  return (
    <>
      {!isFetching && requestInfo ? (
        <>
          <Breadcrumb
            module={t('modules.legal.title')}
            page={t('modules.legal.requestDetails.page')}
            title={t('modules.legal.requestDetails.title')}
          />
          <Container fluid={true}>
            <Card className='order-details'>
              <CardBody>
                <Row>
                  <Col>
                    <section id='requestInfo'>
                      <h6 className='mb-0'>{t('modules.legal.requestDetails.requestNumber')}</h6>
                      <small>{requestInfo?.numero_solicitud}</small>
                    </section>
                    <section id='services'>
                      <ServiceList services={requestInfo.productos} />
                    </section>
                    <section id='address'>
                      <AddressDetails
                        direccion={requestInfo.direccion[0]}
                        empresa_envio={requestInfo.empresa_envio}
                      />
                    </section>
                    <section id='paymentDetails'>
                      {requestInfo.forma_pago.metodo === 'paypal' ? (
                        <PaypalPaymentDetails autorizacion_pago={requestInfo.autorizacion_pago} />
                      ) : (
                        <StripePaymentDetails autorizacion_pago={requestInfo.autorizacion_pago} />
                      )}
                    </section>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </>
      ) : null}

      {!isFetching && !requestInfo ? (
        <section className='cart-section pt-5'>
          <Container fluid={true} className='text-center'>
            <div className='cart-icon'>
              <AlertCircle size={48} />
            </div>
            <h3>
              <strong>No se encontró ninguna solicitud</strong>
            </h3>
            <p className='font-roboto text-muted '>
              La solicitud a la que intentas acceder ha expirado o no existe.
            </p>
          </Container>
        </section>
      ) : null}
    </>
  );
};

export default RequestDetails;
