import { toast } from 'react-toastify';
export const handleHttpErrors = (error, message = '') => {
  if (error.response.status === 412) {
    toast.error(error.response.data.info.data);
  } else if (error.response.status === 401) {
    toast.error(error.response.message);
  } else if (error.response.status === 404) {
    toast.error(error.response.data);
  } else {
    toast.error(error.response.data.error);
  }
  console.error(`${message} - `, error.response);
};
