import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, CardHeader, Button, CardBody } from 'reactstrap';
import dayjs from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';
import { getRequestList } from 'services';
import { RequestStatus } from './components/RequestStatus';
import { Requests } from './components/Requests';
import { SearchForm } from './components/SearchForm';

const Dashboard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isSearching, setIsSearching] = useState(false);
  const [searchData, setSearchData] = useState({
    modulo: 1,
    fecha_desde: dayjs().subtract(90, 'day').format('YYYY-MM-DD'),
    fecha_hasta: dayjs().add(1, 'day').format('YYYY-MM-DD')
  });

  const [requests, setRequests] = useState({
    contador_pendientes: 0,
    contador_en_proceso: 0,
    contador_finalizadas: 0,
    contador_con_problemas: 0,
    solicitudes: []
  });

  useEffect(() => {
    fetchRequestList(searchData);
  }, [searchData]);

  const fetchRequestList = async (searchData = { fecha_desde: '', fecha_hasta: '' }) => {
    setIsSearching(true);
    getRequestList(searchData)
      .then(({ data: { info } }) => {
        setRequests(info?.data);
      })
      .catch((error) => console.error('Error getting request list. ', error))
      .finally(() => setIsSearching(false));
  };

  const handleInputChange = (event) => {
    const {
      target: { name, value }
    } = event;

    if (name === 'fecha_desde') {
      if (dayjs(value).isBefore(searchData.fecha_hasta)) {
        setSearchData({
          ...searchData,
          [name]: value
        });
      } else {
        toast.error(t('modules.legal.dashboard.searchForm.startDateError'));
        event.target.value = dayjs(searchData.fecha_hasta).subtract(90, 'day').format('YYYY-MM-DD');
        setSearchData({
          ...searchData,
          [name]: dayjs(searchData.fecha_hasta).subtract(90, 'day').format('YYYY-MM-DD')
        });
      }
    }

    if (name === 'fecha_hasta') {
      if (dayjs(value).isAfter(searchData.fecha_desde)) {
        setSearchData({
          ...searchData,
          [name]: value
        });
      } else {
        toast.error(t('modules.legal.dashboard.searchForm.endDateError'));
        event.target.value = dayjs(searchData.fecha_desde).add(91, 'day').format('YYYY-MM-DD');
        setSearchData({
          ...searchData,
          [name]: dayjs(searchData.fecha_desde).add(91, 'day').format('YYYY-MM-DD')
        });
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchRequestList(searchData);
  };

  return (
    <>
      <Container fluid={true} className='pt-5'>
        <Card className='p-5'>
          <CardHeader>
            <Row>
              <Col sm='8'>
                <h5>{t('modules.legal.dashboard.requestList')}</h5>
              </Col>

              <Col sm='4' className='text-right'>
                <Button
                  color='primary'
                  className='btn-pill'
                  onClick={() => history.push('/app/legal/create-application/')}
                >
                  {t('modules.legal.dashboard.newRequest')}
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <RequestStatus requests={requests} />

          <CardBody>
            <SearchForm
              handleInputChange={handleInputChange}
              searchData={searchData}
              isSearching={isSearching}
              handleSubmit={handleSubmit}
            />
            <Requests requests={requests.solicitudes} isSearching={isSearching} />
          </CardBody>
        </Card>
      </Container>
      <ToastContainer position='top-right' />
    </>
  );
};

export default Dashboard;
