import React from 'react';
import style from './ClientCard.module.scss';
import PropTypes from 'prop-types';

/**
 * @param record {
    id: PropTypes.number,
    name: PropTypes.string,
    date: PropTypes.string,
    amount: PropTypes.number,
    state: PropTypes.string
  }
*/
const ClientCard = ({ record }) => {
  return (
    <div className={style.cardContainer}>
      <div className='card-description'>
        <span className='ri-checkbox-circle-line state' />
        <div className='user-icon'>{record.name[0]}</div>
        <div className='record-info'>
          <div className='record-info__user-name'>{record.name}</div>
          <div className='record-info__date'>{record.date}</div>
        </div>
      </div>
      <div className='card-action'>
        <div className='amount'>${record.amount.toLocaleString()}</div>
        <button className='button-link'>Enviar nuevamente</button>
      </div>
    </div>
  );
};

ClientCard.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    date: PropTypes.string,
    amount: PropTypes.number,
    state: PropTypes.string
  })
};

export default ClientCard;
