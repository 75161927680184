import Breadcrumbs from 'layout/breadcrumb';
import { StepWizardNavigation } from 'modules/common/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import StepWizard from 'react-step-wizard';
import { Col, Container, Row } from 'reactstrap';
import { AddresseeForm } from './components/AddresseeForm';
import { Payment } from './components/Payment';
import { SenderForm } from './components/SenderForm';
import style from './Transfers.module.scss';

const Transfers = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <Col md='12'>
          <Breadcrumbs
            parent={t('menu.payments.title')}
            module={t('modules.payments.title')}
            page={t('modules.payments.dashboard.title')}
            title={t('menu.payments.services')}
            icon='ri-bank-card-line'
            titleClassName={style.paymentTitle}
          />
          <StepWizard
            className='my-5'
            initialStep={1}
            nav={<StepWizardNavigation />}
            isLazyMount={true}
          >
            <SenderForm stepName='SenderInformation' />
            <AddresseeForm stepName='AddresseeInformation' />
            <Payment stepName='Payment' />
          </StepWizard>
        </Col>
      </Row>
    </Container>
  );
};

export default Transfers;
