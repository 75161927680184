import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useLegalStore } from 'store/legalStore';
import Breadcrumb from 'layout/breadcrumb';
import { getCountriesWithServicesByModule, getServicesByCountry } from 'services';
import { SelectableCard, Loader } from 'modules/common/components';
import { LEGAL_MAX_NUMBER_OF_CERTIFICATES } from 'configurations/constants';
import styles from './CreateApplication.module.scss';

const Services = () => {
  const setService = useLegalStore((state) => state.setService);
  const cartCount = useLegalStore((state) => state.cartCount);
  const history = useHistory();
  const { t } = useTranslation();

  const selectRef = useRef(null);

  const [countries, setCountries] = useState([]);
  const [services, setServices] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const module = 1;

  useEffect(() => {
    getCountriesWithServicesByModule(module)
      .then(({ data: { info } }) => {
        setCountries(info.data);
        selectRef.current.value = info.data[0].id;
        selectRef.current.dispatchEvent(new Event('change', { bubbles: true }));
      })
      .catch((error) => console.error('Error getting countries'));
  }, []);

  const onSelectCountry = ({ target: { value: countryID } }) => {
    setIsFetching(true);
    getServicesByCountry(countryID)
      .then(({ data: { info } }) => {
        const data = info.data
          .filter((service) => service.estado === 1)
          .map((service) => ({
            value: service.id,
            label: service.nombre_esp,
            icon: service.servicio.icono,
            url: service.url,
            price: service.precio,
            countryCode: service.pais.codigo_iso,
            countryID: service.pais.id
          }));
        setServices(data);
      })
      .catch((error) => console.error('Error getting services by module'))
      .finally(() => setIsFetching(false));
  };

  const onSelectService = ({ target }) => {
    const { value: serviceID } = target;
    const service = services.find((service) => Number(service.value) === Number(serviceID));
    const countryInfo = countries.find((country) => country.id === service.countryID);

    setService({
      idPais: service.countryID,
      nombrePais: countryInfo.nombre_esp,
      iva: countryInfo.iva,
      idServicio: service.value,
      nombreServicio: service.label,
      precioServicio: service.price
    });

    if (cartCount >= LEGAL_MAX_NUMBER_OF_CERTIFICATES) {
      Swal.fire({
        title: t('modules.legal.createApplication.alertMaxRequest.title'),
        text: t('modules.legal.createApplication.alertMaxRequest.text', {
          length: LEGAL_MAX_NUMBER_OF_CERTIFICATES
        }),
        icon: 'warning',
        buttonsStyling: false,
        confirmButtonText: t('modules.legal.createApplication.alertMaxRequest.confirm'),
        customClass: {
          confirmButton: 'btn btn-primary'
        }
      });
      target.checked = false;
    } else {
      history.push(service.url);
    }
  };

  return (
    <>
      <Breadcrumb
        module={t('modules.legal.title')}
        page={t('modules.legal.createApplication.page')}
        title={t('modules.legal.createApplication.title')}
        icon='ri-file-add-line'
      />

      <div className={styles.selectContainer}>
        <select ref={selectRef} onChange={onSelectCountry} className='form-control'>
          {countries.map(({ id, nombre_esp }) => (
            <option key={id} value={id}>
              {nombre_esp}
            </option>
          ))}
        </select>
      </div>

      {isFetching ? <Loader /> : null}

      {!isFetching && services.length ? (
        <div className={styles.servicesContainer}>
          <Row>
            {services.map((service, index) => (
              <Col sm='6' lg='4' key={index}>
                <SelectableCard
                  id={`service_${service.value}`}
                  name='service'
                  value={service.value}
                  icon={service.icon || ''}
                  label={service.label}
                  onChange={onSelectService}
                />
              </Col>
            ))}
          </Row>
        </div>
      ) : null}
    </>
  );
};

export default Services;
