const favorites = Object.freeze([
  {
    id: 1,
    name: 'Karla Martinez',
    nickname: 'Chele'
  },
  {
    id: 2,
    name: 'Juan Melara',
    nickname: 'Meme'
  },
  {
    id: 3,
    name: 'Ricardo Perez',
    nickname: 'Ricky'
  },
  {
    id: 4,
    name: 'Lucia Pena',
    nickname: 'Lucy'
  }
]);

export default favorites;
