import React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';

const Address = ({ address, handleSelect = (id = 0) => {}, selectedAddress = null }) => {
  const { t } = useTranslation();
  return (
    <div className='card'>
      <div className='p-20 media'>
        <div className='radio radio-primary mr-3'>
          <input
            type='radio'
            name='direccion'
            id={address.id}
            className='form-check-input radio_animated'
            value={address.id}
            defaultChecked={Number(selectedAddress) === Number(address.id)}
            onChange={({ target }) => handleSelect(target.value)}
          />
          <label htmlFor={address.id}></label>
        </div>
        <div className='media-body'>
          {address.predeterminada ? (
            <span className='badge badge-primary pull-right'>
              {t('common.addresses.defaultAddress')}
            </span>
          ) : null}
          <div className='mb-3'>
            <p className='h6 m-0'>{address.nombre}</p>
            <small>{address.destinatario}</small>
          </div>
          {address.direccion2 && (
            <div className='mb-3'>
              <p className='h6 m-0'>{t('common.addresses.secondaryAddress')}</p>
              <small>{address.direccion2}</small>
            </div>
          )}
          <div className='mb-3'>
            <p className='h6 m-0'>{t('common.addresses.primaryAddress')}</p>
            <small>
              {`${address.zipcode !== '' ? address.zipcode + ', ' : ''} ${address.direccion}`}
            </small>
          </div>
          <div>
            <p className='h6 m-0'>{t('common.addresses.phone')}</p>
            <small>{formatPhoneNumberIntl(address.telefono)}</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
