import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Alert,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Loader } from 'react-feather';
import { TextField } from 'modules/common/components';
import { recoverPassword } from 'services';

const ForgotPassword = (props) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const [showAlert, setShowAlert] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (form) => {
    setIsSubmitting(true);

    const request = {
      correo_electronico: form.email,
    };

    recoverPassword(request)
      .then(({ data: { info } }) => {
        setShowAlert(!showAlert);
        reset({});
      })
      .catch((error) => {
        toast.error(`Error: ${error?.response?.data?.info.data}`);
        console.error('error trying to recover password ', error);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col xs='12'>
            <div className='login-card'>
              <div>
                <div>
                  <a className='logo' href='#javascript'>
                    <img
                      className='img-fluid for-light'
                      src={require('assets/images/logo/logo.png')}
                      alt='tkiero.app'
                    />
                  </a>
                </div>
                <Alert
                  style={{ maxWidth: '450px' }}
                  className='alert-dismissible bg-white'
                  color='success inverse'
                  isOpen={showAlert}
                  target='Alert-1'
                >
                  <i className='fa fa-check'></i>
                  <small className='d-block pr-2'>
                    {t('modules.auth.forgotPassword.alert')}
                  </small>
                  <Button
                    className='close'
                    color='default'
                    id='Alert-1'
                    onClick={() => setShowAlert(!showAlert)}
                  >
                    <span>×</span>
                  </Button>
                </Alert>
                <div className='login-main'>
                  <Form
                    className='theme-form'
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <h4 className='mb-3'>
                      {t('modules.auth.forgotPassword.title')}
                    </h4>
                    <FormGroup className='mb-3'>
                      <TextField
                        id='email'
                        name='email'
                        className='form-control'
                        type='email'
                        rules={{
                          required: t('common.formValidations.required'),
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: t('common.formValidations.invalidEmail'),
                          },
                        }}
                        label={t('modules.auth.forgotPassword.email')}
                        control={control}
                        errors={errors}
                        placeholder={t(
                          'modules.auth.forgotPassword.inputPlaceholder'
                        )}
                      />
                    </FormGroup>
                    <FormGroup className='mb-0'>
                      <Button
                        color='primary'
                        className='btn-block'
                        type='submit'
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <Loader size={15} className='fa-spin' />
                        ) : (
                          <>
                            {t('modules.auth.forgotPassword.continue')}{' '}
                            <i className='fa fa-arrow-right ml-1'></i>
                          </>
                        )}
                      </Button>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer position='top-right' />
    </>
  );
};

export default ForgotPassword;
