import React from "react";
import { Jumbotron } from "reactstrap";
import * as Icon from "react-feather";

/**
 * @param Icon react-feather icon name
 * @param title string
 * @param description string
 *
 **/
const EmptyPage = ({ icon, title, description = "" }) => {
  const IconTag = Icon[icon];
  return (
    <Jumbotron className="text-center">
      <div className="mb-3">
        <IconTag size={48} />
      </div>
      <p className="lead">{title}</p>
      <p className="font-roboto text-muted">{description}</p>
    </Jumbotron>
  );
};

export default EmptyPage;
