import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label, Input, Button } from 'reactstrap';

const SearchForm = ({ handleInputChange, searchData, isSearching, handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <Row className='d-flex mb-4'>
      <Col className='col-auto mb-3'>
        <Label htmlFor='fecha_desde'>{t('modules.legal.dashboard.searchForm.startDate')}</Label>
        <Input
          type='date'
          id='fecha_desde'
          name='fecha_desde'
          className='form-control mx-1'
          defaultValue={searchData.fecha_desde}
          onChange={handleInputChange}
        />
      </Col>
      <Col className='col-auto mb-3'>
        <Label htmlFor='fecha_hasta'>{t('modules.legal.dashboard.searchForm.endDate')}</Label>
        <Input
          type='date'
          id='fecha_hasta'
          name='fecha_hasta'
          className='form-control mx-1'
          defaultValue={searchData?.fecha_hasta}
          onChange={handleInputChange}
        />
      </Col>
      <Col className='col-auto mb-3 align-self-end'>
        <Button color='primary' className='btn-pill' onClick={handleSubmit} disabled={isSearching}>
          {t('modules.legal.dashboard.searchForm.search')}
        </Button>
      </Col>
    </Row>
  );
};

export default SearchForm;
