import React from 'react';
import classNames from 'classnames';
import { Button, Badge } from 'reactstrap';
import styles from './address.module.scss';
import { t } from 'i18next';

const Address = ({ address, onEditAddress, onDeleteAddress }) => {
  const { nombre, predeterminada, telefono, direccion, zipcode } = address;

  return (
    <div className={classNames(styles.card, 'h-100 justify-content-between')}>
      <div className={styles.cardBody}>
        <div style={styles.icon}>
          <span className='ri-map-pin-fill ri-xl'></span>
        </div>
        <div className='w-100'>
          <div className='d-flex justify-content-between'>
            <h6>{nombre}</h6>
            {predeterminada ? (
              <div className='align-content-right'>
                <Badge color='primary'>{t('common.addresses.defaultAddress')}</Badge>
              </div>
            ) : null}
          </div>
          <p className='mb-0'>{`${zipcode !== '' ? zipcode + ', ' : ''} ${direccion}`}</p>
          <p>{telefono}</p>
        </div>
      </div>
      <div className={styles.cardFooter}>
        <Button color='' className={styles.btnIcon} onClick={() => onDeleteAddress(address)}>
          <span className='ri-delete-bin-7-line ri-xl'></span>
          {t('common.formControls.delete')}
        </Button>
        <Button
          color='light'
          className={classNames(styles.btnIcon, 'btn-pill')}
          onClick={() => onEditAddress(address)}
        >
          <span className='ri-pencil-line ri-xl'></span>
          {t('common.formControls.edit')}
        </Button>
      </div>
    </div>
  );
};

export default Address;
