import { axiosInstance, BASE_URL } from 'configurations/axios';

/**
 * TODO: Be able to pass query params
 * @param endpoint string
 * @param config AxiosConfig
 */
export const get = (endpoint = '', config = {}) => {
  const sanitizeEndpoint = endpoint[0] !== '/' ? `/${endpoint}` : endpoint;
  return axiosInstance.get(`${BASE_URL}${sanitizeEndpoint}`);
};

/**
 * @param endpoint string
 * @param data Object
 * @param config AxiosConfig
 */
export const post = (endpoint = '', data, config) => {
  const sanitizeEndpoint = endpoint[0] !== '/' ? `/${endpoint}` : endpoint;
  return axiosInstance.post(`${BASE_URL}${sanitizeEndpoint}`, data, config);
};

/**
 * @param endpoint string
 * @param data Object
 * @param config AxiosConfig
 */
export const put = (endpoint = '', data, config) => {
  const sanitizeEndpoint = endpoint[0] !== '/' ? `/${endpoint}` : endpoint;
  return axiosInstance.put(`${BASE_URL}${sanitizeEndpoint}`, data, config);
};

/**
 * @param endpoint string
 * @param config AxiosConfig
 */
export const remove = (endpoint = '', config) => {
  const sanitizeEndpoint = endpoint[0] !== '/' ? `/${endpoint}` : endpoint;
  return axiosInstance.delete(`${BASE_URL}${sanitizeEndpoint}`, config);
};
