import {
  Login,
  Register,
  ForgotPassword,
  ResetPassword,
  VerificationCode,
  VerifyAccount
} from 'modules/auth';

export const publicRoutes = [
  {
    path: '/auth/login',
    Component: Login
  },
  {
    path: '/auth/register',
    Component: Register
  },
  {
    path: '/auth/forgot-password',
    Component: ForgotPassword
  },
  {
    path: '/auth/reset-password',
    Component: ResetPassword
  },
  {
    path: '/auth/verification-code',
    Component: VerificationCode
  },
  {
    path: '/auth/verify-account',
    Component: VerifyAccount
  }
];
