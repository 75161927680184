import { axiosInstance, BASE_URL } from "configurations/axios";

const path = BASE_URL.concat("/paises");
const getCountries = async () => {
  return await axiosInstance.get(path.concat("?estado=1"));
};

const getCountriesWithServicesByModule = async (modulo = 0) => {
  return await axiosInstance.get(path.concat(`/modulo/${modulo}`));
};

const getCountriesWithExpressPoint = async () => {
  return await axiosInstance.get(`${BASE_URL}/punto-express/paises`);
};

export {
  getCountries,
  getCountriesWithServicesByModule,
  getCountriesWithExpressPoint,
};
