import React, { useEffect, useState, useCallback } from 'react';
import * as dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Row, Col, Form, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getCountries, getGenres, getUserInformation, updateUserInformation } from 'services';
import { TextField, DropDown, Loader, PhoneField } from 'modules/common/components';
import { useAuthStore } from 'store/authStore';
import { handleHttpErrors } from 'utils/handleHttpErrors';

const PersonalInformationModal = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm();
  const updateUserInfo = useAuthStore((s) => s.updateUserInformation);
  const [countries, setCountries] = useState([]);
  const [genres, setGenres] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [userInfo, setUserInfo] = useState(null);

  const fetchData = useCallback(async () => {
    await getCountries()
      .then(({ data: { info } }) => {
        const data = info.data.map((c) => ({
          label: c.nombre_esp,
          value: c.id
        }));
        setCountries(data);
      })
      .catch((error) => handleHttpErrors(error, 'Error getting countries'));

    await getGenres()
      .then(({ data: { info } }) => {
        const data = info.data.map((g) => ({
          label: g.nombre_esp,
          value: g.id
        }));
        setGenres(data);
      })
      .catch((error) => handleHttpErrors(error, 'Error getting genres'));

    await getUserInformation()
      .then(({ data: { info } }) => {
        updateUserInfo(info.data);
        setUserInfo(info.data);
      })
      .catch((error) => handleHttpErrors(error, 'Error getting user information'));

    setIsFetching(false);
  }, [updateUserInfo]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSubmit = (data) => {
    delete data.correo_electronico;
    setIsSaving(true);
    updateUserInformation(data)
      .then(() => {
        fetchData();
        setOpenModal(false);
        toast.success(t('modules.settings.personalInformation.personalInfoMessages.success'));
      })
      .catch((error) => {
        toast.error(t('modules.settings.personalInformation.personalInfoMessages.error'));
        console.error('Error updating user information: ', error);
      })
      .finally(() => setIsSaving(false));
  };

  if (isFetching) return <Loader />;

  return (
    <Modal isOpen={openModal} size='lg'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          {t('modules.settings.personalInformation.completeYourInformation')}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className='mb-3'>
              <h6 className='mb-0 f-w-600'>
                {t('modules.settings.personalInformation.personalInfoTitle')}
              </h6>
            </Col>
          </Row>
          <Row>
            <Col sm='6' className='mb-3'>
              <TextField
                id='nombres'
                name='nombres'
                className='form-control'
                rules={{ required: t('common.formValidations.required') }}
                label={t('modules.settings.personalInformation.personalInfoForm.names')}
                control={control}
                errors={errors}
                defaultValue={userInfo?.nombres}
              />
            </Col>
            <Col sm='6' className='mb-3'>
              <TextField
                id='apellidos'
                name='apellidos'
                className='form-control'
                rules={{ required: t('common.formValidations.required') }}
                label={t('modules.settings.personalInformation.personalInfoForm.lastnames')}
                control={control}
                errors={errors}
                defaultValue={userInfo?.apellidos}
              />
            </Col>
            <Col sm='6' className='mb-3'>
              <TextField
                id='fecha_nacimiento'
                name='fecha_nacimiento'
                className='form-control'
                type='date'
                rules={{ required: t('common.formValidations.required') }}
                label={t('modules.settings.personalInformation.personalInfoForm.birthdate')}
                control={control}
                errors={errors}
                defaultValue={dayjs(userInfo?.fecha_nacimiento).format('YYYY-MM-DD')}
              />
            </Col>
            <Col sm='6' className='mb-3'>
              <PhoneField
                id='telefono'
                name='telefono'
                className='form-control'
                rules={{ required: t('common.formValidations.required') }}
                label={t('modules.settings.personalInformation.personalInfoForm.phone')}
                control={control}
                errors={errors}
                defaultValue={userInfo?.telefono}
              />
            </Col>
            <Col sm='6' className='mb-3'>
              <DropDown
                id='pais'
                name='pais'
                dataSource={countries}
                className='form-control'
                rules={{ required: t('common.formValidations.required') }}
                label={t('modules.settings.personalInformation.personalInfoForm.country')}
                control={control}
                errors={errors}
                defaultValue={userInfo?.pais?.id}
              />
            </Col>
            <Col sm='6' className='mb-3'>
              <DropDown
                id='sexo'
                name='sexo'
                dataSource={genres}
                className='form-control'
                rules={{
                  required: t('common.formValidations.required')
                }}
                label={t('modules.settings.personalInformation.personalInfoForm.gender')}
                control={control}
                errors={errors}
                defaultValue={userInfo?.sexo?.id}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' className='btn-pill' disabled={isSaving}>
            {t('common.formControls.saveChanges')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default PersonalInformationModal;
