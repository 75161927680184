import { axiosInstance, BASE_URL } from 'configurations/axios';

const path = BASE_URL.concat('/clientes/');

const createAddress = async (data) => {
  return axiosInstance.post(path.concat('direcciones'), data);
};

const updateAddress = async (data, addressID) => {
  return axiosInstance.put(path.concat(`direcciones/${addressID}`), data);
};

const getAddresses = async () => {
  return axiosInstance.get(path.concat('direcciones'));
};

const deleteAddress = async (addressID = 0) => {
  return axiosInstance.delete(path.concat(`direcciones/${addressID}`));
};

export { createAddress, updateAddress, getAddresses, deleteAddress };
