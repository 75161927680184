import React, { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Alert } from 'reactstrap';
import { verifyAccount } from 'services';
import { Loader } from 'modules/common/components';

const VerifyAccount = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    verifyAccount({
      correo_electronico: query.get('email'),
      token: query.get('token')
    })
      .catch((error) => {
        setError(true);
      })
      .finally(() => setIsLoading(false));
  }, [query, t]);

  return (
    <div>
      <Container fluid={true}>
        <Row>
          <Col xs='12'>
            <div className='login-card'>
              <div>
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <div>
                      <a className='logo' href='#javascript'>
                        <img
                          className='img-fluid for-light'
                          src={require('assets/images/logo/logo.png')}
                          alt='tkiero.app'
                        />
                      </a>
                    </div>
                    {error ? (
                      <Alert
                        style={{ maxWidth: '450px' }}
                        className='outline bg-white'
                        color='danger inverse'
                        isOpen={true}
                        target='Alert-1'
                      >
                        <i className='icon-close'></i>
                        <small className='d-block pr-2'>
                          {t('modules.auth.verifyAccount.alert')}
                        </small>
                      </Alert>
                    ) : (
                      <Alert
                        style={{ maxWidth: '450px' }}
                        className='outline bg-white'
                        color='success inverse'
                        isOpen={true}
                        target='Alert-1'
                      >
                        <i className='icon-info-alt'></i>
                        <small className='d-block pr-2'>
                          <Trans
                            i18nKey='modules.auth.verifyAccount.verificationSuccess'
                            components={{
                              Link: <Link to='/auth/login' />
                            }}
                          />
                        </small>
                      </Alert>
                    )}
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VerifyAccount;
