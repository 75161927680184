import {
  Dashboard,
  CreateApplication,
  RequestDetails,
  BirthCertificate,
  DeathCertificate,
  DivorceCertificate,
  RegistryInvestigation,
  MarriageCertificate,
  RegistrationCertificate
} from 'modules/legal';

export const legalRoutes = [
  {
    path: '/app/legal/dashboard',
    Component: Dashboard
  },
  {
    path: '/app/legal/create-application',
    Component: CreateApplication
  },
  {
    path: '/app/legal/request-details/:requestId',
    Component: RequestDetails
  },
  {
    path: '/app/legal/birth-certificate',
    Component: BirthCertificate
  },
  {
    path: '/app/legal/death-certificate',
    Component: DeathCertificate
  },
  {
    path: '/app/legal/divorce-certificate',
    Component: DivorceCertificate
  },
  {
    path: '/app/legal/registry-investigation',
    Component: RegistryInvestigation
  },
  {
    path: '/app/legal/marriage-certificate',
    Component: MarriageCertificate
  },
  {
    path: '/app/legal/registration-certificate',
    Component: RegistrationCertificate
  }
];
