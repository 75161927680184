import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { ServiceDetailsModal } from '../ServiceDetails';

const ServiceList = ({ services }) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);
  const [service, setService] = useState(null);

  let totalQuantity = 0;
  let sumTotalPrice = 0.0;

  const onShowDetails = (product = {}) => {
    setService(product);
    setShowDetails(true);
  };

  return (
    <>
      <h6 className='my-4'>{t('modules.legal.requestDetails.serviceList.title')}</h6>
      <table className='table table-striped table-bordered'>
        <thead>
          <tr>
            <th>{t('modules.legal.requestDetails.serviceList.service')}</th>
            <th>{t('modules.legal.requestDetails.serviceList.typeOfProcedure')}</th>
            <th>{t('modules.legal.requestDetails.serviceList.quantity')}</th>
            <th>{t('modules.legal.requestDetails.serviceList.totals')}</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {services.map((product, index) => {
            totalQuantity += Number(product.cantidad);
            sumTotalPrice += Number(product.precio_total);
            return (
              <tr key={`product-${index}`}>
                <td>{product.servicio.nombre_esp}</td>
                <td>{product.data.tipoTramite.nombre_esp}</td>
                <td>{product.cantidad}</td>
                <td>$ {Number(product.precio_total).toFixed(2)}</td>
                <td>
                  <Button
                    size='xs'
                    color='link'
                    onClick={() => {
                      onShowDetails(product);
                    }}
                  >
                    {t('modules.legal.requestDetails.serviceList.viewDetails')}
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={2} align='right'>
              {t('modules.legal.requestDetails.serviceList.totals')}
            </td>
            <td>{totalQuantity}</td>
            <td colSpan={2}>$ {sumTotalPrice.toFixed(2)}</td>
          </tr>
        </tfoot>
      </table>

      {showDetails ? (
        <ServiceDetailsModal
          isOpen={showDetails}
          service={service}
          handleClose={() => {
            setShowDetails(false);
            setService(null);
          }}
        />
      ) : null}
    </>
  );
};

export default ServiceList;
