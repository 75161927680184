const recordsCollection = Object.freeze([
  {
    id: 1,
    name: 'Karla MAaria Martinez Rauda',
    date: 'Martes 15 Noviembre 2022',
    amount: 2525.55,
    state: 'SUCCESS'
  },
  {
    id: 2,
    name: 'Carlos Alfaro Pena',
    date: 'Lunes 15 Octubre 2022',
    amount: 1600.00,
    state: 'SUCCESS'
  },
  {
    id: 3,
    name: 'Jose Rafael Santos Gomez',
    date: 'Sabado 07 Diciembre 2022',
    amount: 525.55,
    state: 'FAILURE'
  },
  {
    id: 4,
    name: 'Rafael Alfredo Romero Martinez',
    date: 'Miercoles 05 Julio 2022',
    amount: 250.00,
    state: 'SUCCESS'
  }
]);

export default recordsCollection;
