import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { usePaymentStore } from "store/paymentStore";
import { getExpressPointCategoriesByModule } from "services";
import { EmptyPage, Loader, SelectableCard } from "modules/common/components";

const ExpressPointCategories = (props) => {
  const [categories, setCategories] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const module = usePaymentStore((state) => state.module);
  const category = usePaymentStore((state) => state.category);
  const setCategory = usePaymentStore((state) => state.setCategory);

  useEffect(() => {
    const fetchCategories = () => {
      getExpressPointCategoriesByModule(module?.id)
        .then(({ data: { info } }) => {
          setCategories(info.data);
        })
        .catch((error) =>
          console.error("Error getting express point categories")
        )
        .finally(() => setIsFetching(false));
    };
    fetchCategories();
  }, [module.id]);

  const onSelectCategory = ({ target }) => {
    const { value: categoryID } = target;
    const categoryItem = categories.find(
      ({ id }) => Number(id) === Number(categoryID)
    );
    setCategory(categoryItem);
  };

  return (
    <>
      <Container fluid>
        <h3 className="text-center mb-4">Lista de categorias</h3>

        {isFetching ? (
          <Loader />
        ) : categories.length ? (
          <>
            <Row>
              {categories.map(({ id, nombre, icono }) => (
                <Col sm="6" lg="4" key={`category_${id}`}>
                  <SelectableCard
                    id={`category_${id}`}
                    name="category"
                    value={id}
                    icon={icono || ""}
                    label={nombre}
                    onChange={onSelectCategory}
                    isChecked={category?.id === id}
                  />
                </Col>
              ))}
            </Row>
            <Row>
              <Col sm="12" className="mt-3 d-flex justify-content-between">
                <Button color="primary" onClick={() => props.previousStep()}>
                  &laquo; Regresar
                </Button>
                <Button
                  color="primary"
                  disabled={!category?.id}
                  onClick={() => props.nextStep()}
                >
                  Siguiente &raquo;
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <EmptyPage
              icon="Search"
              title="No se encontraron datos"
              description={`No se encontraron categorias disponibles para ${module.nombre}`}
            />
            <Row>
              <Col sm="12" className="mt-3 d-flex justify-content-between">
                <Button color="primary" onClick={() => props.previousStep()}>
                  &laquo; Regresar
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default ExpressPointCategories;
