import React from 'react';
import PropTypes from 'prop-types';
import style from './FavoriteCard.module.scss';

const FavoriteCard = ({ favorite }) => {
  return (
    <div className={style.cardContainer}>
      <div className='card-description'>
        <div className='user-icon'>{favorite.name[0]}</div>
        <div className='record-info'>
          <div className='record-info__user-name'>
            {favorite.name} ({favorite.nickname})
          </div>
          <button className='record-info__action'>Enviar dinero</button>
        </div>
      </div>
      <div className='card-action'>
        <span className='ri-close-circle-fill' />
      </div>
    </div>
  );
};

FavoriteCard.propTypes = {
  favorite: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    nickname: PropTypes.string
  })
};

export default FavoriteCard;
