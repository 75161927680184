import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import { getAddresses, deleteAddress } from 'services';
import { Loader } from 'modules/common/components';
import { Address } from './components/Address';
import { AddressForm } from './components/AddressForm';

const Addresses = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addresses, setAddresses] = useState();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = () => {
    getAddresses()
      .then(({ data: { info } }) => {
        setAddresses(info.data);
      })
      .catch((error) => console.error('Error getting addresses list. ', error))
      .finally(() => {
        setTimeout(() => setIsLoading(false), 250);
      });
  };

  const onEditAddress = (address) => {
    setSelectedAddress(address);
    setIsModalOpen(true);
  };

  const onDeleteAddress = (address = null) => {
    Swal.fire({
      html: t('common.addresses.alertDeleteAddress.title', { addressName: address.nombre }),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('common.formControls.delete'),
      cancelButtonText: t('common.formControls.cancel'),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-danger mr-2',
        cancelButton: 'btn btn-light',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAddress(address.id)
          .then((response) => {
            fetchAddresses();
            Swal.fire({
              text: t('common.addresses.alertDeleteAddress.message'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: t('common.formControls.accept'),
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
          })
          .catch((error) => console.error('Error deleting the adddress: ', address));
      }
    });
  };

  return (
    <Card>
      <CardBody>
        <Row className='d-flex justify-content-between'>
          <Col className='mb-3'>
            <h6 className='mb-0 f-w-600'>{t('modules.settings.addresses.title')}</h6>
            <small>{t('modules.settings.addresses.description')}</small>
          </Col>
          <Col className='col-auto text-right'>
            <div>
              <Button
                color='primary'
                className='d-flex align-items-center btn-pill'
                onClick={() => setIsModalOpen(true)}
              >
                <span className='ri-add-line ri-xl'></span>
                {t('common.addresses.add')}
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          {isLoading ? (
            <Col>
              <Loader />
            </Col>
          ) : (
            addresses.map((address) => (
              <Col key={address.id} sm={12} md={6} xl={4} className='mb-3'>
                <Address
                  address={address}
                  onEditAddress={onEditAddress}
                  onDeleteAddress={onDeleteAddress}
                />
              </Col>
            ))
          )}

          {isModalOpen && (
            <AddressForm
              isOpen={isModalOpen}
              setIsOpen={(isOpen) => {
                setIsModalOpen(isOpen);
                setSelectedAddress(null);
                fetchAddresses();
              }}
              address={selectedAddress}
            />
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default Addresses;
