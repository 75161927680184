import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col, FormGroup, Button, Form } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Loader } from 'react-feather';
import { login } from 'services';
import { TextField } from 'modules/common/components';
import { SignInFacebook, SignInGoogle } from '../components';
import { useAuthStore } from 'store/authStore';
import styles from './login.module.scss';

const LoginWithBgImage = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const setUserInformation = useAuthStore((state) => state.setUserInformation);

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm();

  const [togglePassword, setTogglePassword] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword);
  };

  const onSubmit = async (form) => {
    setIsLoggingIn(true);
    const request = {
      correo_electronico: form.email,
      contrasenia: form.password
    };

    login(request)
      .then(({ data: { info } }) => {
        setUserInformation(info.data);
        history.push('/auth/verification-code');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error(t('modules.auth.login.invalidCredentials'));
        } else {
          toast.error(error.response.data.error);
        }
        console.error('Error trying to log in ', error.response);
      })
      .finally(() => setIsLoggingIn(false));
  };

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col xl='6' className={`b-center bg-size ${styles.background}`}>
            <div className={styles.messageLine}></div>
            <label className={styles.messageLabel}>{t('modules.auth.login.slogan')}</label>
            <img
              className={`bg-img-cover bg-center ${styles.mainImage}`}
              src={require('assets/images/login/tk-home-login.png')}
              width='620vw'
              alt='looginpage'
            />
          </Col>
          <Col xl='6'>
            <div className='login-card'>
              <div>
                <div align='center'>
                  <a className='logo' href='#javascript'>
                    <img
                      className='img-fluid for-light'
                      src={require('assets/images/login/logo_main.svg')}
                      alt='tkiero'
                    />
                  </a>
                </div>
                <div>
                  <div className='content-login'>
                    <Form
                      className='theme-form needs-validation mt-5'
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <p className={styles.subtitle}>{t('modules.auth.login.subtitle')}</p>
                      <FormGroup>
                        <TextField
                          id='email'
                          name='email'
                          className={`form-control ${styles.textfield}`}
                          type='email'
                          rules={{
                            required: t('common.formValidations.required')
                          }}
                          label={t('modules.auth.login.email')}
                          icon='ri-user-line ri-lg'
                          control={control}
                          errors={errors}
                        />
                      </FormGroup>
                      <FormGroup>
                        <TextField
                          id='password'
                          name='password'
                          type={togglePassword ? 'text' : 'password'}
                          className={`form-control ${styles.textfield}`}
                          rules={{
                            required: t('common.formValidations.required')
                          }}
                          label={t('modules.auth.login.password')}
                          icon='ri-lock-line ri-lg'
                          control={control}
                          errors={errors}
                        />
                        <div className='show-hide' onClick={() => HideShowPassword(togglePassword)}>
                          <span
                            style={{ display: 'none' }}
                            className={togglePassword ? '' : 'show'}
                          ></span>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className='my-3 text-right'>
                          <Link
                            className={`btn-link ${styles.forgotPassword}`}
                            to='/auth/forgot-password'
                          >
                            {t('modules.auth.login.forgotPassword')}
                          </Link>
                        </div>
                        <div style={{ display: 'flex', width: '100%' }}>
                          <div style={{ width: '50%', paddingRight: '10px' }}>
                            <Button color='light' className='btn-pill' type='button'>
                              <Link to={'/auth/register'} style={{ color: '#42526E' }}>
                                {t('modules.auth.login.register')}
                              </Link>
                            </Button>
                          </div>
                          <div style={{ width: '50%', paddingLeft: '10px' }}>
                            <Button
                              className='btn-pill'
                              color='primary'
                              type='submit'
                              disabled={isLoggingIn}
                            >
                              {isLoggingIn && <Loader size={15} className='fa-spin mr-1' />}
                              {t('modules.auth.login.title')}
                            </Button>
                          </div>
                        </div>
                      </FormGroup>
                      <div className={styles.dotedLine}></div>
                      <h6 className={styles.connectWith}>{t('modules.auth.login.loginWith')}</h6>
                      <div className={styles.dotedLine}></div>
                      <div className='social mt-4' align='center'>
                        <div className='btn-showcase'>
                          <SignInFacebook />
                          <SignInGoogle />
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer position='top-right' />
    </>
  );
};

export default LoginWithBgImage;
