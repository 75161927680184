import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import StepWizard from 'react-step-wizard';
import Breadcrumb from 'layout/breadcrumb';
import { useLegalStore } from 'store/legalStore';
import { StepWizardNavigation } from 'modules/common/components';
import { GeneralInformation, TypeOfProcedure, Summary, ServiceInformation } from './steps';
import { LEGAL_MAX_NUMBER_OF_CERTIFICATES } from 'configurations/constants';

const DeathCertificate = (props) => {
  const { t } = useTranslation();
  const cartCount = useLegalStore((state) => state.cartCount);

  if (cartCount === LEGAL_MAX_NUMBER_OF_CERTIFICATES) {
    return <Redirect to={`${process.env.PUBLIC_URL}/app/legal/create-application/`} />;
  }

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col md='12'>
            <Card>
              <CardBody>
                <Breadcrumb
                  module={t('modules.legal.title')}
                  page={t('modules.legal.createApplication.page')}
                  title={t('common.legal.deathCertificate')}
                />
                <Link to='/app/legal/create-application/'>
                  <i className='fa fa-arrow-left'></i> {t('common.formControls.back')}
                </Link>
                <StepWizard
                  className='my-5'
                  initialStep={1}
                  nav={<StepWizardNavigation />}
                  isLazyMount={true}
                >
                  <GeneralInformation stepName={'GeneralInformation'} />
                  <TypeOfProcedure stepName={'TypeOfProcedure'} />
                  <Summary stepName={'Summary'} />
                  <ServiceInformation stepName={'ServiceInformation'} />
                </StepWizard>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DeathCertificate;
