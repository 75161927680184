export const calculatePricePerCopies = (
  servicePrice = 0,
  procedurePrice = 0,
  numberCopies = 0
) => {
  let total = 0.0;

  if (numberCopies === 1) {
    total = servicePrice + procedurePrice;
  } else {
    total =
      servicePrice +
      procedurePrice +
      (servicePrice / 2 + procedurePrice) * (numberCopies - 1);
  }
  return Number(total.toFixed(2));
};
