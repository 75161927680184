import React from "react";
import { Loader as LoaderIcon } from "react-feather";
const Loader = ({ text = null }) => {
  return (
    <>
      <div className="w-100 d-flex align-items-center justify-content-center">
        <LoaderIcon className="fa-spin mr-1" />
        {text ? <span>{text}</span> : null}
      </div>
    </>
  );
};

export default Loader;
