import React from 'react';
import { Card, CardBody } from 'reactstrap';
import CountUp from 'react-countup';
/**
 * @param cardStyle string
 * @param textBody string
 * @param counter number
 *
 *  */
const CardColor = ({ cardStyle, textBody, counter }) => {
  return (
    <Card className='o-hidden shadow-none '>
      <CardBody className={` b-r-4 card-body p-3`}>
        <div className='media static-top-widget text-center'>
          <div className='media-body pl-3'>
            <h4 className=' counter '>
              <CountUp end={counter} />
            </h4>
            <div className={` ${cardStyle} line-border`}></div>
            <span>{textBody}</span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default CardColor;
