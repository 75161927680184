import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { usePaymentStore } from "store/paymentStore";
import {
  getCountriesWithExpressPoint,
  getExpressPointModulesByCountry,
} from "services";
import {
  EmptyPage,
  ButtonTags,
  Loader,
  SelectableCard,
} from "modules/common/components";

const ExpressPointModules = (props) => {
  const [countries, setCountries] = useState([]);
  const [modules, setModules] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const module = usePaymentStore((store) => store.module);
  const setModule = usePaymentStore((store) => store.setModule);

  useEffect(() => {
    getCountriesWithExpressPoint()
      .then(({ data: { info } }) => {
        const data = info.data.map((c) => ({
          ...c.pais,
          value: c.pais.id,
          label: c.pais.nombre_esp,
        }));
        setCountries(data);
      })
      .catch((error) => console.error("Error getting express point countries"));
  }, []);

  const onSelectCountry = ({ target: { value: countryID } }) => {
    setIsFetching(true);
    getExpressPointModulesByCountry(countryID)
      .then(({ data: { info } }) => {
        setModules(info.data);
      })
      .catch((error) => console.error("Error getting express point modules"))
      .finally(() => setIsFetching(false));
  };

  const onSelectModule = ({ target }) => {
    const { value: moduleID } = target;
    const module = modules.find(({ id }) => Number(id) === Number(moduleID));
    setModule(module);
  };

  return (
    <Container fluid={true}>
      <h3 className="mb-4 text-center">Opciones disponible</h3>

      {countries.length ? (
        <Row>
          <Col>
            <ButtonTags
              id="countries"
              name="country"
              dataSource={countries}
              onChange={onSelectCountry}
            />
          </Col>
        </Row>
      ) : null}

      {isFetching ? (
        <Loader />
      ) : modules.length ? (
        <>
          <Row>
            {modules.map(({ id, nombre, icono }) => (
              <Col sm="6" lg="4" key={`module_${id}`}>
                <SelectableCard
                  id={`module_${id}`}
                  name="module"
                  value={id}
                  icon={icono || ""}
                  label={nombre}
                  onChange={onSelectModule}
                  isChecked={module?.id === id}
                />
              </Col>
            ))}
          </Row>
          <Row>
            <Col sm="12" className="mb-3 text-right">
              <Button
                color="primary"
                disabled={!module?.id}
                onClick={() => props.nextStep()}
              >
                Siguiente &raquo;
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <EmptyPage
          icon="Search"
          title="No se encontraron datos"
          description="No se encontraron datos disponibles para el pais seleccionado."
        />
      )}
    </Container>
  );
};

export default ExpressPointModules;
